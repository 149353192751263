import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-event-summary-activity',
  templateUrl: './cnfg-event-summary-activity.component.html',
  styleUrls: ['./cnfg-event-summary-activity.component.css']
})

export class CnfgEventSummaryActivityComponent extends BaseComponent implements OnInit {

  @Input() parentData: any;
  isShow=true; usrPrmsn: any = null; cnfgEvntSmPrmsn = true;
  eventActivityForm: FormGroup = this.fb.group({});
  msdStngs = {
    singleSelection: false, 
    idField: 'id', 
    textField: 'name', 
    selectAllText: 'ALL',
    unSelectAllText: 'ALL', 
    itemsShowLimit: 3, 
    allowSearchFilter: true
  };
  selectedUserCategories: any[] = [];
  userCategries: any[] = [];
 
  isSbmtd: boolean = false; 
  constructor(public glblSvc: GlobalService,  public fb: FormBuilder) { 
    super(glblSvc);
    this.getMaterialTagandFacilityCatoegory()
  
  }
  ngOnChanges(changes: SimpleChanges): void {
  this.editData(this.parentData)}
  
  editData(data:any){
    if(data){
      this.eventActivityForm.patchValue({
        durationNotLoggedIn:data?.userInactive?.durationNotLoggedIn,
        enabledPushNotification:data?.userInactive?.enabledPushNotification,
      })
       
       if(data?.userInactive?.selectedUserCategories && data?.userInactive?.selectedUserCategories.length>0){
        this.selectedUserCategories = data?.userInactive?.selectedUserCategories;
       }
    
      
     }

  }

  ngOnInit(): void {
    this.select2ShowHide();
    this.intializeForm();
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_es_ac') ? (this.cnfgEvntSmPrmsn = e.action) : '') });
    }
  }

  getMaterialTagandFacilityCatoegory(){
    var localBadges:any[]= JSON.parse(<string>localStorage.getItem('appBdgs'));
    this.userCategries = localBadges.filter(f=>f.badgeTypeId==3 && f.isDeleted==false);
   
  }

  intializeForm(){
    this.eventActivityForm = this.fb.group({
      userCatagoryId: ['', Validators.required], 
      durationNotLoggedIn:['',Validators.required],
      enabledPushNotification:[false],
      
     
    })
  }
  onSelect(item:any,type:any){
    if(type=='f' ){
      if (this.selectedUserCategories.filter(f => f.id == item.id).length == 0)
      this.selectedUserCategories.push(item);
      this.selectedUserCategories = [...this.selectedUserCategories];
    }
 

}
  
  onDeSelect(item:any,type:any){
    if(type=='f' ){
      let data = this.selectedUserCategories;
      data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
      this.selectedUserCategories = [...data];
    }


  }
  onSelectAll(item:any,type:any){
    if(type=='f' ){
      this.selectedUserCategories = item;
      this.selectedUserCategories = [...this.selectedUserCategories];
    }


  }
  onDeSelectAll(item:any,type:any){
    if(type=='f'){
      this.selectedUserCategories = [];
    }


  }

  onValueChange(event:any){

  }
  get fc() { return this.eventActivityForm.controls; }


  saveIndent(){
    this.checkFormValidations(this.eventActivityForm), 
    this.isSbmtd = true;
    var  payLoad: any;
    if(this.eventActivityForm.valid){
    payLoad = {
      "configJson":{
        userInactive:{
          "durationNotLoggedIn":+this.eventActivityForm.controls['durationNotLoggedIn'].value,
          "enabledPushNotification":this.eventActivityForm.controls['enabledPushNotification'].value,
          "selectedUserCategories":this.selectedUserCategories
         },
      
        
      },
       configTypeId: +environment?.eventSummaryTypes?.activity, 
         updatedBy: +this.usrLgnDtls?.userId
  
    }
    this.FetchData(environment.apiCalls.post, environment.apiPaths.systemConfiguration, environment.apiMthds.saveRupdate, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.message == 'Record updated successfully')
          this.glblSvc.onTstr('s', this.result.message);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
   
    }
    else{

    }

  }

  // LoadData() {
  
  //   this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType +
  //     environment.eventSummaryTypes.indent, null).then(() => {
  //       if (this.result) {
  //         if (this.result.status == 'OK') {
  //           if (this.result.data) {
  //            const resp= this.result?.data;
  //            if(resp){
  //             this.eventActivityForm.patchValue({
  //               durationNotLoggedIn:this.parentData?.userInactive?.durationNotLoggedIn,
  //               overPeriodOfTime:this.parentData?.userInactive?.overPeriodOfTime,
  //               notdurationNotLoggedIn:this.parentData?.notGoodIndent?.notdurationNotLoggedIn,
  //               enabledPushNotification:this.parentData?.userInactive?.enabledPushNotification,
  //               enabledPushNotificationIndentNotFulfilled:this.parentData?.notGoodIndent?.enabledPushNotification,
  //             })
  //              if(this.parentData?.userInactive?.selectedMaterialTags&&this.parentData?.userInactive?.selectedMaterialTags.length>0){
  //               this.selectedMaterialTags = this.parentData?.userInactive?.selectedMaterialTags;
  //              }
  //              if(this.parentData?.userInactive?.selectedUserCategories&&this.parentData?.userInactive?.selectedUserCategories.length>0){
  //               this.selectedUserCategories = this.parentData?.userInactive?.selectedUserCategories;
  //              }
  //              if(this.parentData?.notGoodIndent?.selectedMaterialTags&&this.parentData?.userInactive?.selectedMaterialTags.length>0){
  //               this.selectedNotMaterialTags = this.parentData?.notGoodIndent?.selectedMaterialTags;
  //              }
  //              if(this.parentData?.notGoodIndent?.selectedUserCategories&&this.parentData?.userInactive?.selectedUserCategories.length>0){
  //               this.selectedNotFacilityTags = this.parentData?.notGoodIndent?.selectedUserCategories;
  //              }
  //            }
          
  //           }
  //         }
  //       }
  //     });
  // }
}

