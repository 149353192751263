<div class="card p-0 ">
    <div class="card-header border-0 shadow-none p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="Activity-tab" data-bs-toggle="tab" href="#Activity"
                    role="tab" aria-controls="Activity" aria-selected="true" (click)="onTabChage(37)">Activity</a></li>
            <li class="nav-item"><a class="nav-link" id="Assets-tab" data-bs-toggle="tab" href="#Assets" role="tab"
                    aria-controls="Assets" aria-selected="false"  (click)="onTabChage(36)">Assets</a></li>
            <li class="nav-item"><a class="nav-link" id="Inventory-tab" data-bs-toggle="tab" href="#Inventory"
                    role="tab" aria-controls="Inventory" aria-selected="false" (click)="onTabChage(35)">Stock Management</a></li>
            <li class="nav-item"><a class="nav-link" id="Supply-tab" data-bs-toggle="tab" href="#Supply" role="tab"
                    aria-controls="Supply" aria-selected="false"  (click)="onTabChage(34)">Indent</a></li>
        </ul>
    </div>
    <div class="card-body shadow-none p-2">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="Activity" role="tabpanel" aria-labelledby="Activity-tab">
                <evin-cnfg-event-summary-activity  [parentData]="parentPassData"></evin-cnfg-event-summary-activity>
            </div>
            <div class="tab-pane fade" id="Assets" role="tabpanel" aria-labelledby="Assets-tab">
                <evin-cnfg-event-summary-assets></evin-cnfg-event-summary-assets>
            </div>
            <div class="tab-pane fade" id="Navigation" role="tabpanel" aria-labelledby="Navigation-tab">
                <evin-cnfg-navigation></evin-cnfg-navigation>
            </div>
            <div class="tab-pane fade" id="Inventory" role="tabpanel" aria-labelledby="Inventory-tab">
                <evin-cnfg-event-summary-inventory></evin-cnfg-event-summary-inventory>
            </div>
            <div class="tab-pane fade" id="Supply" role="tabpanel" aria-labelledby="Supply-tab">
                <evin-cnfg-event-summary-supply [parentData]="parentPassData"></evin-cnfg-event-summary-supply>
            </div>
        </div>
    </div>
</div>