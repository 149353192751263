<div class="container-fluid">
    <header class="sticky-top">
     <nav class="navbar navbar-expand-lg navbar-dark">
         <div class="container-fluid">
           <a class="navbar-brand" href="#">
             SHWP
           </a>
           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
             <span class="navbar-toggler-icon"></span>
           </button>
           <div class="collapse navbar-collapse" id="navbarNav">
             <ul class="navbar-nav me-auto mb-2 mb-lg-0">
               <li class="nav-item">
                 <a class="nav-link" href="#">Stories</a>
               </li>
               <li class="nav-item">
                 <a class="nav-link" href="#">Expertise</a>
               </li>
               <li class="nav-item">
                 <a class="nav-link" href="#">Countries We Support</a>
               </li>
               <li class="nav-item">
                 <a class="nav-link" href="#">About</a>
               </li>
               <li class="nav-item">
                 <a class="nav-link" href="#">Get Involved</a>
               </li>
             </ul>
             <div class="d-flex align-items-center">
               <a href="#" class="btn donate-btn me-2">Donate</a>
               
             </div>
           </div>
         </div>
       </nav>
    </header>
 
       <!--1st paragraph-->
       <div class="container my-5">
         <h1 class="section-title">What Information We Collect</h1>
         
         <div class="content-text mt-4">
           <p>“Personal Information” is information that identifies you as an individual or relates to an identifiable person. When you visit SHWP Sites, SHWP and its service providers may collect Personal Information that you provide, such as your name, address, email address, and payment information.</p>
           
           <p>“Other Information” is any information that does not reveal your specific identity or does not directly relate to an individual. SHWP and its service providers use a variety of techniques to gather Other Information about your use of our Sites, including, among other things, your IP address, browser type, mobile device type, content visited while on our Sites, and the duration of your visit to our Sites.</p>
           
           <p>If you submit any Personal Information relating to third parties (i.e., other individuals) to us or to our service providers in connection with our Sites, you are representing to SHWP that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.</p>
         </div>
       </div>
        <!--2nd paragraph-->
        <div class="container my-5">
         <h1 class="section-title">How We Collect Information</h1>
         
         <div class="content-text mt-4">
           <p>SHWP and its service providers collect Personal Information that you provide while using our Sites. For instance, SHWP gathers information when you fill out forms on our Sites, including, for example, e-newsletter sign-up forms, donation forms, surveys, and advocacy action alerts. We may supplement the information we collect from you with information we collect from and about you from other online and offline sources, such as public databases, social media platforms, and from people with whom you are connected on social media platforms. For example, if you choose to connect your social media account to your Site account, certain Personal Information from your social media account will be shared with us. This may include Personal Information that is part of your profile or the profile of your connections on social media.</p>
           
           <p>SHWP and its service providers may collect Other Information in the following ways:</p>
           <ul class="custom-bullets">
             <li>We use “cookies” to help us monitor traffic and collect information about use of our Sites. Cookies are pieces of data placed on your device when you visit our Sites that help us recognize you when you come back to our Sites, allowing us, for instance, to remember the email address and password you use when you log in on a specific computer. SHWP uses the aggregated information collected from cookies to measure the number of visits to our Sites, the average time spent on our Sites, and the identity and number of pages viewed, and to gather other traffic data and information that allows us to improve the user experience of our Sites. We also use the information for security purposes, to facilitate navigation, to display information more effectively, and to personalize your experience while using our Sites. Cookies further allow us to select which of our content is most likely to appeal to you and display it while you are on our Sites.</li>
             <li>SHWP also uses a variety of third-party Web and mobile app analytics and optimization services to improve our Sites and enhance the visitor experience. For examples, our Sites use Web analytics services that may record mouse clicks, mouse movements, scrolling activity, text you type in on our Sites, and similar activity. Our Sites do not use these services to collect any personally identifiable information not otherwise provided by you in using our Sites, and they do not track your browsing habits across other third-party websites on our behalf.</li>
             <li>SHWP uses Google Analytics, which in turn uses cookies and other similar technologies to collect information about Site use anonymously and report website trends, without identifying individual visitors. You can learn about Google’s practices by going to <a>https://policies.google.com/technologies/partner-sites</a> and opt out of them by downloading the Google Analytics opt-out browser add-on, available at <a>https://tools.google.com/dlpage/gaoptout.</a></li>
             <li>We may use pixel tags and other similar technologies to, among other things, track the actions of users of our Sites, measure the success of our marketing campaigns, and compile statistics about use of our Sites and response rates.</li>
             <li>Certain information is collected by most browsers or automatically through your device, such as your media access control address, computer type (Windows or Macintosh), screen resolution, operating system name and version, device manufacturer and model, language, and Internet browser type and version.</li>
             <li>We may collect your IP address, which is a number that is automatically assigned to the computer that you are using by your Internet service provider. An IP address may be identified and logged automatically in our server log files whenever a user accesses our Sites, along with the time of the visit and the page(s) that was/were visited. Collecting IP addresses is a common practice and is done automatically by many websites, applications, and other services. We use IP addresses for purposes such as calculating usage levels, helping diagnose server problems, and administering our Sites.</li>
             <li>When you download and use our mobile applications (each, an “App”), we may track and collect App usage data, such as the date and time the App on your device accesses our servers, and what information and files have been downloaded to the App based on your device number.</li>
             <li>We may collect the physical location of your device by, for example, using satellite, cellphone tower, or Wi-Fi signals. We may use your device’s physical location to provide you with personalized, location-based services and content.</li>
             <li>Information that you provide, such as your preferred means of communication, does not personally identify you unless it is combined with your Personal Information.</li>
             <li>We may aggregate Personal Information. Aggregated information does not personally identify you or any other user of our Sites (for example, we may aggregate Personal Information to calculate the percentage of our users who have a particular telephone area code).</li>
           </ul>
         </div>
       </div>
        <!--3rd paragraph-->
        <div class="container my-5">
         <h1 class="section-title">How We Use Collected Information</h1>
         
         <div class="content-text mt-4">
           <p>SHWP may use your Personal Information to:</p>
           <ul class="custom-bullets">
             <li>Send you information about SHWP or SHWP affiliates.</li>
             <li>Personalize your interactions with SHWP, such as to customize your Web and email content based on your interests, send you requests to participate in events, and otherwise target content and communications to you.</li>
             <li>Respond to inquiries and fulfill your requests, such as to process donations or send you information about a project, and to provide technical resources of interest to the user.</li>
             <li>Send administrative information to you, such as information regarding our Sites and changes to tour terms, conditions, and policies.</li>
             <li>Allow you to participate in contests and similar promotions and to administer these activities. Some of these activities have additional rules, which could contain additional information about how we use and disclose your Personal Information.</li>
             <li>Facilitate social sharing functionality.</li>
             <li>Carry out data analysis, audits, fraud monitoring, and prevention to enhance, improve, or modify our Sites; identify usage trends; determine the effectiveness of our promotional campaigns; and operate and expand our activities.</li>
             </ul>
             <p>Your Personal Information may be disclosed:</p>
             <ul class="custom-bullets">
             <li>To our partners, our approved vendors, and their service providers</li>
             <li>To our third-party service providers, which provide services such as website hosting, data analysis, payment processing, information technology and related infrastructure provision, customer service, email delivery, auditing, and other similar services</li>
             <li>To third-party sponsors of surveys, contests, and similar promotions</li>
             <li>By you, on message boards, chat, profile pages, blogs, and other services to which you are able to post information and materials: Please note that any information you post or disclose through these services will become public and may be available to users of our Sites and to the general public. We urge you to be very careful when deciding to disclose your Personal Information, or any Other Information, on our Sites.</li>
             <li>To your contacts associated with your social media account, other website users, and your social media account provider, in connection with your social sharing activity, such as if you connect your social media account to your Site account or log into your Site account from your social media account: By connecting your Site account and your social media account, you authorize us to share information with your social media account provider, friends associated with your social media account, and other website users, and you understand that the use of the information we share will be governed by the recipients’ privacy policy(ies). If you do not want your Personal Information shared with other users or with your social media account provider, please do not connect your social media account with your Site account, and do not participate in social sharing on our Sites.</li>
           </ul>
           <p>We may also use and disclose your Personal Information as we believe to be necessary or appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities, including public and government authorities outside your country of residence; (d) to enforce our terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others; and (g) to allow us to pursue available remedies or limit the damages that we may sustain.</p>
           <p>SHWP uses and discloses information that falls into the category of Other Information, unless applicable laws disallow such disclosures or sharing of information.</p>
           <p>The Site and SEO platform are not meant for the processing of sensitive Personal Information. Please do not send us and do not disclose any sensitive Personal Information (such as information related to racial or ethnic origin, religion or other beliefs, health records, identification card or social security number, criminal background, trade union membership, or information related to credit score, personal banking accounts, and similar personal identifiers) on or through the Site or otherwise, as SHWP has no intentions of processing such sensitive Personal Information.</p>
         </div>
       </div>
       <!--4th paragraph-->
       <div class="container my-5">
         <h1 class="section-title">How Can You Control Collection of Your Information?</h1>
         
         <div class="content-text mt-4">
           <ol>
             <li>Set your Web browser to inform you of when cookies are being set before a cookie is stored, refuse any request to set cookies, and do not accept cookies from SHWP. Erase all SHWP cookies from your hard drive.</li>
             <li>Do not provide your information when prompted by our Sites.</li>
           </ol>
           <p>Please note, however, that if you choose to reject or erase cookies, your ability to navigate our Sites may be limited. Further, in the event you choose not to share information, SHWP will be unable to respond to your request for SHWP information, enter you in any survey or contest, facilitate your action via SHWP’s Global Circle, or send information about our yearly fundraising initiatives.</p>
           <p>Finally, if you would like to modify Personal Information you have previously provided us, please manage your profile by visiting <a>give.SHWP.org/site/ConsProfileUser</a>.</p>
           <p>Pursuant to the EU General Data Protection Regulation and other applicable privacy laws, you have the right to:</p>
           <ul class="custom-bullets">
             <li>Know what Personal Information SHWP has about you.</li>
             <li>Ensure your Personal Information is accurate and relevant for the purposes for which SHWP collected it.</li>
             <li>Make your Personal Information portable to another data controller.</li>
             <li>Withdraw your consent to SHWP processing your Personal Information.</li>
             <li>Have your Personal Information erased.</li>
           </ul>
           <p>If you would like to review, correct, receive a copy of, or erase the Personal Information we have about you or withdraw your processing consent, please send us your request using the “Contact Information” below. Unless a legal exemption applies, we will respond to all such requests within 30 days. If we refuse your request, we will notify you of our reasons for the refusal to the extent required and how you may complain about the refusal.</p>
           <p>We ask individual users to identify themselves and specify the information requested to be accessed, corrected, or erased before processing such requests, and we may decline to process requests that are unreasonably repetitive and vexatious, require disproportionate technical effort (for instance, requests concerning information residing on backup tapes), jeopardize the privacy of others, would be extremely impractical, or for which access is not otherwise required. In any case, where we provide information access and correction, we perform this service free of charge, except if doing so would require a disproportionate effort. However, we will not charge you for the making of the request or to correct, update, or erase your Personal Information</p>
         </div>
       </div>
       <!--5th paragraph-->
       <div class="container my-5">
         <h1 class="section-title">Data Retention, Storage, and Security</h1>
         
         <div class="content-text mt-4">
           <p>We hold your Personal Information in a combination of hard copy and electronic files for the period necessary to support the Site, comply with our legal obligations, resolve disputes, or otherwise fulfill the purposes outlined in this Privacy Policy. Even after you cancel your account, copies of some information from your account may remain viewable in some circumstances where, for example, you have shared information with social media or other services. We may also retain backup information related to your account on our servers for some time after cancellation for fraud detection or to comply with applicable law or our internal security policies. We do not always remove or delete all of your nonpersonally identifiable account information for a number of reasons, including technical and systems constraints, and contractual, financial, or legal requirements.</p>
           <p>The security of your Personal Information is important to us. We use physical, electronic, and procedural safeguards to protect your Personal Information. We have implemented information security best practices throughout our organization, and we are secure sockets layer (SSL) secured. When you enter confidential information (such as your password), we may encrypt that information using SSL technology (SSL encryption). To learn more about such technology, go to <a>http://en.wikipedia.org/wiki/Secure_Sockets_Layer</a>. However, no method of transmission over the Internet, method of electronic storage, or other data security methods are 100 percent secure, and we cannot guarantee the security of the information you provide.</p>
           <p>If SHWP learns of a security breach that may affect your data, we will notify you electronically so that you can take appropriate protective steps. We may communicate with you electronically regarding security, privacy, and administrative issues related to your use of the Site. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. If you are an EU resident, you will receive, within 72 hours of our recognition of a breach, a Personal Information breach notification if such breach is likely to result in a high risk to the rights and freedoms of natural persons, or as otherwise required under the EU General Data Protection Regulation. To receive free written notice of a security breach or to withdraw your consent from receiving electronic notice, please email <a>info&#64;SHWp.org</a>.</p>
         </div>
       </div>
       <!--6th paragraph-->
       <div class="container my-5">
         <h1 class="section-title">How Can You Stop Receiving Communications from SHWP?</h1>
         
         <div class="content-text mt-4">
           <p>If you would like to opt out of receiving content from SHWP, you can:</p>
           <ul class="custom-bullets">
             <li>Take the opportunity to opt out in links on the bottom of emails SHWP sends to you.</li>
             <li>Go to the Supporter Center (<a>https://give.SHWP.org/site/ConsInterestsUser</a>) and click “update my email preferences.</li>
             <li>Call SHWP at (410) 537-1827 to tell us you do not want to receive communications from SHWP.</li>
           </ul>
         </div>
       </div>
       <!--7th paragraph-->
       <div class="container my-5">
         <h1 class="section-title">Does SHWP Collect Information from Children?</h1>
         
         <div class="content-text mt-4">
           <p>SHWP does not knowingly collect Personal Information from anyone under the age of 13. Our Sites are not directed at children under the age of 13. In the event that SHWP learns that it has collected Personal Information from a child under age 13 without parental consent, we will delete that information as quickly as possible. If you believe that we might have collected any Personal Information from a child under 13, email us at info&#64;SHWp.org.</p>
         </div>
       </div>
       <!--8th paragraph-->
       <div class="container my-5">
         <h1 class="section-title">What Information We Collect</h1>
         
         <div class="content-text mt-4">
           <p><strong>“Personal Information”</strong> is information that identifies you as an individual or relates to an identifiable person. When you visit SHWP Sites, SHWP and its service providers may collect Personal Information that you provide, such as your name, address, email address, and payment information.</p>
           
           <p><strong>“Other Information”</strong> is any information that does not reveal your specific identity or does not directly relate to an individual. SHWP and its service providers use a variety of techniques to gather Other Information about your use of our Sites, including, among other things, your IP address, browser type, mobile device type, content visited while on our Sites, and the duration of your visit to our Sites.</p>
           
           <p>If you submit any Personal Information relating to third parties (i.e., other individuals) to us or to our service providers in connection with our Sites, you are representing to SHWP that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.</p>
         </div>
       </div>
       <!--9th paragraph-->
       <div class="container my-5">
         <h1 class="section-title">Other Limits to Disclosure and Dissemination of Personal Information</h1>
         <h2 class="sub-section">California and Delaware “Do Not Track” Disclosures</h2>
         <div class="content-text mt-4">
           <p>California and Delaware law require us to indicate whether we honor Do Not Track settings in your Web browser concerning targeted advertising. At this time, there is no worldwide uniform or consistent industry standard or definition for responding to, processing, or communicating Do Not Track signals. Thus, like many other websites and online services, we do not currently respond to any Do Not Track browser requests.</p>
         </div>
         <h2 class="sub-section">California Privacy Rights</h2>
         <div class="content-text mt-4">
          <p>Under California law, California residents have the right to request in writing from businesses with whom they have an established business relationship: (a) a list of the categories of personally identifiable information—such as name, email, and mailing address, and the type of services provided to the customer—that a business has disclosed to third parties (including affiliates that are separate legal entities) during the immediately preceding calendar year for the third parties’ direct marketing purposes; and (b) the name and address of all such third parties. To request the above information, please email us at <a>webcenter&#64;jhmi.edu</a> with a reference to California disclosure information. Please note that we are only required to respond to each customer once per calendar year.</p>
          <p>If you are a California resident, California Civil Code Section 1798.83 permits you to request information about our disclosure of your Personal Information to third parties for those parties’ own direct marketing purposes. To make such a request, please send a letter to:</p>
          <p><strong>SHWP</strong></p>
          <p>Global Engagement and Communications Office<br>
             1615 Thames Street Baltimore, MD 21231-3492</p>
         </div>
         <h2 class="sub-section">European Union Privacy Rights</h2>
         <div class="content-text mt-4">
           <p>Citizens of the European Union are protected through the General Data Protection Regulation, which provides guidance to corporations on collecting and disclosing personal data of EU citizens within the EU and cross-border data flows. SHWP’s guidelines as stated above are consistent with EU protections and the EU-US Privacy Shield guidelines on protecting usage, storage, and further transfer of the personal data. One key element of EU protection is your right to correct your data and to request removal of your data from databases if you decide that your data are no longer needed or you simply wish to disengage from further activity or communications.</p>
         </div>
       </div>
       <!--10th paragraph-->
       <div class="container my-5">
         <h1 class="section-title">Third-Party Services</h1>
         
         <div class="content-text mt-4">
           <p>This Privacy Policy does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third-party operating any site or service to which our Sites link. The inclusion of a link on our Sites does not imply endorsement of the linked site or service by us. In addition, we are not responsible for the information collection, use, disclosure, or security practices (including the data security practices) of other organizations, such as Facebook, Apple, Google, Microsoft, or any other app developer, app provider, social media platform provider, operating system provider, wireless service provider, or device manufacturer.</p>
         </div>
       </div>
       <!--11th paragraph-->
       <div class="container my-5">
         <h1 class="section-title">Changes to This Privacy Policy</h1>
         <p>SHWP may, from time to time and at its discretion, amend this Privacy Policy. The “Effective Date” legend at the top of this page indicates when this Privacy Policy was last revised. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy on our Sites. Your continued use of our Sites following these changes means that you accept the revised Privacy Policy.</p>
         <h2 class="sub-section">Security</h2>
         <div class="content-text mt-4">
          <p>We seek to use reasonable organizational, technical, and administrative measures to protect Personal Information within our organization. Unfortunately, no data transmission or storage system can be guaranteed to be 100 percent secure. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the “Questions/Contact Information” section below.</p>
         </div>
         <h2 class="sub-section">Cross-Border Transfer</h2>
         <div class="content-text mt-4">
          <p>Our Sites are controlled and operated by us from the United States and are not intended to subject us to the laws or jurisdiction of any state, country, or territory other than that of the United States. Your Personal Information may be stored and processed in any country where we have facilities or in which we engage service providers, and, by using our Sites, you consent to the transfer of information to countries outside of your country of residence, including the United States, which may have different data protection rules than those of your country.</p>
          <p><strong>Additional Websites Subject to the Privacy Policy</strong></p>
          <p>This Privacy Policy shall apply to the following websites and all associated subdomains:</p>
         </div>
         <ul class="custom-bullets">
             <li><a>SHWP.org</a></li>
             <li><a>mcsprogram.org</a></li>
             <li><a>mchip.net</a></li>
             <li><a>tiptopmalaria.org</a></li>
             <li><a>hivoralprep.org</a></li>
             <li><a>reprolineplus.org</a></li>
             <li><a>secure2.convio.net</a></li>
             <li><a>jobs-SHWP.icims.com</a></li>
         </ul>
       </div>
       <!--12th paragraph-->
       <div class="container my-5">
         <h1 class="section-title">Questions/Contact Information</h1>
         
         <div class="content-text mt-4">
           <p>If you have any questions about this Privacy Policy, please feel free to email us info&#64;SHWp.org. You can also send a letter to:</p>
           <p><strong>SHWP</strong></p>
           <p>Attn: Development Coordinator, Global Engagement and Communications<br>
             Global Engagement and Communications Office<br>
             1615 Thames Street<br>
             Baltimore, MD 21231-3492</p>
         </div>
       </div>
       <footer class="footer">
         <div class="container">
             <div class="row">
                 <!-- Newsletter Section -->
                 <div class="col-md-4">
                     <h4>Sign Up for Our Newsletter</h4>
                     <p>Stay up to date with our latest stories, resources, and other news from SHWP.</p>
                     <form class="newsletter">
                         <div class="mb-2">
                             <input type="text" class="form-control" placeholder="First Name">
                         </div>
                         <div class="mb-2">
                             <input type="text" class="form-control" placeholder="Last Name">
                         </div>
                         <div class="mb-2">
                             <input type="email" class="form-control" placeholder="Email">
                         </div>
                         <button type="submit" class="btn btn-primary">Sign Up</button>
                         <button type="button" class="btn btn-outline-light">View past issues</button>
                     </form>
                 </div>
 
                 <!-- About Section -->
                 <div class="col-md-4">
                     <h4>About</h4>
                     <ul>
                         <li><a href="#">History & Mission</a></li>
                         <li><a href="#">Leadership</a></li>
                         <li><a href="#">Experts</a></li>
                         <li><a href="#">Board of Advisors</a></li>
                         <li><a href="#">Annual Reports</a></li>
                     </ul>
                 </div>
 
                 <!-- Explore Section -->
                 <div class="col-md-4">
                     <h4>Explore</h4>
                     <ul>
                         <li><a href="#">Stories</a></li>
                         <li><a href="#">Our Expertise</a></li>
                         <li><a href="#">Countries We Support</a></li>
                         <li><a href="#">Technical Resources</a></li>
                         <li><a href="#">Get Involved</a></li>
                     </ul>
                 </div>
             </div>
 
             <div class="row mt-4">
                 <div class="col text-center">
                     <p>Follow us on:</p>
                     <a href="#">Facebook</a> | 
                     <a href="#">Twitter</a> | 
                     <a href="#">Instagram</a> | 
                     <a href="#">LinkedIn</a>
                 </div>
             </div>
         </div>
     </footer>

 </div>
