<form [formGroup]="cnfgInvFrm">
    <!--  *ngIf="lgnUsrData?.screenPermissions?.Configurations?.viewList==true" -->
    <div class="card  pb-0 p-2" formGroupName="configJson" autocomplete="off">
        <div class="card-header shadow-none p-2">
            <div class="h6 float-start">Stock Management configuration</div>
            <!-- <div class="small float-end">Last updated on 7/9/20 10:08 AM by <a [routerLink]="">Devdutt Mishra</a></div> -->
        </div>
        <div class="card-body p-2">
            <div class="row mt-2">
                <div class="col-sm-12">
                    <div class="card shadow-sm p-0 mt-0 mb-0" formGroupName="transaction_reasons">
                        <div class="card-header p-0">
                            <h6>Transaction reasons</h6>
                        </div>
                        <div class="card-body p-0">
                            <div class="small">Enter a comma-separated list of reasons for transactions; ensure that each
                                reason less than 15 characters. For instance, discard reasons can be entered as follows:
                                Damaged,Expired,Lost. If you want to give a choice of no reason, you can start the list
                                with a comma. For example: Damaged,Expired.</div>
                            <div class="mt-3">
                                <div class="">
                                    <div class="nav  nav-tabs border-tab mb-2" id="v-pills-tab" role="tablist"
                                        aria-orientation="vertical">
                                        <a class="nav-link f-w-500 active" id="top-tab1-tab" data-bs-toggle="tab"
                                            href="#top-tab1" role="tab" aria-controls="top-tab1"
                                            aria-selected="true">Issues/Net Utilization</a>
                                        <a class="nav-link f-w-500" id="tab2-top-tab" data-bs-toggle="tab"
                                            href="#top-tab2" role="tab" aria-controls="top-tab2"
                                            aria-selected="false">Stock-In</a>
                                        <a class="nav-link f-w-500" id="tab3-top-tab" data-bs-toggle="tab"
                                            href="#top-tab3" role="tab" aria-controls="top-tab3"
                                            aria-selected="false">Stock-Edit</a>
                                        <a class="nav-link f-w-500" id="tab4-top-tab" data-bs-toggle="tab"
                                            href="#top-tab4" role="tab" aria-controls="top-tab4"
                                            aria-selected="false">Stock-Discards</a>
                                        <a class="nav-link f-w-500" id="tab5-top-tab" data-bs-toggle="tab"
                                            href="#top-tab5" role="tab" aria-controls="top-tab5"
                                            aria-selected="false">Transfer</a>
                                        <a class="nav-link f-w-500" id="tab6-top-tab" data-bs-toggle="tab"
                                            href="#top-tab6" role="tab" aria-controls="top-tab6" aria-selected="false"
                                            hidden>Incoming returns</a>
                                        <a class="nav-link f-w-500" id="tab7-top-tab" data-bs-toggle="tab"
                                            href="#top-tab7" role="tab" aria-controls="top-tab7" aria-selected="false"
                                            hidden>Outgoing returns</a>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="tab-content" id="top-tabContent">
                                        <div class="tab-pane fade show active" id="top-tab1" role="tabpanel"
                                            aria-labelledby="top-tab1-tab" formGroupName="issue">
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Reasons</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given reason does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        reasons is 500.
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th class="text-center w-40" hidden>Default Reason
                                                                            </th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstIssue; let i=index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.reasonName}}</td>
                                                                            <td class="text-center" hidden>
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="radio" id="'rbId'+{{i}}"
                                                                                        (change)="onChngRB('Issue', item)"
                                                                                        name="radioBtnName5" [checked]="item.isDefault">
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true"
                                                                                    (click)="onAddDel('Issue', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstIssue?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="4">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Reason"
                                                                                    formControlName="issTxt" [(ngModel)]="issTxt">
                                                                            </td>
                                                                            <td>
                                                                                <!-- <div class="text-end pb-3"> -->
                                                                                    <button type="button" class="btn btn-sm btn-success"
                                                                                    (click)="onAddDel('Issue', issTxt, 'a', IssmndtRsn);issTxt='';"
                                                                                         *ngIf="cnfgInvPrmsn">Add Reasons</button>
                                                                                <!-- </div> -->
                                                                            </td>
                                                                            <td rowspan="2" class="text-center" hidden>
                                                                                <button type="button" *ngIf="cnfgInvPrmsn"
                                                                                    class="btn btn-primary btn-sm"
                                                                                    (click)="clear('Issue')"
                                                                                    [disabled]="RDBchckd4">Clear
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="IssmndtRsn"
                                                                                        id="issueMTFM" (change)="IssmndtRsn=!IssmndtRsn;onChkRsn('Issue',IssmndtRsn)"
                                                                                        [attr.disabled]="lstTblData?.lstIssue?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstIssue[0]?.isReasonMandatory==true" -->
                                                                                    <label class="form-check-label" for="issueMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3">
                                                                <button type="button" class="btn btn-sm btn-success"
                                                                    (click)="onSaveData('Issue')" *ngIf="cnfgInvPrmsn">Add Reasons</button>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row">                                                        
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">Configure by
                                                                material categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" *ngIf="cnfgInvPrmsn"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons"
                                                                    (click)="CnfgMatTag($event, 'issue')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding reasons
                                                                </label>
                                                            </div>
                                                            <div class="form-group col-xl-4" *ngIf="shwMatTabl">
                                                                <select class="form-select form-select-sm" formControlName="material_tag_id" (change)="IssmndtRsnCMC=false;onMatTagChng($event, 'Issue')">
                                                                    <option value="">-Select-</option>
                                                                    <option *ngFor="let mt of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('issue')?.get('configure_by_material_tags')?.get('matsLst')?.value" 
                                                                    [value]="mt.id">{{mt.name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="">
                                                        <div class="nav  nav-tabs border-tab mb-2" id="v-pills-tab" role="tablist"
                                                            aria-orientation="vertical" formControlName="material_tag_id">
                                                            <ng-container *ngFor="let mt of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('issue')?.get('configure_by_material_tags')?.get('matsLst')?.value">
                                                                <a class="nav-link f-w-500 active" id="top-tab1-tab" data-bs-toggle="tab"
                                                                href="#top-tab8" role="tab" aria-controls="top-tab1" formControlName="material_tag_id"
                                                                aria-selected="true"   (click)="IssmndtRsnCMC=false;onClickMatTagChng(mt.id, 'Issue')">{{mt.name}}</a>
                                                            </ng-container> -->
                                                            <!-- <a class="nav-link f-w-500 active" id="top-tab1-tab" data-bs-toggle="tab"
                                                                href="#top-tab8" role="tab" aria-controls="top-tab1" formControlName="material_tag_id"
                                                                aria-selected="true" (click)="IssmndtRsnCMC=false;onClickMatTagChng(284, 'Issue')">Adapter</a>
                                                            <a class="nav-link f-w-500" id="tab2-top-tab" data-bs-toggle="tab"
                                                                href="#top-tab9" role="tab" aria-controls="top-tab2" formControlName="material_tag_id"
                                                                aria-selected="false" (click)="IssmndtRsnCMC=false;onClickMatTagChng(1, 'Issue')">CAMPAIGN</a>
                                                            <a class="nav-link f-w-500" id="tab3-top-tab" data-bs-toggle="tab" formControlName="material_tag_id" 
                                                                href="#top-tab10" role="tab" aria-controls="top-tab3"
                                                                aria-selected="false"  (click)="IssmndtRsnCMC=false;onClickMatTagChng(252, 'Issue')">COVID Vaccine</a>
                                                            <a class="nav-link f-w-500" id="tab4-top-tab" data-bs-toggle="tab" formControlName="material_tag_id"
                                                                href="#top-tab11" role="tab" aria-controls="top-tab4"
                                                                aria-selected="false" (click)="IssmndtRsnCMC=false;onClickMatTagChng(3, 'Issue')">OPEN Vials</a>
                                                            <a class="nav-link f-w-500" id="tab5-top-tab" data-bs-toggle="tab" formControlName="material_tag_id"
                                                                href="#top-tab12" role="tab" aria-controls="top-tab5"
                                                                aria-selected="false" (click)="IssmndtRsnCMC=false;onClickMatTagChng(2, 'Issue')">RI Vaccines</a>
                                                            <a class="nav-link f-w-500" id="tab6-top-tab" data-bs-toggle="tab"
                                                                href="#top-tab13" role="tab" aria-controls="top-tab6" aria-selected="false" formControlName="material_tag_id"
                                                                (click)="IssmndtRsnCMC=false;onClickMatTagChng(291, 'Issue')">sqoop_incrementel_test</a>
                                                            <a class="nav-link f-w-500" id="tab7-top-tab" data-bs-toggle="tab"
                                                                href="#top-tab14" role="tab" aria-controls="top-tab7" aria-selected="false" formControlName="material_tag_id"
                                                                (click)="IssmndtRsnCMC=false;onClickMatTagChng(12, 'Issue')">Syringes</a> -->
                                                        <!-- </div>
                                                    </div> -->
                                                    <div class="row" *ngIf="shwMatTabl && shwMatIssRsnsTab">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let rsn of lstTblData?.lstIssBdgeRsn; let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{rsn.reasonName}}</td>
                                                                            <td>
                                                                                <i class="fa fa-trash toggle-data"
                                                                                aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                (click)="onAddDelBdRsn('Issue', rsn, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstIssBdgeRsn?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Material Reason"
                                                                                    [(ngModel)]="issBdgeTxt"
                                                                                    formControlName="issTxt">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success"
                                                                                    (click)="onAddDelBdRsn('IssueCMC', issBdgeTxt, 'a', IssmndtRsnCMC, true);issBdgeTxt='';"
                                                                                    *ngIf="cnfgInvPrmsn">Add Material Reasons</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="IssmndtRsnCMC"
                                                                                        id="issueMCCR" (change)="IssmndtRsnCMC=!IssmndtRsnCMC;onChkRsn('IssueCMC',IssmndtRsnCMC)"
                                                                                        [attr.disabled]="lstTblData?.lstIssBdgeRsn?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstIssue[0]?.isReasonMandatory==true" -->
                                                                                    <label class="form-check-label" for="issueMCCR">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Reasons for issue</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="reasons_for_issue"
                                                                (change)="onAsgnRsns($event, 'issue', null, 'reasons_for_issue')">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Default reason for
                                                                issue</label>
                                                            <select class="form-select form-select-sm"
                                                                formControlName="default_reason_for_issue"
                                                                [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('issue')?.get('reasons_for_issue')?.value == '' ? true : null">
                                                                <option value="">Select the default issue reason
                                                                </option>
                                                                <option
                                                                    *ngFor="let rsn of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('issue')?.get('lstRsn')?.value"
                                                                    [value]="rsn">{{rsn}}</option>
                                                            </select>
                                                            <div class="form-check  col-xl-6 mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('issue')?.get('reasons_for_issue')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">Configure by
                                                                material categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" *ngIf="cnfgInvPrmsn"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons"
                                                                    (click)="CnfgMatTag($event, 'issue')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="row" *ngIf="shwMatTabl">
                                                                <div class="col-xl-12">
                                                                    <div class="table-responsive">
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered"
                                                                            formArrayName="material_tags">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Sl.No </th>
                                                                                    <th>Material tags</th>
                                                                                    <th>Reason</th>
                                                                                    <th>Default reason</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody
                                                                                *ngFor="let item of GetFormArrayData('issue').controls;let bIndx=index;"
                                                                                [formGroupName]="bIndx">
                                                                                <tr>
                                                                                    <td>{{bIndx+1}}</td>
                                                                                    <td>{{item.get('material_tag_id')?.value}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type="text"
                                                                                            class="form-control form-control-sm"
                                                                                            formControlName="reason"
                                                                                            (change)="onAsgnRsns($event, 'issue', bIndx, '')">
                                                                                    </td>
                                                                                    <td>
                                                                                        <select
                                                                                            class="form-select form-select-sm"
                                                                                            formControlName="default_reason"
                                                                                            [attr.disabled]="item.get('reason')?.value == '' ? true : null">
                                                                                            <option value="">Select the
                                                                                                default issue reason
                                                                                            </option>
                                                                                            <option
                                                                                                *ngFor="let rsn of item.get('lstRsn')?.value"
                                                                                                [value]="rsn">{{rsn}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td *ngIf="cnfgInvPrmsn"
                                                                                        (click)="onDelMatTags(bIndx, 'issue', item?.get('material_tag_id')?.value)">
                                                                                        <i class="fa fa-trash"
                                                                                            aria-hidden="true"></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colspan="2">
                                                                                        <ng-template #cstmPrdctTag
                                                                                            let-model="item">
                                                                                            <span
                                                                                                class="font-weight-bold">{{model.name}}</span>
                                                                                        </ng-template>
                                                                                        <input name="prdctTag"
                                                                                            type="text"
                                                                                            placeholder="Enter the material tag"
                                                                                            id="prdctTag"
                                                                                            formControlName="material_tag_id"
                                                                                            [typeahead]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('issue')?.get('configure_by_material_tags')?.get('matsLst')?.value"
                                                                                            [typeaheadScrollable]="true"
                                                                                            [typeaheadItemTemplate]="cstmPrdctTag"
                                                                                            typeaheadOptionField="name"
                                                                                            [adaptivePosition]="true"
                                                                                            class="form-control form-control-sm w-31"
                                                                                            [typeaheadScrollable]="true" [typeaheadOptionsLimit]="500"
                                                                                            (typeaheadOnSelect)="onChngPrdtTag($event, 'issue')">
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="top-tab2" role="tabpanel"
                                            aria-labelledby="tab2-top-tab" formGroupName="receipt">
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Reasons</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given reason does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        reasons is 500.</div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th class="text-center w-40" hidden>Default Reason
                                                                            </th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstRcpt; let i=index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.reasonName}}
                                                                            </td>
                                                                            <td class="text-center" hidden>
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="radio" id="'rbId'+{{i}}"
                                                                                        (change)="onChngRB('Receipt', item)"
                                                                                        name="radioBtnName2"
                                                                                        [checked]="item.isDefault">
                                                                                </div>
                                                                            </td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDel('Receipt', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstRcpt?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="4">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Reason"
                                                                                    [(ngModel)]="RcptTxt"
                                                                                    formControlName="RcptTxt">
                                                                            </td>
                                                                            <td rowspan="2" class="text-center" hidden>
                                                                                <button type="button" *ngIf="cnfgInvPrmsn"
                                                                                    class="btn btn-primary btn-sm"
                                                                                    (click)="clear('Receipt')"
                                                                                    [disabled]="RDBchckd3">
                                                                                    Clear
                                                                                </button>
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDel('Receipt', RcptTxt, 'a', RcptmndtRsn);RcptTxt='';"
                                                                                 *ngIf="cnfgInvPrmsn">Add Reasons</button>
                                                                                 <!-- (change)="onAddDel('Receipt', RcptTxt, 'a', RcptmndtRsn);RcptTxt='';" -->
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="RcptmndtRsn"
                                                                                        id="rcptMTFM" (change)="RcptmndtRsn=!RcptmndtRsn;onChkRsn('Receipt',RcptmndtRsn)"
                                                                                        [attr.disabled]="lstTblData?.lstRcpt?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstRcpt[0]?.isReasonMandatory" -->
                                                                                    <label class="form-check-label" for="rcptMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">                                                        
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">Configure by
                                                                material categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" *ngIf="cnfgInvPrmsn"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons"
                                                                    (click)="CnfgMatTag($event, 'receipt')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="form-group col-xl-4" *ngIf="shwMatTabl1">
                                                                <select class="form-select form-select-sm" formControlName="material_tag_id" (change)="RcptmndtRsnCMC=false;onMatTagChng($event, 'Receipt')">
                                                                    <option value="">-Select-</option>
                                                                    <option *ngFor="let mt of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('receipt')?.get('configure_by_material_tags')?.get('matsLst')?.value" 
                                                                    [value]="mt.id">{{mt.name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="shwMatTabl1 && shwMatRcptRsnsTab">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let rsn of lstTblData?.lstRcptBdgeRsn; let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{rsn.reasonName}}</td>
                                                                            <td>
                                                                                <i class="fa fa-trash toggle-data"
                                                                                aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                (click)="onAddDelBdRsn('Receipt', rsn, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstRcptBdgeRsn?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Material Reason"
                                                                                    [(ngModel)]="RcptBdgeTxt"                                                                                    
                                                                                    formControlName="RcptTxt">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelBdRsn('ReceiptCMC', RcptBdgeTxt, 'a', RcptmndtRsnCMC, true);RcptBdgeTxt='';"
                                                                                 *ngIf="cnfgInvPrmsn">Add Material Reasons</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="RcptmndtRsnCMC"
                                                                                        id="receiptMCCR" (change)="RcptmndtRsnCMC=!RcptmndtRsnCMC;onChkRsn('ReceiptCMC',RcptmndtRsnCMC)"
                                                                                        [attr.disabled]="lstTblData?.lstRcptBdgeRsn?.length == 0 ? true : null">
                                                                                    <label class="form-check-label" for="receiptMCCR">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3">
                                                          
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Reasons for
                                                                receipt</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="reasons_for_receipt"
                                                                (change)="onAsgnRsns($event, 'receipt', null, 'reasons_for_receipt')">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Default reason for
                                                                receipt</label>
                                                            <select class="form-select form-select-sm"
                                                                formControlName="default_reason_for_receipt"
                                                                [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('receipt')?.get('reasons_for_receipt')?.value == '' ? true : null">
                                                                <option value="">Select the default receipt reason
                                                                </option>
                                                                <option
                                                                    *ngFor="let rsn of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('receipt')?.get('lstRsn')?.value"
                                                                    [value]="rsn">{{rsn}}</option>
                                                            </select>
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('receipt')?.get('reasons_for_receipt')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">
                                                                Configure by material categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"  *ngIf="cnfgInvPrmsn"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons"
                                                                    (click)="CnfgMatTag($event, 'receipt')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="row" *ngIf="shwMatTabl1">
                                                                <div class="col-xl-12">
                                                                    <div class="table-responsive">
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered"
                                                                            formArrayName="material_tags">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Sl.No </th>
                                                                                    <th>Material tags</th>
                                                                                    <th>Reason</th>
                                                                                    <th>Default reason</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody
                                                                                *ngFor="let item of GetFormArrayData('receipt').controls;let bIndx=index;"
                                                                                [formGroupName]="bIndx">
                                                                                <tr>
                                                                                    <td>{{bIndx+1}}</td>
                                                                                    <td>{{item.get('material_tag_id')?.value}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type="text"
                                                                                            class="form-control form-control-sm"
                                                                                            formControlName="reason"
                                                                                            (change)="onAsgnRsns($event, 'receipt', bIndx, '')">
                                                                                    </td>
                                                                                    <td>
                                                                                        <select
                                                                                            class="form-select form-select-sm"
                                                                                            formControlName="default_reason"
                                                                                            [attr.disabled]="item.get('reason')?.value == '' ? true : null">
                                                                                            <option value="">Select the
                                                                                                default receipt reason
                                                                                            </option>
                                                                                            <option
                                                                                                *ngFor="let rsn of item.get('lstRsn')?.value"
                                                                                                [value]="rsn">{{rsn}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td *ngIf="cnfgInvPrmsn"
                                                                                        (click)="onDelMatTags(bIndx, 'receipt', item?.get('material_tag_id')?.value)">
                                                                                        <i class="fa fa-trash"
                                                                                            aria-hidden="true"></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colspan="2">
                                                                                        <ng-template #cstmPrdctTag
                                                                                            let-model="item">
                                                                                            <span
                                                                                                class="font-weight-bold">{{model.name}}</span>
                                                                                        </ng-template>
                                                                                        <input name="prdctTag"
                                                                                            type="text"
                                                                                            placeholder="Enter the material tag"
                                                                                            id="prdctTag"
                                                                                            formControlName="material_tag_id"
                                                                                            [typeahead]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('receipt')?.get('configure_by_material_tags')?.get('matsLst')?.value"
                                                                                            [typeaheadScrollable]="true"
                                                                                            [typeaheadItemTemplate]="cstmPrdctTag"
                                                                                            typeaheadOptionField="name"
                                                                                            [adaptivePosition]="true"
                                                                                            class="form-control form-control-sm w-31"
                                                                                            [typeaheadScrollable]="true" [typeaheadOptionsLimit]="500"
                                                                                            (typeaheadOnSelect)="onChngPrdtTag($event, 'receipt')">
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="top-tab3" role="tabpanel"
                                            aria-labelledby="tab3-top-tab" formGroupName="stock_count">
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Reasons</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given reason does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        reasons is 500.</div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th class="text-center w-40" hidden>Default Reason
                                                                            </th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstStkCnt; let i=index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.reasonName}}
                                                                            </td>
                                                                            <td class="text-center" hidden>
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="radio" id="'rbId'+{{i}}"
                                                                                        (change)="onChngRB('Stock Count', item)"
                                                                                        name="radioBtnName3"
                                                                                        [checked]="item.isDefault">
                                                                                </div>
                                                                            </td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDel('Stock Count', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstStkCnt?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="4">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Reason"
                                                                                    [(ngModel)]="stkTxt"
                                                                                    
                                                                                    formControlName="stkTxt">
                                                                            </td>
                                                                            <td rowspan="2" class="text-center" hidden>
                                                                                <button type="button" *ngIf="cnfgInvPrmsn"
                                                                                    class="btn btn-primary btn-sm"
                                                                                    (click)="clear('Stock Count')"
                                                                                    [disabled]="RDBchckd2">
                                                                                    Clear
                                                                                </button>
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDel('Stock Count', stkTxt, 'a', StkmndtRsn);stkTxt='';"
                                                                                 *ngIf="cnfgInvPrmsn">Add Reasons</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="StkmndtRsn"
                                                                                         id="stCntMTFM" (change)="StkmndtRsn=!StkmndtRsn;onChkRsn('Stock Count',StkmndtRsn)"
                                                                                        [attr.disabled]="lstTblData?.lstStkCnt?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstStkCnt[0]?.isReasonMandatory" -->
                                                                                    <label class="form-check-label" for="stCntMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3">
                                                             
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row">                                                        
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">Configure by
                                                                material categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" *ngIf="cnfgInvPrmsn"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons"
                                                                    (click)="CnfgMatTag($event, 'stock_count')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="form-group col-xl-4" *ngIf="shwMatTabl2">
                                                                <select class="form-select form-select-sm" formControlName="material_tag_id" (change)="StkmndtRsnCMC=false;onMatTagChng($event, 'Stock Count')">
                                                                    <option value="">-Select-</option>
                                                                    <option *ngFor="let mt of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('stock_count')?.get('configure_by_material_tags')?.get('matsLst')?.value" 
                                                                    [value]="mt.id">{{mt.name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="shwMatTabl2 && shwMatSCIssRsnsTab">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let rsn of lstTblData?.lstScBdgeRsn; let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{rsn.reasonName}}</td>
                                                                            <td>
                                                                                <i class="fa fa-trash toggle-data"
                                                                                aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                (click)="onAddDelBdRsn('Stock Count', rsn, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstScBdgeRsn?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Material Reason"
                                                                                    [(ngModel)]="stkBdgeTxt"
                                                                                    
                                                                                    formControlName="stkTxt">
                                                                            </td>
                                                                            <td class="text-end pb-3">
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelBdRsn('Stock CountCMC', stkBdgeTxt, 'a', StkmndtRsnCMC, true);stkBdgeTxt='';"
                                                                                 *ngIf="cnfgInvPrmsn">Add Material Reasons</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="StkmndtRsnCMC"
                                                                                        id="stkCntMCCR" (change)="StkmndtRsnCMC=!StkmndtRsnCMC;onChkRsn('Stock CountCMC',StkmndtRsnCMC)"
                                                                                        [attr.disabled]="lstTblData?.lstScBdgeRsn?.length == 0 ? true : null">
                                                                                    <label class="form-check-label" for="stkCntMCCR">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                               
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Reasons for stock
                                                                count</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="reasons_for_stock_count"
                                                                (change)="onAsgnRsns($event, 'stock_count', null, 'reasons_for_stock_count')">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Default reason for stock
                                                                count</label>
                                                            <select class="form-select form-select-sm"
                                                                formControlName="default_reason_for_stock_count"
                                                                [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('stock_count')?.get('reasons_for_stock_count')?.value == '' ? true : null">
                                                                <option value="">Select the default stock count reason
                                                                </option>
                                                                <option
                                                                    *ngFor="let rsn of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('stock_count')?.get('lstRsn')?.value"
                                                                    [value]="rsn">{{rsn}}</option>
                                                            </select>
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('stock_count')?.get('reasons_for_stock_count')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">Configure by
                                                                material categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons" *ngIf="cnfgInvPrmsn"
                                                                    (click)="CnfgMatTag($event, 'stock_count')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="row" *ngIf="shwMatTabl2">
                                                                <div class="col-xl-12">
                                                                    <div class="table-responsive">
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered"
                                                                            formArrayName="material_tags">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Sl.No </th>
                                                                                    <th>Material tags</th>
                                                                                    <th>Reason</th>
                                                                                    <th>Default reason</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody
                                                                                *ngFor="let item of GetFormArrayData('stock_count').controls;let bIndx=index;"
                                                                                [formGroupName]="bIndx">
                                                                                <tr>
                                                                                    <td>{{bIndx+1}}</td>
                                                                                    <td>{{item.get('material_tag_id')?.value}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type="text"
                                                                                            class="form-control form-control-sm"
                                                                                            formControlName="reason"
                                                                                            (change)="onAsgnRsns($event, 'stock_count', bIndx, '')">
                                                                                    </td>
                                                                                    <td>
                                                                                        <select
                                                                                            class="form-select form-select-sm"
                                                                                            formControlName="default_reason"
                                                                                            [attr.disabled]="item.get('reason')?.value == '' ? true : null">
                                                                                            <option value="">Select the
                                                                                                default stock count
                                                                                                reason
                                                                                            </option>
                                                                                            <option
                                                                                                *ngFor="let rsn of item.get('lstRsn')?.value"
                                                                                                [value]="rsn">{{rsn}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td *ngIf="cnfgInvPrmsn"
                                                                                        (click)="onDelMatTags(bIndx, 'stock_count', item?.get('material_tag_id')?.value)">
                                                                                        <i class="fa fa-trash"
                                                                                            aria-hidden="true"></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colspan="2">
                                                                                        <ng-template #cstmPrdctTag
                                                                                            let-model="item">
                                                                                            <span
                                                                                                class="font-weight-bold">{{model.name}}</span>
                                                                                        </ng-template>
                                                                                        <input name="prdctTag"
                                                                                            type="text"
                                                                                            placeholder="Enter the material tag"
                                                                                            id="prdctTag"
                                                                                            formControlName="material_tag_id"
                                                                                            [typeahead]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('stock_count')?.get('configure_by_material_tags')?.get('matsLst')?.value"
                                                                                            [typeaheadScrollable]="true"
                                                                                            [typeaheadItemTemplate]="cstmPrdctTag"
                                                                                            typeaheadOptionField="name"
                                                                                            [adaptivePosition]="true"
                                                                                            class="form-control form-control-sm w-31"
                                                                                            [typeaheadScrollable]="true" [typeaheadOptionsLimit]="500"
                                                                                            (typeaheadOnSelect)="onChngPrdtTag($event, 'stock_count')">
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="top-tab4" role="tabpanel"
                                            aria-labelledby="tab4-top-tab" formGroupName="discards">
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Reasons</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given reason does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        reasons is 500.</div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th class="text-center w-40" hidden>Default Reason
                                                                            </th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstDscrd; let i=index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.reasonName}}
                                                                            </td>
                                                                            <td class="text-center" hidden>
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="radio" id="'rbId'+{{i}}"
                                                                                        (change)="onChngRB('Discard', item)"
                                                                                        name="radioBtnName"
                                                                                        [checked]="item.isDefault">
                                                                                </div>
                                                                            </td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDel('Discard', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstDscrd?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="4">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Reason"
                                                                                    [(ngModel)]="dscrdTxt"
                                                                                    
                                                                                    formControlName="dscrdTxt">
                                                                            </td>
                                                                            <td rowspan="2" class="text-center" hidden>
                                                                                <button type="button" *ngIf="cnfgInvPrmsn"
                                                                                    class="btn btn-primary btn-sm"
                                                                                    (click)="clear('Discard')"
                                                                                    [disabled]="RDBchckd1">
                                                                                    Clear
                                                                                </button>
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDel('Discard', dscrdTxt, 'a', DscrdmndtRsn);dscrdTxt='';"
                                                                                *ngIf="cnfgInvPrmsn">Add Reasons</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="DscrdmndtRsn"
                                                                                        id="dscrdMTFM" (change)="DscrdmndtRsn=!DscrdmndtRsn;onChkRsn('Discard',DscrdmndtRsn)"
                                                                                        [attr.disabled]="lstTblData?.lstDscrd?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstDscrd[0]?.isReasonMandatory" -->
                                                                                    <label class="form-check-label" for="dscrdMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                                <div class="text-end pb-3">
                                                                 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">                                                        
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">Configure by
                                                                material categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" *ngIf="cnfgInvPrmsn"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons"
                                                                    (click)="CnfgMatTag($event, 'discards')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="form-group col-xl-4" *ngIf="shwMatTabl3">
                                                                <select class="form-select form-select-sm" formControlName="material_tag_id" (change)="DscrdmndtRsnCMC=false;onMatTagChng($event, 'Discard')">
                                                                    <option value="">-Select-</option>
                                                                    <option *ngFor="let mt of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('discards')?.get('configure_by_material_tags')?.get('matsLst')?.value" 
                                                                    [value]="mt.id">{{mt.name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="shwMatTabl3 && shwMatDscrdIssRsnsTab">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let rsn of lstTblData?.lstDsrcdBdgeRsn; let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{rsn.reasonName}}</td>
                                                                            <td>
                                                                                <i class="fa fa-trash toggle-data"
                                                                                aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                (click)="onAddDelBdRsn('Discard', rsn, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstDsrcdBdgeRsn?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Material Reason"
                                                                                    [(ngModel)]="dscrdBdgeTxt"
                                                                                    formControlName="dscrdTxt">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelBdRsn('DiscardCMC', dscrdBdgeTxt, 'a', DscrdmndtRsnCMC, true);dscrdBdgeTxt='';"
                                                                                    *ngIf="cnfgInvPrmsn">Add Material Reasons</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="DscrdmndtRsnCMC"
                                                                                        id="discardMCCR" (change)="DscrdmndtRsnCMC=!DscrdmndtRsnCMC;onChkRsn('DiscardCMC',DscrdmndtRsnCMC)"
                                                                                        [attr.disabled]="lstTblData?.lstDsrcdBdgeRsn?.length == 0 ? true : null">
                                                                                    <label class="form-check-label" for="discardMCCR">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <div class="text-end pb-3">
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Reasons for
                                                                discards</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="reasons_for_discards"
                                                                (change)="onAsgnRsns($event, 'discards', null, 'reasons_for_discards')">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Default reason for
                                                                discards</label>
                                                            <select class="form-select form-select-sm"
                                                                formControlName="default_reason_for_discards"
                                                                [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('discards')?.get('reasons_for_discards')?.value == '' ? true : null">
                                                                <option value="">Select the default discards reason
                                                                </option>
                                                                <option
                                                                    *ngFor="let rsn of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('discards')?.get('lstRsn')?.value"
                                                                    [value]="rsn">{{rsn}}</option>
                                                            </select>
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('discards')?.get('reasons_for_discards')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">Configure by
                                                                material categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons" *ngIf="cnfgInvPrmsn"
                                                                    (click)="CnfgMatTag($event, 'discards')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="row" *ngIf="shwMatTabl3">
                                                                <div class="col-xl-12">
                                                                    <div class="table-responsive">
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered"
                                                                            formArrayName="material_tags">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Sl.No </th>
                                                                                    <th>Material tags</th>
                                                                                    <th>Reason</th>
                                                                                    <th>Default reason</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody
                                                                                *ngFor="let item of GetFormArrayData('discards').controls;let bIndx=index;"
                                                                                [formGroupName]="bIndx">
                                                                                <tr>
                                                                                    <td>{{bIndx+1}}</td>
                                                                                    <td>{{item.get('material_tag_id')?.value}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type="text"
                                                                                            class="form-control form-control-sm"
                                                                                            formControlName="reason"
                                                                                            (change)="onAsgnRsns($event, 'discards', bIndx, '')">
                                                                                    </td>
                                                                                    <td>
                                                                                        <select
                                                                                            class="form-select form-select-sm"
                                                                                            formControlName="default_reason"
                                                                                            [attr.disabled]="item.get('reason')?.value == '' ? true : null">
                                                                                            <option value="">Select the
                                                                                                default discards reason
                                                                                            </option>
                                                                                            <option
                                                                                                *ngFor="let rsn of item.get('lstRsn')?.value"
                                                                                                [value]="rsn">{{rsn}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td  *ngIf="cnfgInvPrmsn"
                                                                                        (click)="onDelMatTags(bIndx, 'discards', item?.get('material_tag_id')?.value)">
                                                                                        <i class="fa fa-trash"
                                                                                            aria-hidden="true"></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colspan="2">
                                                                                        <ng-template #cstmPrdctTag
                                                                                            let-model="item">
                                                                                            <span
                                                                                                class="font-weight-bold">{{model.name}}</span>
                                                                                        </ng-template>
                                                                                        <input name="prdctTag"
                                                                                            type="text"
                                                                                            placeholder="Enter the material tag"
                                                                                            id="prdctTag"
                                                                                            formControlName="material_tag_id"
                                                                                            [typeahead]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('discards')?.get('configure_by_material_tags')?.get('matsLst')?.value"
                                                                                            [typeaheadScrollable]="true"
                                                                                            [typeaheadItemTemplate]="cstmPrdctTag"
                                                                                            typeaheadOptionField="name"
                                                                                            [adaptivePosition]="true"
                                                                                            class="form-control form-control-sm w-31"
                                                                                            [typeaheadScrollable]="true" [typeaheadOptionsLimit]="500"
                                                                                            (typeaheadOnSelect)="onChngPrdtTag($event, 'discards')">
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="top-tab5" role="tabpanel"
                                            aria-labelledby="tab5-top-tab" formGroupName="transfer">
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Reasons</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given reason does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        reasons is 500.</div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th class="text-center w-40" hidden>Default Reason
                                                                            </th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstTrnsfr; let i=index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.reasonName}}
                                                                            </td>
                                                                            <td class="text-center" hidden>
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="radio" id="'rbId'+{{i}}"
                                                                                        name="radioBtnName1"
                                                                                        (change)="onChngRB('Transfer', item);"
                                                                                        [checked]="item.isDefault">
                                                                                </div>
                                                                            </td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDel('Transfer', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstTrnsfr?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="4">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Reason"
                                                                                    [(ngModel)]="trnsfrTxt"
                                                                                    formControlName="trnsfrTxt">
                                                                            </td>
                                                                            <td rowspan="2" class="text-center" hidden>
                                                                                <button type="button"  *ngIf="cnfgInvPrmsn"
                                                                                    class="btn btn-primary btn-sm"
                                                                                    (click)="clear('Transfer')"
                                                                                    [disabled]="RDBchckd">
                                                                                    Clear
                                                                                </button>
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDel('Transfer', trnsfrTxt, 'a', TnsfrmndtRsn);trnsfrTxt='';"
                                                                             *ngIf="cnfgInvPrmsn">Add Reasons</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="TnsfrmndtRsn"
                                                                                        id="trnsfrMTFM" (change)="TnsfrmndtRsn=!TnsfrmndtRsn;onChkRsn('Transfer',TnsfrmndtRsn)"
                                                                                        [attr.disabled]="lstTblData?.lstTrnsfr?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstTrnsfr[0]?.isReasonMandatory" -->
                                                                                    <label class="form-check-label" for="trnsfrMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3">
                                                                
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row">                                                        
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">Configure by
                                                                material categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons"  *ngIf="cnfgInvPrmsn"
                                                                    (click)="CnfgMatTag($event, 'transfer')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="form-group col-xl-4" *ngIf="shwMatTabl4">
                                                                <select class="form-select form-select-sm" formControlName="material_tag_id" (change)="TnsfrmndtRsnCMC=false;onMatTagChng($event, 'Transfer')">
                                                                    <option value="">-Select-</option>
                                                                    <option *ngFor="let mt of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('transfer')?.get('configure_by_material_tags')?.get('matsLst')?.value" 
                                                                    [value]="mt.id">{{mt.name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="shwMatTabl4 && shwMatTrnsfrRsnsTab">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let rsn of lstTblData?.lstTrsfrBdgeRsn; let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{rsn.reasonName}}</td>
                                                                            <td>
                                                                                <i class="fa fa-trash toggle-data"
                                                                                aria-hidden="true"  *ngIf="cnfgInvPrmsn"
                                                                                (click)="onAddDelBdRsn('Transfer', rsn, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstTrsfrBdgeRsn?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Material Reason"
                                                                                    [(ngModel)]="trnsfrBdgeTxt"
                                                                                    
                                                                                    formControlName="trnsfrTxt">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelBdRsn('TransferCMC', trnsfrBdgeTxt, 'a', TnsfrmndtRsnCMC, true);trnsfrBdgeTxt='';"
                                                                                *ngIf="cnfgInvPrmsn">Add Material Reasons</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="TnsfrmndtRsnCMC"
                                                                                        id="tnsfrMCCR" (change)="TnsfrmndtRsnCMC=!TnsfrmndtRsnCMC;onChkRsn('TransferCMC',TnsfrmndtRsnCMC)"
                                                                                        [attr.disabled]="lstTblData?.lstTrsfrBdgeRsn?.length == 0 ? true : null">
                                                                                    <label class="form-check-label" for="tnsfrMCCR">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3">
                                                              
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Reasons for
                                                                transfer</label>
                                                            <input type="text" placeholder=""
                                                                formControlName="reasons_for_transfer"
                                                                class="form-control form-control-sm"
                                                                (change)="onAsgnRsns($event, 'transfer', null, 'reasons_for_transfer')">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Default Reasons for
                                                                transfer</label>
                                                            <select class="form-select form-select-sm"
                                                                formControlName="default_reason_for_transfer"
                                                                [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('transfer')?.get('reasons_for_transfer')?.value == '' ? true : null">
                                                                <option value="">Select the default transfer reason
                                                                </option>
                                                                <option
                                                                    *ngFor="let rsn of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('transfer')?.get('lstRsn')?.value"
                                                                    [value]="rsn">{{rsn}}</option>
                                                            </select>
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('transfer')?.get('reasons_for_transfer')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">Configure by
                                                                material categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons"  *ngIf="cnfgInvPrmsn"
                                                                    (click)="CnfgMatTag($event, 'transfer')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="row" *ngIf="shwMatTabl4">
                                                                <div class="col-xl-12">
                                                                    <div class="table-responsive">
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered"
                                                                            formArrayName="material_tags">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Sl.No </th>
                                                                                    <th>Material tags</th>
                                                                                    <th>Reason</th>
                                                                                    <th>Default reason</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody
                                                                                *ngFor="let item of GetFormArrayData('transfer').controls;let bIndx=index;"
                                                                                [formGroupName]="bIndx">
                                                                                <tr>
                                                                                    <td>{{bIndx+1}}</td>
                                                                                    <td>{{item.get('material_tag_id')?.value}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type="text"
                                                                                            class="form-control form-control-sm"
                                                                                            formControlName="reason"
                                                                                            (change)="onAsgnRsns($event, 'transfer', bIndx, '')">
                                                                                    </td>
                                                                                    <td>
                                                                                        <select
                                                                                            class="form-select form-select-sm"
                                                                                            formControlName="default_reason"
                                                                                            [attr.disabled]="item.get('reason')?.value == '' ? true : null">
                                                                                            <option value="">Select the
                                                                                                default transfer reason
                                                                                            </option>
                                                                                            <option
                                                                                                *ngFor="let rsn of item.get('lstRsn')?.value"
                                                                                                [value]="rsn">{{rsn}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td  *ngIf="cnfgInvPrmsn"
                                                                                        (click)="onDelMatTags(bIndx, 'transfer', item?.get('material_tag_id')?.value)">
                                                                                        <i class="fa fa-trash"
                                                                                            aria-hidden="true"></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colspan="2">
                                                                                        <ng-template #cstmPrdctTag
                                                                                            let-model="item">
                                                                                            <span
                                                                                                class="font-weight-bold">{{model.name}}</span>
                                                                                        </ng-template>
                                                                                        <input name="prdctTag"
                                                                                            type="text"
                                                                                            placeholder="Enter the material tag"
                                                                                            id="prdctTag"
                                                                                            formControlName="material_tag_id"
                                                                                            [typeahead]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('transfer')?.get('configure_by_material_tags')?.get('matsLst')?.value"
                                                                                            [typeaheadScrollable]="true"
                                                                                            [typeaheadItemTemplate]="cstmPrdctTag"
                                                                                            typeaheadOptionField="name"
                                                                                            [adaptivePosition]="true"
                                                                                            class="form-control form-control-sm w-31"
                                                                                            [typeaheadScrollable]="true" [typeaheadOptionsLimit]="500"
                                                                                            (typeaheadOnSelect)="onChngPrdtTag($event, 'transfer')">
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="top-tab6" role="tabpanel"
                                            aria-labelledby="tab6-top-tab" formGroupName="incoming_returns" hidden>
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Reasons</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given reason does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        reasons is 500.</div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th class="text-center w-40">Default Reason
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="text-center">1</td>
                                                                            <td class="text-center">ABC</td>
                                                                            <td class="text-center">
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="radio" name="test1">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <!-- <tbody [hidden]="lstTabsData?.lstPT?.length!=0">
                                                                            <tr>
                                                                                <td class="text-center" colspan="3">
                                                                                    {{noRcrdTxt}}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody> -->
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Reason">
                                                                            </td>
                                                                            <td rowspan="2" class="text-center">
                                                                                <button type="button"
                                                                                    class="btn btn-secondary btn-sm">
                                                                                    Clear
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    (click)="isChk=!isChk">&nbsp;&nbsp;
                                                                                <label class="form-check-label">
                                                                                    Make this field mandatory
                                                                                </label>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Reasons for incoming
                                                                returns</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="reasons_for_incoming_returns"
                                                                (change)="onAsgnRsns($event, 'incoming_returns', null, 'reasons_for_incoming_returns')">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Default Reasons for
                                                                incoming
                                                                returns</label>
                                                            <select class="form-select form-select-sm"
                                                                formControlName="default_reason_for_incoming_returns"
                                                                [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('incoming_returns')?.get('reasons_for_incoming_returns')?.value == '' ? true : null">
                                                                <option value="">Select the default incoming
                                                                    returns reason</option>
                                                                <option
                                                                    *ngFor="let rsn of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('incoming_returns')?.get('lstRsn')?.value"
                                                                    [value]="rsn">{{rsn}}</option>
                                                            </select>
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('incoming_returns')?.get('reasons_for_incoming_returns')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">Configure by
                                                                material
                                                                categories</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons"  *ngIf="cnfgInvPrmsn"
                                                                    (click)="CnfgMatTag($event, 'incoming_returns')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="row" *ngIf="shwMatTabl5">
                                                                <div class="col-xl-12">
                                                                    <div class="table-responsive">
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered"
                                                                            formArrayName="material_tags">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Sl.No </th>
                                                                                    <th>Material tags</th>
                                                                                    <th>Reason</th>
                                                                                    <th>Default reason</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody
                                                                                *ngFor="let item of GetFormArrayData('incoming_returns').controls;let bIndx=index;"
                                                                                [formGroupName]="bIndx">
                                                                                <tr>
                                                                                    <td>{{bIndx+1}}</td>
                                                                                    <td>{{item.get('material_tag_id')?.value}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type="text"
                                                                                            class="form-control form-control-sm"
                                                                                            formControlName="reason"
                                                                                            (change)="onAsgnRsns($event, 'incoming_returns', bIndx, '')">
                                                                                    </td>
                                                                                    <td>
                                                                                        <select
                                                                                            class="form-select form-select-sm"
                                                                                            formControlName="default_reason"
                                                                                            [attr.disabled]="item.get('reason')?.value == '' ? true : null">
                                                                                            <option value="">Select the
                                                                                                default incoming returns
                                                                                                reason
                                                                                            </option>
                                                                                            <option
                                                                                                *ngFor="let rsn of item.get('lstRsn')?.value"
                                                                                                [value]="rsn">{{rsn}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td  *ngIf="cnfgInvPrmsn"
                                                                                        (click)="onDelMatTags(bIndx, 'incoming_returns', item?.get('material_tag_id')?.value)">
                                                                                        <i class="fa fa-trash"
                                                                                            aria-hidden="true"></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colspan="2">
                                                                                        <ng-template #cstmPrdctTag
                                                                                            let-model="item">
                                                                                            <span
                                                                                                class="font-weight-bold">{{model.name}}</span>
                                                                                        </ng-template>
                                                                                        <input name="prdctTag"
                                                                                            type="text"
                                                                                            placeholder="Enter the material tag"
                                                                                            id="prdctTag"
                                                                                            formControlName="material_tag_id"
                                                                                            [typeahead]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('incoming_returns')?.get('configure_by_material_tags')?.get('matsLst')?.value"
                                                                                            [typeaheadScrollable]="true"
                                                                                            [typeaheadItemTemplate]="cstmPrdctTag"
                                                                                            typeaheadOptionField="name"
                                                                                            [adaptivePosition]="true"
                                                                                            class="form-control form-control-sm w-31"
                                                                                            [typeaheadScrollable]="true" [typeaheadOptionsLimit]="500"
                                                                                            (typeaheadOnSelect)="onChngPrdtTag($event, 'incoming_returns')">
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="top-tab7" role="tabpanel"
                                            aria-labelledby="tab7-top-tab" formGroupName="outgoing_returns" hidden>
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Reasons</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given reason does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        reasons is 500.</div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th class="text-center w-40">Default Reason
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="text-center">1</td>
                                                                            <td class="text-center">ABC</td>
                                                                            <td class="text-center">
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="radio" name="test1">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <!-- <tbody [hidden]="lstTabsData?.lstPT?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody> -->
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Reason">
                                                                            </td>
                                                                            <td rowspan="2" class="text-center">
                                                                                <button type="button"
                                                                                    class="btn btn-secondary btn-sm">
                                                                                    Clear
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    (click)="isChk=!isChk">&nbsp;&nbsp;
                                                                                <label class="form-check-label">
                                                                                    Make this field mandatory
                                                                                </label>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Reasons for outgoing
                                                                returns</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="reasons_for_outgoing_returns"
                                                                (change)="onAsgnRsns($event, 'outgoing_returns', null, 'reasons_for_outgoing_returns')">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">
                                                                Default Reasons for outgoing returns
                                                            </label>
                                                            <select class="form-select form-select-sm"
                                                                formControlName="default_reason_for_outgoing_returns"
                                                                [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('outgoing_returns')?.get('reasons_for_outgoing_returns')?.value == '' ? true : null">
                                                                <option value="">Select the default outgoing
                                                                    returns reason</option>
                                                                <option
                                                                    *ngFor="let rsn of cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('outgoing_returns')?.get('lstRsn')?.value"
                                                                    [value]="rsn">{{rsn}}</option>
                                                            </select>
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('outgoing_returns')?.get('reasons_for_outgoing_returns')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-12 mt-2"
                                                            formGroupName="configure_by_material_tags">
                                                            <label for="textBox" class="mb-0 f-w-500">
                                                                Configure by material categories
                                                            </label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="specify_the_material_tags_and_corresponding_reasons"  *ngIf="cnfgInvPrmsn"
                                                                    (click)="CnfgMatTag($event, 'outgoing_returns')">
                                                                <label class="form-check-label">
                                                                    Specify the material categories and corresponding
                                                                    reasons
                                                                </label>
                                                            </div>
                                                            <div class="row" *ngIf="shwMatTabl6">
                                                                <div class="col-xl-12">
                                                                    <div class="table-responsive">
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered"
                                                                            formArrayName="material_tags">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Sl.No </th>
                                                                                    <th>Material tags</th>
                                                                                    <th>Reason</th>
                                                                                    <th>Default reason</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody
                                                                                *ngFor="let item of GetFormArrayData('outgoing_returns').controls;let bIndx=index;"
                                                                                [formGroupName]="bIndx">
                                                                                <tr>
                                                                                    <td>{{bIndx+1}}</td>
                                                                                    <td>{{item.get('material_tag_id')?.value}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type="text"
                                                                                            class="form-control form-control-sm"
                                                                                            formControlName="reason"
                                                                                            (change)="onAsgnRsns($event, 'outgoing_returns', bIndx, '')">
                                                                                    </td>
                                                                                    <td>
                                                                                        <select
                                                                                            class="form-select form-select-sm"
                                                                                            formControlName="default_reason"
                                                                                            [attr.disabled]="item.get('reason')?.value == '' ? true : null">
                                                                                            <option value="">Select the
                                                                                                default outgoing returns
                                                                                                reason
                                                                                            </option>
                                                                                            <option
                                                                                                *ngFor="let rsn of item.get('lstRsn')?.value"
                                                                                                [value]="rsn">{{rsn}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td  *ngIf="cnfgInvPrmsn"
                                                                                        (click)="onDelMatTags(bIndx, 'outgoing_returns', item?.get('material_tag_id')?.value)">
                                                                                        <i class="fa fa-trash" 
                                                                                            aria-hidden="true"></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table
                                                                            class="table table-striped table1 table-sm table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colspan="2">
                                                                                        <ng-template #cstmPrdctTag
                                                                                            let-model="item">
                                                                                            <span
                                                                                                class="font-weight-bold">{{model.name}}</span>
                                                                                        </ng-template>
                                                                                        <input name="prdctTag"
                                                                                            type="text"
                                                                                            placeholder="Enter the material tag"
                                                                                            id="prdctTag"
                                                                                            formControlName="material_tag_id"
                                                                                            [typeahead]="cnfgInvFrm.get('configJson')?.get('transaction_reasons')?.get('outgoing_returns')?.get('configure_by_material_tags')?.get('matsLst')?.value"
                                                                                            [typeaheadScrollable]="true"
                                                                                            [typeaheadItemTemplate]="cstmPrdctTag"
                                                                                            typeaheadOptionField="name"
                                                                                            [adaptivePosition]="true"
                                                                                            class="form-control form-control-sm w-31"
                                                                                            [typeaheadScrollable]="true" [typeaheadOptionsLimit]="500"
                                                                                            (typeaheadOnSelect)="onChngPrdtTag($event, 'outgoing_returns')">
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-0" formGroupName="material_status">
                        <div class="card-header p-2">
                            <h6>Material status</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">Enter a comma-separated list of statuses for materials. Ensure that each
                                status is less than 15 characters. For instance, discard statuses can be entered as
                                follows: Damaged,Expired,Lost.</div>
                            <div class="mt-3">
                                <div class="">
                                    <div class="nav nav-tabs border-tab mb-2" id="v-pills-tab" role="tablist"
                                        aria-orientation="vertical">
                                        <a class="nav-link f-w-500 active" id="top-m-tab1-tab" data-bs-toggle="tab"
                                            href="#top-m-tab1" role="tab" aria-controls="top-tab1"
                                            aria-selected="true">Issues/Net Utilization</a>
                                        <a class="nav-link f-w-500" id="m-tab2-top-tab" data-bs-toggle="tab"
                                            href="#m-top-tab2" role="tab" aria-controls="top-tab2"
                                            aria-selected="false">Stock-In</a>
                                        <a class="nav-link f-w-500" id="m-tab3-top-tab" data-bs-toggle="tab"
                                            href="#m-top-tab3" role="tab" aria-controls="top-tab3"
                                            aria-selected="false">Stock-Edit</a>
                                        <a class="nav-link f-w-500" id="m-tab4-top-tab" data-bs-toggle="tab"
                                            href="#m-top-tab4" role="tab" aria-controls="top-tab4"
                                            aria-selected="false">Stock-Discards</a>
                                        <a class="nav-link f-w-500" id="m-tab5-top-tab" data-bs-toggle="tab"
                                            href="#m-top-tab5" role="tab" aria-controls="top-tab5"
                                            aria-selected="false">Transfer</a>
                                        <a class="nav-link f-w-500" id="m-tab6-top-tab" data-bs-toggle="tab"
                                            href="#m-top-tab6" role="tab" aria-controls="top-tab6" aria-selected="false"
                                            hidden>Incoming returns</a>
                                        <a class="nav-link f-w-500" id="m-tab7-top-tab" data-bs-toggle="tab"
                                            href="#m-top-tab7" role="tab" aria-controls="top-tab7" aria-selected="false"
                                            hidden>Outgoing returns</a>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="tab-content" id="top-tabContent">
                                        <div class="tab-pane fade show active" id="top-m-tab1" role="tabpanel"
                                            aria-labelledby="top-m-tab1-tab" formGroupName="issue">
                                            <div class="card border-0 shadow-sm p-0 m-0" formGroupName="status">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Status</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given status does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        statuses is 500.
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-5">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstSTIssue;let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.name}}</td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDelete('Issue', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstSTIssue?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Status"
                                                                                    [(ngModel)]="issStTxt"
                                                                                    formControlName="issStTxt">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelete('Issue', issStTxt, 'a', Issmndt);issStTxt='';"
                                                                                *ngIf="cnfgInvPrmsn">Add Status</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="Issmndt"
                                                                                        id="issStMTFM" (change)="Issmndt=!Issmndt;onChk('Issue',Issmndt)"
                                                                                        [attr.disabled]="lstTblData?.lstSTIssue?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstSTIssue[0]?.isStatusMandatory" -->
                                                                                    <label class="form-check-label" for="issStMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3 pt-3">
                                                         
                                                            </div> -->
                                                        </div>
                                                        <div class="col-sm-7">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Temperature Sensitive Material Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstSTIssueTS;let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.name}}</td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDelete('IssueTS', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstSTIssueTS?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Temperature Sensitive Material Status"
                                                                                    [(ngModel)]="issStTxtTS"
                                                                                 
                                                                                    [ngModelOptions]="{standalone: true}">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success"    (click)="onAddDelete('IssueTS', issStTxtTS, 'a', IssmndtTS);issStTxtTS='';"
                                                                                *ngIf="cnfgInvPrmsn">Add Status</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" id="issStMTFM" 
                                                                                        (change)="IssmndtTS=!IssmndtTS;onChk('IssueTS',IssmndtTS)"
                                                                                        [checked]="lstTblData?.lstSTIssueTS[0]?.isStatusMandatory"
                                                                                        [attr.disabled]="lstTblData?.lstSTIssueTS?.length == 0 ? true : null">
                                                                                    <label class="form-check-label" for="issStMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3 pt-3">
                                                                
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Status for issue</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_issue">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">
                                                                Status for temperature sensitive materials
                                                            </label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_temperature_sensitive_materials">
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('material_status')?.get('issue')?.get('status')?.get('status_for_temperature_sensitive_materials')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="m-top-tab2" role="tabpanel"
                                            aria-labelledby="m-tab2-top-tab" formGroupName="receipt">
                                            <div class="card border-0 shadow-sm p-0 m-0" formGroupName="status">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Status</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given status does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        statuses is 500.
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-5">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstStRcpt;let i=index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.name}}</td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDelete('Receipt', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstStRcpt?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Status"
                                                                                    [(ngModel)]="RcptStTxt"
                                                                                    
                                                                                    formControlName="RcptStTxt">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelete('Receipt', RcptStTxt, 'a', Rcptmndt);RcptStTxt='';"
                                                                                 *ngIf="cnfgInvPrmsn">Add Status</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="Rcptmndt"
                                                                                        id="rcptStMTFM" (change)="Rcptmndt=!Rcptmndt;onChk('Receipt',Rcptmndt)"
                                                                                        [attr.disabled]="lstTblData?.lstStRcpt?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstStRcpt[0]?.isStatusMandatory" -->
                                                                                    <label class="form-check-label" for="rcptStMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3 pt-3">
                                                                
                                                            </div> -->
                                                        </div>
                                                        <div class="col-sm-7">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Temperature Sensitive Material Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstStRcptTS;let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.name}}</td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDelete('ReceiptTS', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstStRcptTS?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Temperature Sensitive Material Status"
                                                                                    [(ngModel)]="RcptStTxtTS"
                                                                                    [ngModelOptions]="{standalone: true}">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelete('ReceiptTS', RcptStTxtTS, 'a', RcptmndtTS);RcptStTxtTS='';"
                                                                                (click)="onSaveStatus('ReceiptTS')" *ngIf="cnfgInvPrmsn">Add Status</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" id="issStMTFM"
                                                                                        (change)="RcptmndtTS=!RcptmndtTS;onChk('ReceiptTS',RcptmndtTS)"
                                                                                        [checked]="lstTblData?.lstStRcptTS[0]?.isStatusMandatory"
                                                                                        [attr.disabled]="lstTblData?.lstStRcptTS?.length == 0 ? true : null">
                                                                                    <label class="form-check-label" for="issStMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3 pt-3">
                                                                
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Status for
                                                                receipt</label>
                                                            <input type="text" placeholder=""
                                                                formControlName="status_for_receipt"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Status for temperature
                                                                sensitive materials</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_temperature_sensitive_materials">
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('material_status')?.get('receipt')?.get('status')?.get('status_for_temperature_sensitive_materials')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="m-top-tab3" role="tabpanel"
                                            aria-labelledby="m-tab3-top-tab" formGroupName="stock_count">
                                            <div class="card border-0 shadow-sm p-0 m-0" formGroupName="status">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Status</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given status does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        statuses is 500.
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-5">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstStstkCnt;let i=index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.name}}</td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDelete('Stock Count', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody
                                                                        [hidden]="lstTblData?.lstStstkCnt?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Status"
                                                                                    [(ngModel)]="StkCntStTxt"
                                                                                    formControlName="StkCntStTxt">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelete('Stock Count', StkCntStTxt, 'a', Stkmndt);StkCntStTxt='';"
                                                                               *ngIf="cnfgInvPrmsn">Add Status</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="Stkmndt"
                                                                                        id="sCntStMTFM" (change)="Stkmndt=!Stkmndt;onChk('Stock Count',Stkmndt)"
                                                                                        [attr.disabled]="lstTblData?.lstStstkCnt?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstStstkCnt[0]?.isStatusMandatory" -->
                                                                                    <label class="form-check-label" for="sCntStMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3 pt-3">
                                                                
                                                            </div> -->
                                                        </div>
                                                        <div class="col-sm-7">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Temperature Sensitive Material Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstStstkCntTS;let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.name}}</td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true" *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDelete('StkCntTS', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstStstkCntTS?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Temperature Sensitive Material Status"
                                                                                    [(ngModel)]="StkCntStTxtTS"
                                                                                   
                                                                                    [ngModelOptions]="{standalone: true}">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success"  (click)="onAddDelete('StkCntTS', StkCntStTxtTS, 'a', StkmndtTS);StkCntStTxtTS='';"
                                                                                 *ngIf="cnfgInvPrmsn">Add Status</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" id="issStMTFM"
                                                                                        (change)="StkmndtTS=!StkmndtTS;onChk('StkCntTS',StkmndtTS)"
                                                                                        [checked]="lstTblData?.lstStstkCntTS[0]?.isStatusMandatory"
                                                                                        [attr.disabled]="lstTblData?.lstStstkCntTS?.length == 0 ? true : null">
                                                                                    <label class="form-check-label" for="issStMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3 pt-3">
                                                                
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Status for stock
                                                                count</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_stock_count">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">
                                                                Status for temperature sensitive materials
                                                            </label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_temperature_sensitive_materials">
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('material_status')?.get('stock_count')?.get('status')?.get('status_for_temperature_sensitive_materials')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="m-top-tab4" role="tabpanel"
                                            aria-labelledby="m-tab4-top-tab" formGroupName="discard">
                                            <div class="card border-0 shadow-sm p-0 m-0" formGroupName="status">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Status</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given status does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        statuses is 500.
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-5">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstStDsrcd;let i=index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.name}}</td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true"  *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDelete('Discard', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstStDsrcd?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Status"
                                                                                    [(ngModel)]="DscrdsStTxt"
                                                                                    formControlName="DscrdsStTxt">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelete('Discard', DscrdsStTxt, 'a', Dscrdmndt);DscrdsStTxt='';"
                                                                                 *ngIf="cnfgInvPrmsn">Add Status</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="Dscrdmndt"
                                                                                        id="dcrdStMTFM" (change)="Dscrdmndt=!Dscrdmndt;onChk('Discard',Dscrdmndt)"
                                                                                        [attr.disabled]="lstTblData?.lstStDsrcd?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstStDsrcd[0]?.isStatusMandatory" -->
                                                                                    <label class="form-check-label" for="dcrdStMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3 pt-3">
                                                                
                                                            </div> -->
                                                        </div>
                                                        <div class="col-sm-7">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Temperature Sensitive Material Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstStDsrcdTS;let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.name}}</td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true"  *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDelete('DiscrdTS', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstStDsrcdTS?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Temperature Sensitive Material Status"
                                                                                    [(ngModel)]="DscrdsStTxtTS"
                                                                                    [ngModelOptions]="{standalone: true}">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelete('DiscrdTS', DscrdsStTxtTS, 'a', DscrdmndtTS);DscrdsStTxtTS='';"
                                                                                *ngIf="cnfgInvPrmsn">Add Status</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" id="issStMTFM"
                                                                                        (change)="DscrdmndtTS=!DscrdmndtTS;onChk('DiscrdTS',DscrdmndtTS)"
                                                                                        [checked]="lstTblData?.lstStDsrcdTS[0]?.isStatusMandatory"
                                                                                        [attr.disabled]="lstTblData?.lstStDsrcdTS?.length == 0 ? true : null">
                                                                                    <label class="form-check-label" for="issStMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3 pt-3">
                                                           
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Status for discard</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_discard">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">
                                                                Status for temperature sensitive materials
                                                            </label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_temperature_sensitive_materials">
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('material_status')?.get('discard')?.get('status')?.get('status_for_temperature_sensitive_materials')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="m-top-tab5" role="tabpanel"
                                            aria-labelledby="m-tab5-top-tab" formGroupName="transfer">
                                            <div class="card border-0 shadow-sm p-0 m-0" formGroupName="status">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Status</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given status does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        statuses is 500.
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-5">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstStTrnsfr;let i=index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.name}}</td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true"  *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDelete('Transfer', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody
                                                                        [hidden]="lstTblData?.lstStTrnsfr?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Status"
                                                                                    [(ngModel)]="TrnsfrStTxt"
                                                                                    formControlName="TrnsfrStTxt">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelete('Transfer', TrnsfrStTxt, 'a', Tnsfrmndt);TrnsfrStTxt='';"
                                                                                 *ngIf="cnfgInvPrmsn">Add Status</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" [checked]="Tnsfrmndt"
                                                                                        id="trfrStMTFM" (change)="Tnsfrmndt=!Tnsfrmndt;onChk('Transfer',Tnsfrmndt)"
                                                                                        [attr.disabled]="lstTblData?.lstStTrnsfr?.length == 0 ? true : null">
                                                                                        <!-- [checked]="lstTblData?.lstStTrnsfr[0]?.isStatusMandatory" -->
                                                                                    <label class="form-check-label" for="trfrStMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <div class="text-end pb-3 pt-3">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-7">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Temperature Sensitive Material Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let item of lstTblData?.lstStTrnsfrTS;let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td class="text-center">{{item.name}}</td>
                                                                            <td><i class="fa fa-trash toggle-data"
                                                                                    aria-hidden="true"  *ngIf="cnfgInvPrmsn"
                                                                                    (click)="onAddDelete('TnsfrTS', item, 'd')"></i>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody [hidden]="lstTblData?.lstStTrnsfrTS?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Temperature Sensitive Material Status"
                                                                                    [(ngModel)]="TrnsfrStTxtTS"
                                                                                    
                                                                                    [ngModelOptions]="{standalone: true}">
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm btn-success" (click)="onAddDelete('TnsfrTS', TrnsfrStTxtTS, 'a', TnsfrmndtTS);TrnsfrStTxtTS='';"
                                                                                *ngIf="cnfgInvPrmsn">Add Status</button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" id="issStMTFM"
                                                                                        (change)="TnsfrmndtTS=!TnsfrmndtTS;onChk('TnsfrTS',TnsfrmndtTS)"
                                                                                        [checked]="lstTblData?.lstStTrnsfrTS[0]?.isStatusMandatory"
                                                                                        [attr.disabled]="lstTblData?.lstStTrnsfrTS?.length == 0 ? true : null">
                                                                                    <label class="form-check-label" for="issStMTFM">
                                                                                        Make this field mandatory
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="text-end pb-3 pt-3">
                                                            
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Status for
                                                                transfer</label>
                                                            <input type="text" placeholder=""
                                                                formControlName="status_for_transfer"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">
                                                                Status for temperature sensitive materials
                                                            </label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_temperature_sensitive_materials">
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('material_status')?.get('transfer')?.get('status')?.get('status_for_temperature_sensitive_materials')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="m-top-tab6" role="tabpanel"
                                            aria-labelledby="m-tab6-top-tab" formGroupName="incoming_returns" hidden>
                                            <div class="card border-0 shadow-sm p-0 m-0" formGroupName="status">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Status</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given status does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        statuses is 500.
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th class="text-center w-40">Default Reason
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="text-center">1</td>
                                                                            <td class="text-center">ABC</td>
                                                                            <td class="text-center">
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="radio" name="test1">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <!-- <tbody [hidden]="lstTabsData?.lstPT?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody> -->
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Reason">
                                                                            </td>
                                                                            <td rowspan="2" class="text-center">
                                                                                <button type="button"
                                                                                    class="btn btn-secondary btn-sm">
                                                                                    Clear
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    (click)="isChk=!isChk">&nbsp;&nbsp;
                                                                                <label class="form-check-label">
                                                                                    Make this field mandatory
                                                                                </label>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Status for incoming
                                                                returns</label>
                                                            <input type="text" placeholder=""
                                                                formControlName="status_for_incoming_returns"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">
                                                                Status for temperature sensitive materials
                                                            </label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_temperature_sensitive_materials">
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('material_status')?.get('incoming_returns')?.get('status')?.get('status_for_temperature_sensitive_materials')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="m-top-tab7" role="tabpanel"
                                            aria-labelledby="m-tab7-top-tab" formGroupName="outgoing_returns" hidden>
                                            <div class="card border-0 shadow-sm p-0 m-0" formGroupName="status">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Status</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">Please ensure that a given status does not exceed
                                                        100
                                                        characters. The total number of characters allowed across all
                                                        statuses is 500.
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive mt-2">
                                                                <table
                                                                    class="table table-striped table-sm table-bordered w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center w-24">Item No.</th>
                                                                            <th class="text-center">Reason</th>
                                                                            <th class="text-center w-40">Default Reason
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="text-center">1</td>
                                                                            <td class="text-center">ABC</td>
                                                                            <td class="text-center">
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input class="form-check-input"
                                                                                        type="radio" name="test1">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <!-- <tbody [hidden]="lstTabsData?.lstPT?.length!=0">
                                                                        <tr>
                                                                            <td class="text-center" colspan="3">
                                                                                {{noRcrdTxt}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody> -->
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="Create custom Reason">
                                                                            </td>
                                                                            <td rowspan="2" class="text-center">
                                                                                <button type="button"
                                                                                    class="btn btn-secondary btn-sm">
                                                                                    Clear
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    (click)="isChk=!isChk">&nbsp;&nbsp;
                                                                                <label class="form-check-label">
                                                                                    Make this field mandatory
                                                                                </label>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" hidden>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">Status for outgoing
                                                                returns</label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_outgoing_returns">
                                                        </div>
                                                        <div class="form-group col-xl-6 mt-2">
                                                            <label for="textBox" class="mb-0">
                                                                Status for temperature sensitive materials
                                                            </label>
                                                            <input type="text" placeholder="" class="form-control form-control-sm"
                                                                formControlName="status_for_temperature_sensitive_materials">
                                                            <div class="form-check mt-1">
                                                                <input class="form-check-input" type="checkbox"
                                                                    formControlName="make_this_field_mandatory"
                                                                    [attr.disabled]="cnfgInvFrm.get('configJson')?.get('material_status')?.get('outgoing_returns')?.get('status')?.get('status_for_temperature_sensitive_materials')?.value == '' ? true : null">
                                                                <label class="form-check-label">
                                                                    Make this field mandatory
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-0">
                <div class="col-sm-12">
                    <div class="card card_1 p-0 mt-3 mb-3" formArrayName="returns_policy" [hidden]="true">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Returns policy</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">
                                Specify the policy around incoming and outgoing returns
                            </div>
                            <div class="table-responsive table-responsive1 mt-4">
                                <table class="table table-striped table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-center">Item No.</th>
                                            <th scope="col" class="text-center">Facility categories</th>
                                            <th scope="col" class="text-center">Return policy</th>
                                            <th scope="col" class="text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rp of GetFormArrayData('').controls;let rpIndx=index"
                                            [formGroupName]="rpIndx">
                                            <td class="text-center">{{rpIndx+1}}</td>
                                            <td>
                                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                    [data]="lstFacTags" placeholder="" formControlName="store_tags"
                                                    [(ngModel)]="{{rp?.get('store_tags')?.value}}"
                                                    (onSelect)="onItemSelect($event, rpIndx, 'store_tags')"
                                                    (onDeSelect)="onItemSelect($event, rpIndx, 'store_tags')"
                                                    (onSelectAll)="onSelectAll($event, rpIndx, 'store_tags')"
                                                    (onDeSelectAll)="onSelectAll($event, rpIndx, 'store_tags')">
                                                </ng-multiselect-dropdown><br /> -->
                                                <select class="form-select form-select-sm" formControlName="store_tags">
                                                    <option value="">-Select-</option>
                                                    <option *ngFor="let it of lstFacTags" value="{{it.id}}">{{it.name}}</option>
                                                </select>
                                            </td>
                                            <td formGroupName="return_policy">
                                                <div class="row">
                                                    <div class="col-sm-5">Incoming return duration</div>
                                                    <div class="col-sm-3">
                                                        <input type="text" placeholder="" class="form-control form-control-sm"
                                                            formControlName="incoming_return_duration_in_days">
                                                    </div>
                                                    <div class="col-sm-3">days</div>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-sm-5">Outgoing return duration</div>
                                                    <div class="col-sm-3">
                                                        <input type="text" placeholder="" class="form-control form-control-sm"
                                                            formControlName="outgoing_return_duration_in_days">
                                                    </div>
                                                    <div class="col-sm-3">days</div>
                                                </div>
                                            </td>
                                            <td (click)="onDelRtrn_plcy(rpIndx)"><i class="fa fa-trash"  *ngIf="cnfgInvPrmsn"
                                                    aria-hidden="true"></i></td>
                                        </tr>
                                    </tbody>
                                    <tfoot style="margin-top: 300px">
                                        <tr>
                                            <td colspan="4">
                                                <button type="button" class="btn btn-sm btn-primary"
                                                    (click)="onAddRP()">  *ngIf="cnfgInvPrmsn"
                                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-2 mb-0" formGroupName="permissions" [hidden]="true">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Permissions</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">
                                Specify global permissions for visibility or access to Stock Management.
                            </div>
                            <div class="form-group col-xl-12 mb-2 mt-3" formGroupName="stock_management_visibility">
                                <label for="textBox" class="f-w-500 mb-2 me-2">Stock Management visibility</label><br />
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox"
                                        formControlName="allow_stock_management_of_receiving_stores_to_be_visible_to_issuing_stores_during_issue_and_transfer_transactions">
                                    <label class="form-check-label">
                                        Allow Stock Management of receiving facilities to be visible to issuing facilities
                                        during issue and transfer transactions
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card p-0 mt-3 mb-2" formGroupName="transaction_data_export">
                        <div class="card-header p-2 ">
                            <h6 class="mb-0">Transaction data export</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">
                                Enable periodic export of transaction data in a Comma-Separated Values (CSV) file, which
                                is sent as an attachment to the specified email address.
                            </div>
                            <div class="form-group col-xl-12 mb-2 mt-3"
                                formGroupName="transaction_data_export_schedule">
                                <label for="textBox" class="f-w-500 mb-2 me-2">
                                    Transaction data export schedule</label><br />
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox"
                                        formControlName="enable_transaction_data_export" (change)="txnDataExpt=!txnDataExpt">
                                    <label class="form-check-label">
                                        Enable transaction data export (as Comma-separated values, CSV)
                                    </label>
                                </div>
                                <div class="card border p-0 shadow-sm col-xl-8" *ngIf="txnDataExpt" formGroupName="enabled_transaction_data_export">
                                    <div class="card-body p-4">
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-6">
                                                <div class="f-w-500">Every day at hour(s) (hh:mm)</div>
                                                <div>
                                                    <input type="text" class="col-xl-3 form-control form-control-sm"
                                                        placeholder="hh:mm" (change)="onAddHrs($event)"
                                                        onkeypress='return ((event.charCode >= 48 && event.charCode <= 57) ||
                                                            event.charCode == 58 || event.charCode == 44)' formControlName="everyday_hours">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-12">
                                                <div class="f-w-500">To</div>
                                                <div>
                                                    <div class="f-w-250">Select groups of users by tags</div>
                                                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="userBadges"
                                                        (onSelect)="onChng($event, 'usrBdge', 'os')" [(ngModel)]="defaultUsrTags"
                                                        (onSelectAll)="onChng($event, 'usrBdge', 'osa')"
                                                        (onDeSelect)="onChng($event, 'usrBdge', 'ds')"
                                                        (onDeSelectAll)="onChng($event, 'usrBdge', 'dsa')" formControlName="group_of_user_tags">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                                <div class="mt-2">
                                                    <div class="f-w-250">Select individual users</div>
                                                    <ng-multiselect-dropdown [settings]="usrDrpdwn" [data]="userLst"
                                                        (onSelect)="onChng($event, 'owner', 'os')" [(ngModel)]="defaultUsers"
                                                        (onSelectAll)="onChng($event, 'owner', 'osa')"
                                                        (onDeSelect)="onChng($event, 'owner', 'ds')"
                                                        (onDeSelectAll)="onChng($event, 'owner', 'dsa')" formControlName="group_of_users">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="card p-0 mt-3 mb-2" formGroupName="consumption_ratess">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Consumption based computation</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">
                                Enable Consumption to generate and forecast quantities for critical values like Minimum & Maximum Stock.
                            </div>
                            <!-- <div class="row">
                                <div class="form-group col-xl-12 mb-2 mt-3"
                                    formGroupName="consumption_rate_computation">
                                    <label for="textBox" class="f-w-500 mb-2 me-2">Consumption rate
                                        computation</label><br />
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="none" name="none"
                                            [(ngModel)]="slctdCnsmPrd" value="none">
                                        <label class="form-check-label">
                                            None
                                        </label>
                                    </div>
                                    <div class="form-check" formGroupName="manual">
                                        <input class="form-check-input" type="radio" formControlName="value"
                                            name="value" [(ngModel)]="slctdCnsmPrd" value="manual">
                                        <label class="form-check-label">
                                            Manual
                                        </label>
                                        <div class="card border p-0 shadow-sm col-xl-8" *ngIf="slctdCnsmPrd == 'manual'">
                                            <div class="card-body p-4">
                                                <div class="form-row row mb-2">
                                                    <div class="form-group col-xl-6">
                                                        <div class="f-w-500">Select period</div>
                                                        <select id="period_type" class="col-xl-3 form-select form-select-sm" formControlName="period_type">
                                                            <option>daily</option>
                                                            <option>Weekly</option>
                                                            <option>monthly</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-check" formGroupName="automatic">
                                        <input class="form-check-input" type="radio" formControlName="value"
                                            name="value" [(ngModel)]="slctdCnsmPrd" value="automatic">
                                        <label class="form-check-label">
                                            Automatic
                                        </label>
                                        <div class="card border p-0 shadow-sm col-xl-8"
                                            *ngIf="slctdCnsmPrd == 'automatic'">
                                            <div class="card-body p-4">
                                                <div class="form-row row mb-2">
                                                    <div class="form-group col-xl-6">
                                                        <div class="f-w-500">Frequency of computation</div>
                                                        <div>
                                                            <select class="col-xl-3 form-select form-select-sm" formControlName="frequency_computation">
                                                                <option>Daily</option>
                                                                <option>Weekly</option>
                                                                <option>Monthly</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row row mb-2">
                                                    <div class="form-group col-xl-12">
                                                        <div class="f-w-500">Minimum transaction period</div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <input type="text" class="col-xl-3 form-control form-control-sm"
                                                                    formControlName="min_transaction_period">
                                                            </div>
                                                            <div class="col-xl-6">days</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row row mb-2">
                                                    <div class="form-group col-xl-12">
                                                        <div class="f-w-500">Maximum transaction period</div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <input type="text" class="col-xl-3 form-control form-control-sm"
                                                                    formControlName="max_transaction_period">
                                                            </div>
                                                            <div class="col-xl-6">days</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row row mb-2">
                                                    <div class="form-group" formGroupName="transaction_to_exclude">
                                                        <div class="f-w-500">Transactions to exclude</div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                formControlName="exclude_discards">
                                                            <label class="form-check-label">
                                                                Exclude discards
                                                            </label>
                                                        </div>
                                                        <div class="form-check"
                                                            formGroupName="exclude_issues_net_utilization">
                                                            <input class="form-check-input" type="checkbox"
                                                                formControlName="value">
                                                            <label class="form-check-label">
                                                                Exclude issues/net utilization with the
                                                                following reason
                                                                codes
                                                            </label>
                                                            <div class="form-group col-xl-6">
                                                                <select class="form-select form-select-sm" formControlName="reason_codes">
                                                                    <option>Utilization</option>
                                                                    <option>Private CVC</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-check"
                                                            formGroupName="subtract_income_return_quantities">
                                                            <input class="form-check-input" type="checkbox"
                                                                formControlName="value">
                                                            <label class="form-check-label">
                                                                Subtract incoming return quantities from
                                                                consumed
                                                                quantity
                                                            </label>
                                                            <div class="form-group col-xl-6">
                                                                <select class="form-select form-select-sm" formControlName="reason_codes">
                                                                    <option>Utilization</option>
                                                                    <option>Private CVC</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-check" formGroupName="display">
                                    <label for="textBox" class="f-w-500 mb-2 me-2">Display</label><br />
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox"
                                            formControlName="show_days_weeks_months_of_stock">
                                        <label class="form-check-label">
                                            Show days/weeks/months of stock
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox"
                                            formControlName="show_stock_out_prediction">
                                        <label class="form-check-label">
                                            Show stock out prediction
                                        </label>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="form-group col-xl-4 mt-3">
                                    <span class="col-form-label"><b>Material By</b></span>&nbsp;
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="isStkMatBdge" value="badge"
                                            [(ngModel)]="isStkMatBdge" (click)="onClkRdb($event)"
                                            formControlName="isStkMatBdge" [checked]="isStkMatBdge=='badge'">
                                        <label class="form-check-label" for="mat">Tag</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="isStkMatBdge" value="name"
                                            [(ngModel)]="isStkMatBdge" (click)="onClkRdb($event)"
                                            [checked]="isStkMatBdge=='name'" formControlName="isStkMatBdge">
                                        <label class="form-check-label" for="mat">Name</label>
                                    </div>
                                    <div *ngIf="isStkMatBdge=='badge'">
                                        <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="slctdStkMat" placeholder=""
                                            (onSelect)="onMultiSlct('s', 'n', $event)" [data]="lstMatTblTags"
                                            (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 'n', $event)" formControlName="stkMtrlBdge">
                                        </ng-multiselect-dropdown>                                       
                                    </div>
                                    <div *ngIf="isStkMatBdge=='name'">
                                        <ng-multiselect-dropdown [settings]="msdStngs" [data]="smMtrlTblList" placeholder=""
                                            (onSelect)="onMultiSlct('s', 'm', $event)" [(ngModel)]="slctdStkMatName"
                                            (onSelectAll)="onMultiSlct('a', 'm', $event)" (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 'm', $event)" formControlName="stkMtrlNme">
                                        </ng-multiselect-dropdown>                                        
                                    </div>
                                </div>
                                <div class="form-group col-xl-3 mb-2 mt-3">
                                    <label for="textBox" class="f-w-500 mb-2 me-2"></label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" formControlName="min_max_range"
                                            name="min_max_range" value="Average" (change) = "minMaxDuration($event)">
                                        <label class="form-check-label">Average</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" formControlName="min_max_range"
                                            name="min_max_range" value="Peak" (change) = "minMaxDuration($event)">
                                        <label class="form-check-label">Peak</label>
                                    </div>
                                </div>
                                <div class="form-group col-xl-5 mb-2 mt-3">
                                    <div class="row">
                                        <div class="form-group col-xl-10">
                                            <label>Period</label>
                                            <select formControlName="range" class="form-select form-select-sm" [(ngModel)]="prd">
                                                <option value="1">Last 3 Months</option>
                                                <option value="2">Last 6 Months</option>
                                                <option value="3">Last 9 Months</option>
                                                <option value="4">Last 1 Year</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-2 mt-3">
                                            <div class="float-end">
                                                <button type="button" class="btn btn-sm btn-success" (click)="onActn('a')"><i class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table_spacing table-responsive mt-3" *ngIf="cnsumsnDtls?.length!=0">
                                <table class="table table-striped table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Material Tag</th>
                                            <th>Material Name</th>
                                            <th>Period Type</th>
                                            <th>Period</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let x of cnsumsnDtls; let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{x.mtrlTagNme}}</td>
                                            <td>{{x.mtrlNme}}</td>
                                            <td>{{x.min_max_range}}</td>
                                            <td>{{x.PrdName}}</td>
                                            <td class="text-center"><a (click)="onActn('d', i)"><i class="fa fa-trash" aria-hidden="true"></i></a></td>
                                        </tr>
                                    </tbody>
                                    <!-- <tbody *ngIf="lstTags.length == 0">
                                        <tr>
                                            <td colspan="2" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody> -->
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card p-0 mt-3 mb-2" formGroupName="forecasting_and_optimization">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Forecasting &amp; optimization</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">
                                Select the appropriate option for Stock Management forecasting and/or
                                optimization,
                                and
                                specify
                                relevant parameters.
                            </div>
                            <div class="form-group mt-3" formGroupName="computation_options">
                                <div class="form-row row mb-2">
                                    <div class="form-group col-xl-6">
                                        <label for="textBox" class="f-w-500">Computation options</label>
                                        <select class="col-xl-3 form-select form-select-sm" formControlName="value"
                                            [(ngModel)]="slctdCmptOpt" (change)="onChngCmptOpt($event)">
                                            <option value="None">None</option>
                                            <option value="Demand forecast">Demand forecast</option>
                                            <option value="Optimal Indent quantity">Optimal Indent quantity</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="card border p-0 shadow-sm mt-2 col-xl-8" formGroupName="demand_forecast"
                                    *ngIf="slctdCmptOpt == 'Demand forecast'">
                                    <div class="card-body p-4">
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-12">
                                                <div class="f-w-500">Minimum transaction period</div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <input type="text" class="col-xl-3 form-control form-control-sm"
                                                            formControlName="min_transaction_period">
                                                    </div>
                                                    <div class="col-xl-6">days</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-12">
                                                <div class="f-w-500">Maximum transaction period</div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <input type="text" class="col-xl-3 form-control form-control-sm"
                                                            formControlName="max_transaction_period">
                                                    </div>
                                                    <div class="col-xl-6">days</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row row">
                                            <div class="form-group col-xl-12">
                                                <div class="f-w-500">Indent periodicity (average)</div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <input type="text" class="col-xl-3 form-control form-control-sm"
                                                            formControlName="order_periodicity">
                                                    </div>
                                                    <div class="col-xl-6">days</div>
                                                </div>
                                            </div>
                                            <div class="form-row row mb-2">
                                                <div class="form-group col-xl-12">
                                                    <div class="f-w-500">Number of Indent periods to look back
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <input type="text" class="col-xl-3 form-control form-control-sm"
                                                                formControlName="no_of_order_periods">
                                                        </div>
                                                        <div class="col-xl-6">period(s)</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-check" formGroupName="display">
                                            <div class="f-w-500">Display</div>
                                            <input class="form-check-input" type="checkbox"
                                                formControlName="show_demand_forecast">
                                            Show demand forecast
                                        </div>
                                    </div>
                                </div>
                                <div class="card border p-0 shadow-sm col-xl-8" formGroupName="optimal_order_quantity"
                                    *ngIf="slctdCmptOpt == 'Optimal order quantity'">
                                    <div class="card-body p-4">
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-6">
                                                <div class="f-w-500">Stock Management Model</div>
                                                <div>
                                                    <select class="form-select form-select-sm" formControlName="stock_management_model">
                                                        <option value="User specified replenishment- s, Q">User specified replenishment- s, Q</option>
                                                        <option value="System determined replenishment- s, Q">System determined replenishment- s, Q</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-12">
                                                <div class="f-w-500">Minimum transaction period</div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <input type="text" class="col-xl-3 form-control form-control-sm"
                                                            formControlName="min_transaction_period">
                                                    </div>
                                                    <div class="col-xl-6">days</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-12">
                                                <div class="f-w-500">Maximum transaction period</div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <input type="text" class="col-xl-3 form-control form-control-sm"
                                                            formControlName="max_transaction_period">
                                                    </div>
                                                    <div class="col-xl-6">days</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-12">
                                                <div class="f-w-500">Indent periodicity (average)</div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <input type="text" class="col-xl-3 form-control form-control-sm"
                                                            formControlName="order_periodicity">
                                                    </div>
                                                    <div class="col-xl-6">days</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-12">
                                                <div class="f-w-500">Number of Indent periods to look back</div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <input type="text" class="col-xl-3 form-control form-control-sm"
                                                            formControlName="order_periodicity">
                                                    </div>
                                                    <div class="col-xl-6">period(s)</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-12">
                                                <div class="f-w-500">Lead time (average)</div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <input type="text" class="col-xl-3 form-control form-control-sm">
                                                    </div>
                                                    <div class="col-xl-6">period(s)</div>
                                                </div>
                                                <div class="card border p-0 shadow-sm col-xl-8 mt-2"
                                                    formGroupName="lead_time_average">
                                                    <div class="card-body p-4">
                                                        <div class="form-row row mb-2">
                                                            <div class="form-group col-xl-12">
                                                                <div class="f-w-500">Minimum number of Indents</div>
                                                                <div class="row">
                                                                    <div class="col-xl-6">
                                                                        <input type="text" class="col-xl-3 form-control form-control-sm"
                                                                            formControlName="min_no_of_orders">
                                                                    </div>
                                                                    <div class="col-xl-6">days</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-row row mb-2">
                                                            <div class="form-group col-xl-12">
                                                                <div class="f-w-500">Maximum number of Indents</div>
                                                                <div class="row">
                                                                    <div class="col-xl-6">
                                                                        <input type="text" class="col-xl-3 form-control form-control-sm"
                                                                            formControlName="max_no_of_orders">
                                                                    </div>
                                                                    <div class="col-xl-6">period(s)</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-row row mb-2">
                                                            <div class="form-group col-xl-12">
                                                                <div class="f-w-500">Maximum number of Indent periods
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-xl-6">
                                                                        <input type="text" class="col-xl-3 form-control form-control-sm"
                                                                            formControlName="max_no_of_order_periods">
                                                                    </div>
                                                                    <div class="col-xl-6">period(s)</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                formControlName="exclude_order_processing">
                                                            Exclude Indent processing time from lead time computation
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-check" formGroupName="display">
                                            <label class="form-check-label">Display</label>
                                            <input class="form-check-input" type="checkbox"
                                                formControlName="show_demand_forecast">
                                            Show demand forecast
                                            <input class="form-check-input" type="checkbox"
                                                formControlName="show_optimal_order_quantity">
                                            Show optimal Indent quantity
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="card p-0 mt-3 mb-2" formGroupName="min_max_configuration">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Min/Max configuration</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="form-group col-xl-12 mb-2 mt-3">
                                <label for="textBox" class="f-w-500 mb-2 me-2">Min/max value type</label>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="min_max_value_type" 
                                        name="min_max_value_type" value="Absolute quantity"> 
                                        <!-- (change) = "minMaxChange($event)" 
                                    <label class="form-check-label">Absolute quantity</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="min_max_value_type" 
                                        name="min_max_value_type" value="Duration of stock">
                                        <!-- (change) = "minMaxChange($event)" 
                                    <label class="form-check-label">Duration of stock</label>
                                </div>
                            </div>
                           
                        </div>
                    </div> -->
                    <div class="card p-0 mt-3 mb-2" formGroupName="stock_management_dashboard" *ngIf="false">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Stock Management dashboard</h6>
                        </div>
                        <div class="card-body p-2" formGroupName="stock_management_dashboard_visibility">
                            <div class="small">
                                Enable display of Stock Management dashboard (note: enable this only if
                                number
                                of
                                materials is &lt; 25 to achieve good performance)
                            </div>
                            <div class="form-group col-xl-12 mb-2 mt-3">
                                <label for="textBox" class="f-w-500 mb-2 me-2">
                                    Stock Management dashboard visibility
                                </label><br />
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox"
                                        formControlName="enable_display_of_stock_management_dashboard"
                                        [(ngModel)]="EDOSMD" value="false">
                                    <label class="form-check-label">
                                        Enable display of Stock Management dashboard
                                    </label>
                                </div>
                            </div>
                            <div class="card border p-0 shadow-sm col-xl-8" [hidden]="!EDOSMD">
                                <div class="card-body p-2">
                                    <div class="form-group col-xl-6 mb-2">
                                        <div class="">
                                            <div class="f-w-500">Default facility category</div>
                                            <div>                                                
                                                <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                    [data]="lstFacTags" placeholder=""
                                                    formControlName="default_store_tag" [(ngModel)]="defaultStrTags"
                                                    (onSelect)="onChng($event, 'default_store_tag', 'os')"
                                                    (onSelectAll)="onChng($event, 'default_store_tag', 'osa')"
                                                    (onDeSelect)="onChng($event, 'default_store_tag', 'ds')"
                                                    (onDeSelectAll)="onChng($event, 'default_store_tag', 'dsa')">
                                                </ng-multiselect-dropdown>
                                                <!-- <select class="js-example-basic-hide-search select_sm"
                                                    multiple="multiple">
                                                    <optgroup label="">
                                                        <option value="AL">GMSD</option>
                                                        <option value="WY">COVID vaccine</option>
                                                        <option value="WY">HIV</option>
                                                        <option value="WY">Malaria</option>
                                                        <option value="WY">OPEN Vials</option>
                                                        <option value="WY">RI vaccine</option>
                                                        <option value="WY">Syringes</option>
                                                        <option value="WY">TB</option>
                                                    </optgroup>
                                                </select> -->
                                                <small>( Filter this view using facility categories )</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2" formGroupName="manual_transaction_upload" *ngIf="false">
                        <div class="card-header p-2">
                            <h6>Manual transaction upload</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">
                                Allow specification for allowing manual upload of transaction and Stock Management
                                metadata
                            </div>
                        </div>
                        <div class="form-group col-xl-12 mb-2 mt-3">
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    Manual transaction upload options
                                </label>
                                <input class="form-check-input" type="checkbox"
                                    formControlName="enable_manual_upload_of_stock_management_metadata_along_with_transactions">
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2" formGroupName="date_of_actual_transaction" *ngIf="false">
                        <div class="card-header p-2">
                            <h6>Date of actual transaction</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">Enter a comma-separated list of statuses for materials.
                                Ensure
                                that
                                each
                                status is less than 15 characters. For instance, discard statuses can be
                                entered
                                as
                                follows: Damaged,Expired,Lost.</div>
                            <div class="row mt-3">
                                <div>
                                    <div class="nav nav-tabs border-tab mb-2" id="v-pills-tab" role="tablist"
                                        aria-orientation="vertical">
                                        <a class="nav-link f-w-500 active" id="top-d-tab1-tab" data-bs-toggle="tab"
                                            href="#top-d-tab1" role="tab" aria-controls="top-tab1"
                                            aria-selected="true">Issues/Net Utilization</a>
                                        <a class="nav-link f-w-500" id="d-tab2-top-tab" data-bs-toggle="tab"
                                            href="#d-top-tab2" role="tab" aria-controls="top-tab2"
                                            aria-selected="false">Stock-In</a>
                                        <a class="nav-link f-w-500" id="d-tab3-top-tab" data-bs-toggle="tab"
                                            href="#d-top-tab3" role="tab" aria-controls="top-tab3"
                                            aria-selected="false">Stock-Edit</a>
                                        <a class="nav-link f-w-500" id="d-tab4-top-tab" data-bs-toggle="tab"
                                            href="#d-top-tab4" role="tab" aria-controls="top-tab4"
                                            aria-selected="false">Stock-Discards</a>
                                        <a class="nav-link f-w-500" id="d-tab5-top-tab" data-bs-toggle="tab"
                                            href="#d-top-tab5" role="tab" aria-controls="top-tab5"
                                            aria-selected="false">Transfer</a>
                                        <a class="nav-link f-w-500" id="d-tab6-top-tab" data-bs-toggle="tab"
                                            href="#d-top-tab6" role="tab" aria-controls="top-tab6"
                                            aria-selected="false" hidden>Incoming returns</a>
                                        <a class="nav-link f-w-500" id="d-tab7-top-tab" data-bs-toggle="tab"
                                            href="#d-top-tab7" role="tab" aria-controls="top-tab7"
                                            aria-selected="false" hidden>Outgoing returns</a>
                                    </div>
                                </div>
                                <div>
                                    <div class="tab-content" id="top-tabContent">
                                        <div class="tab-pane fade show active" id="top-d-tab1" role="tabpanel"
                                            aria-labelledby="top-d-tab1-tab" formGroupName="issue">
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Enable capturing date of actual
                                                        transaction
                                                    </h6>
                                                </div>
                                                <div class="card-body p-2"
                                                    formGroupName="enable_capturing_date_of_actual_transaction">
                                                    <label for="textBox" class="f-w-500 mb-2 me-2">Date of
                                                        actual
                                                        transaction</label><br />
                                                    <div class="form-check form-check-inline ms-2">
                                                        <input class="form-check-input" type="radio" value="None"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            None
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Optional"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Optional
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Mandatory"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Mandatory
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="d-top-tab2" role="tabpanel"
                                            aria-labelledby="d-tab2-top-tab" formGroupName="receipt">
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Enable capturing date of actual
                                                        transaction
                                                    </h6>
                                                </div>
                                                <div class="card-body p-2"
                                                    formGroupName="enable_capturing_date_of_actual_transaction">
                                                    <label for="textBox" class="f-w-500 mb-2 me-2">Date of
                                                        actual
                                                        transaction</label><br />
                                                    <div class="form-check form-check-inline ms-2">
                                                        <input class="form-check-input" type="radio" value="None"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            None
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Optional"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Optional
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Mandatory"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Mandatory
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="d-top-tab3" role="tabpanel"
                                            aria-labelledby="d-tab3-top-tab" formGroupName="stock_count">
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Enable capturing date of actual
                                                        transaction
                                                    </h6>
                                                </div>
                                                <div class="card-body p-2"
                                                    formGroupName="enable_capturing_date_of_actual_transaction">
                                                    <label for="textBox" class="f-w-500 mb-2 me-2">Date of
                                                        actual
                                                        transaction</label><br />
                                                    <div class="form-check form-check-inline ms-2">
                                                        <input class="form-check-input" type="radio" value="None"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            None
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Optional"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Optional
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Mandatory"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Mandatory
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="d-top-tab4" role="tabpanel"
                                            aria-labelledby="d-tab4-top-tab" formGroupName="discards">
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Enable capturing date of actual
                                                        transaction
                                                    </h6>
                                                </div>
                                                <div class="card-body p-2"
                                                    formGroupName="enable_capturing_date_of_actual_transaction">
                                                    <label for="textBox" class="f-w-500 mb-2 me-2">Date of
                                                        actual
                                                        transaction</label><br />
                                                    <div class="form-check form-check-inline ms-2">
                                                        <input class="form-check-input" type="radio" value="None"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            None
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Optional"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Optional
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Mandatory"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Mandatory
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="d-top-tab5" role="tabpanel"
                                            aria-labelledby="d-tab5-top-tab" formGroupName="transfer">
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Enable capturing date of actual
                                                        transaction
                                                    </h6>
                                                </div>
                                                <div class="card-body p-2"
                                                    formGroupName="enable_capturing_date_of_actual_transaction">
                                                    <label for="textBox" class="f-w-500 mb-2 me-2">Date of
                                                        actual
                                                        transaction</label><br />
                                                    <div class="form-check form-check-inline ms-2">
                                                        <input class="form-check-input" type="radio" value="None"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            None
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Optional"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Optional
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Mandatory"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Mandatory
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="d-top-tab6" role="tabpanel"
                                            aria-labelledby="d-tab6-top-tab" formGroupName="incoming_returns" hidden>
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Enable capturing date of actual
                                                        transaction
                                                    </h6>
                                                </div>
                                                <div class="card-body p-2"
                                                    formGroupName="enable_capturing_date_of_actual_transaction">
                                                    <label for="textBox" class="f-w-500 mb-2 me-2">Date of
                                                        actual
                                                        transaction</label><br />
                                                    <div class="form-check form-check-inline ms-2">
                                                        <input class="form-check-input" type="radio" value="None"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            None
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Optional"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Optional
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Mandatory"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Mandatory
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="d-top-tab7" role="tabpanel"
                                            aria-labelledby="d-tab7-top-tab" formGroupName="outgoing_returns" hidden>
                                            <div class="card border-0 shadow-sm p-0 m-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Enable capturing date of actual
                                                        transaction
                                                    </h6>
                                                </div>
                                                <div class="card-body p-2"
                                                    formGroupName="enable_capturing_date_of_actual_transaction">
                                                    <label for="textBox" class="f-w-500 mb-2 me-2">Date of
                                                        actual
                                                        transaction</label><br />
                                                    <div class="form-check form-check-inline ms-2">
                                                        <input class="form-check-input" type="radio" value="None"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            None
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Optional"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Optional
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline  ms-2">
                                                        <input class="form-check-input" type="radio" value="Mandatory"
                                                            id="flexCheckDefault"
                                                            formControlName="date_of_actual_transaction">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Mandatory
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-end pb-3">
                        <button type="button" class="btn btn-sm btn-primary" (click)="onSave()" *ngIf="cnfgInvPrmsn">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>