<form  [formGroup]="eventActivityForm">
    <div class="row mt-2">
        <div class="col-sm-12">
          <!-- Good Indent -->
            <div class="card mb-3 p-0 pb-0 mt-2 pt-2">
                <div class="card-header p-2">
                    <h6>Users Inactive</h6>
                </div>
                <div class="card-body p-2"> 
                        <div class="row mt-3">
                            
                            <div class="form-group col-xl-12 mt-2">
                                <label for="textBox" class="f-w-500 mb-2 me-2">Enable push
                                    notifications</label><br />
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox"  formControlName="enabledPushNotification">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Enable push notifications for userInactive
                                    </label>
                                </div>
                            </div>
                          
                        </div>
                        <div class="row mt-2">
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">Duration not logged in <span class="text-danger"><sup>*</sup></span></label>
                                <div class="row">
                                    <div  class="col-xl-10">
                                        <input type="text" maxlength="99" min="1" placeholder=""  onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'   formControlName="durationNotLoggedIn" [ngClass]="{ 'is-invalid': isSbmtd && fc.durationNotLoggedIn.errors }" class="form-control">
                                        <div *ngIf="isSbmtd && fc.durationNotLoggedIn.errors" class="invalid-feedback">
                                            <div *ngIf="fc.durationNotLoggedIn.errors?.required">Duration not logged in is required</div>
                                        </div>
                                      
                                    </div>
                                    <div  class="col-xl-2">
                                        <p class="small"> Days</p>
                                    </div>
                                </div>
                            
                            </div>
                            <div class="form-group col-xl-6">
                                <label for="tagName"><b>User Category <span class="text-danger"><sup>*</sup></span></b></label>
                                <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="selectedUserCategories"
                                (onSelect)="onSelect($event,'f')" (onDeSelect)="onDeSelect($event,'t')"
                                (onSelectAll)="onSelectAll($event,'f')" (onDeSelectAll)="onDeSelectAll($event,'f')"  [data]="userCategries" placeholder="" formControlName="userCatagoryId" [ngClass]="{ 'is-invalid': isSbmtd && fc.userCatagoryId.errors }">
                                </ng-multiselect-dropdown>
                                <div *ngIf="isSbmtd && fc.userCatagoryId.errors" class="invalid-feedback">
                                    <div *ngIf="fc.userCatagoryId.errors?.required"> Facility Category is required</div>
                                </div>
                              
                            </div>	
                        </div>
                      
                    

                </div>

            </div>
              <!-- END Good Indent -->

     
            <div class="text-end p-3">
                <button type="button" class="btn btn-sm btn-primary" (click)="saveIndent()">Save</button>
            </div>
        </div>
    </div>
</form>