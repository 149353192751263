import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-event-summary-supply',
  templateUrl: './cnfg-event-summary-supply.component.html',
  styleUrls: ['./cnfg-event-summary-supply.component.css']
})

export class CnfgEventSummarySupplyComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() parentData: any;
  isShow=true; usrPrmsn: any = null; cnfgEvntSmPrmsn = true;
  eventIndentForm: FormGroup = this.fb.group({});
  msdStngs = {
    singleSelection: false, 
    idField: 'id', 
    textField: 'name', 
    selectAllText: 'ALL',
    unSelectAllText: 'ALL', 
    itemsShowLimit: 3, 
    allowSearchFilter: true
  };
  selectedFacilityTags: any[] = [];
  selectedNotFacilityTags: any[] = [];
  selectedMaterialTags: any[] = [];
  selectedNotMaterialTags: any[] = [];
  lstFacTags: any[] = [];
  lstMatTags: any[] = [];
  slctdStkMat: any[] = [];
 
  isSbmtd: boolean = false; 
  constructor(public glblSvc: GlobalService,  public fb: FormBuilder) { 
    super(glblSvc);
    this.getMaterialTagandFacilityCatoegory()
  
  }
  ngOnChanges(changes: SimpleChanges): void {
    
 this.editData(this.parentData)

      
        }
  
  

  ngOnInit(): void {

 
    this.select2ShowHide();
    this.intializeForm();
    // this.LoadData();
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_es_ac') ? (this.cnfgEvntSmPrmsn = e.action) : '') });
    }
  }

editData(data:any){
  if(data){
    this.eventIndentForm.patchValue({
      indentFulfilled:data?.goodIndent?.indentFulfilled,
      overPeriodOfTime:data?.goodIndent?.overPeriodOfTime,
      notIndentFulfilled:data?.notGoodIndent?.notIndentFulfilled,
      enabledPushNotification:data?.goodIndent?.enabledPushNotification,
      enabledPushNotificationIndentNotFulfilled:data?.notGoodIndent?.enabledPushNotification,
    })
     if(data?.goodIndent?.selectedMaterialTags&&data?.goodIndent?.selectedMaterialTags.length>0){
      this.selectedMaterialTags = data?.goodIndent?.selectedMaterialTags;
     }
     if(data?.goodIndent?.selectedFacilityTags&&data?.goodIndent?.selectedFacilityTags.length>0){
      this.selectedFacilityTags = data?.goodIndent?.selectedFacilityTags;
     }
     if(data?.notGoodIndent?.selectedMaterialTags&&data?.goodIndent?.selectedMaterialTags.length>0){
      this.selectedNotMaterialTags = data?.notGoodIndent?.selectedMaterialTags;
     }
     if(data?.notGoodIndent?.selectedFacilityTags&&data?.goodIndent?.selectedFacilityTags.length>0){
      this.selectedNotFacilityTags = data?.notGoodIndent?.selectedFacilityTags;
     }
   }

}

  getMaterialTagandFacilityCatoegory(){
    var localBadges:any[]= JSON.parse(<string>localStorage.getItem('appBdgs'));
    this.lstFacTags = localBadges.filter(f=>f.badgeTypeId==2 && f.isDeleted==false);
    this.lstMatTags = localBadges.filter(f=>f.badgeTypeId==1 && f.isDeleted==false);
  }

  intializeForm(){
    this.eventIndentForm = this.fb.group({
      stckFacCatId: ['', Validators.required], 
      stkMtrlBdge: ['',Validators.required],
      indentFulfilled:['',Validators.required],
      overPeriodOfTime:['',Validators.required],
      enabledPushNotification:[false],
      enabledPushNotificationIndentNotFulfilled:[false],
      notIndentFulfilled:['',Validators.required],
      NotstckFacCatId: ['', Validators.required], 
      NotstkMtrlBdge: ['',Validators.required],
     
    })
  }
  onSelect(item:any,type:any){
    if(type=='f' ){
      if (this.selectedFacilityTags.filter(f => f.id == item.id).length == 0)
      this.selectedFacilityTags.push(item);
      this.selectedFacilityTags = [...this.selectedFacilityTags];
    }
    if(type=='m' ){
      if (this.selectedMaterialTags.filter(f => f.id == item.id).length == 0)
      this.selectedMaterialTags.push(item);
      this.selectedMaterialTags = [...this.selectedMaterialTags];
    }

}
  
  onDeSelect(item:any,type:any){
    if(type=='f' ){
      let data = this.selectedFacilityTags;
      data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
      this.selectedFacilityTags = [...data];
    }
    if(type=='nf' ){
      let data = this.selectedNotFacilityTags;
      data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
      this.selectedNotFacilityTags = [...data];
    }
    if(type=='t' ){
      let data = this.selectedMaterialTags;
      data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
      this.selectedMaterialTags = [...data];
    }
    if(type=='nt' ){
      let data = this.selectedNotMaterialTags;
      data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
      this.selectedNotMaterialTags = [...data];
    }
    

  }
  onSelectAll(item:any,type:any){
    if(type=='f' ){
      this.selectedFacilityTags = item;
      this.selectedFacilityTags = [...this.selectedFacilityTags];
    }
    if(type=='nf' ){
      this.selectedNotFacilityTags = item;
      this.selectedNotFacilityTags = [...this.selectedNotFacilityTags];
    }
    if(type=='t' ){
      this.selectedMaterialTags = item;
      this.selectedMaterialTags = [...this.selectedFacilityTags];
    }
    if(type=='nt' ){
      this.selectedNotMaterialTags = item;
      this.selectedNotMaterialTags = [...this.selectedNotMaterialTags];
    }

  }
  onDeSelectAll(item:any,type:any){
    if(type=='f'){
      this.selectedFacilityTags = [];
    }
    if(type=='nf'){
      this.selectedNotFacilityTags = [];
    }
    if(type=='t'){
      this.selectedMaterialTags=[]
    }
    if(type=='nt'){
      this.selectedNotMaterialTags=[]
    }

  }
  get fc() { return this.eventIndentForm.controls; }


  saveIndent(){
    this.checkFormValidations(this.eventIndentForm), 
    this.isSbmtd = true;
    var  payLoad: any;
    if(this.eventIndentForm.valid){
    payLoad = {
      "configJson":{
        goodIndent:{
          "indentFulfilled":+this.eventIndentForm.controls['indentFulfilled'].value,
          "enabledPushNotification":this.eventIndentForm.controls['enabledPushNotification'].value,
          "overPeriodOfTime":+this.eventIndentForm.controls['overPeriodOfTime'].value,
          "selectedMaterialTags":this.selectedMaterialTags,
          "selectedFacilityTags":this.selectedFacilityTags
         },
         notGoodIndent:{
          "notIndentFulfilled":+this.eventIndentForm.controls['notIndentFulfilled'].value,
          "selectedMaterialTags":this.selectedNotMaterialTags,
          "enabledPushNotification":this.eventIndentForm.controls['enabledPushNotificationIndentNotFulfilled'].value,
          "selectedFacilityTags":this.selectedNotFacilityTags
          
         }
        
      },
       configTypeId: +environment?.eventSummaryTypes?.indent, 

  
         updatedBy: +this.usrLgnDtls?.userId
  
    }
    this.FetchData(environment.apiCalls.post, environment.apiPaths.systemConfiguration, environment.apiMthds.saveRupdate, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.message == 'Record updated successfully')
          this.glblSvc.onTstr('s', this.result.message);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
   
    }
    else{

    }

  }

  // LoadData() {
  
  //   this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType +
  //     environment.eventSummaryTypes.indent, null).then(() => {
  //       if (this.result) {
  //         if (this.result.status == 'OK') {
  //           if (this.result.data) {
  //            const resp= this.result?.data;
  //            if(resp){
  //             this.eventIndentForm.patchValue({
  //               indentFulfilled:data?.goodIndent?.indentFulfilled,
  //               overPeriodOfTime:data?.goodIndent?.overPeriodOfTime,
  //               notIndentFulfilled:data?.notGoodIndent?.notIndentFulfilled,
  //               enabledPushNotification:data?.goodIndent?.enabledPushNotification,
  //               enabledPushNotificationIndentNotFulfilled:data?.notGoodIndent?.enabledPushNotification,
  //             })
  //              if(data?.goodIndent?.selectedMaterialTags&&data?.goodIndent?.selectedMaterialTags.length>0){
  //               this.selectedMaterialTags = data?.goodIndent?.selectedMaterialTags;
  //              }
  //              if(data?.goodIndent?.selectedFacilityTags&&data?.goodIndent?.selectedFacilityTags.length>0){
  //               this.selectedFacilityTags = data?.goodIndent?.selectedFacilityTags;
  //              }
  //              if(data?.notGoodIndent?.selectedMaterialTags&&data?.goodIndent?.selectedMaterialTags.length>0){
  //               this.selectedNotMaterialTags = data?.notGoodIndent?.selectedMaterialTags;
  //              }
  //              if(data?.notGoodIndent?.selectedFacilityTags&&data?.goodIndent?.selectedFacilityTags.length>0){
  //               this.selectedNotFacilityTags = data?.notGoodIndent?.selectedFacilityTags;
  //              }
  //            }
          
  //           }
  //         }
  //       }
  //     });
  // }
}

