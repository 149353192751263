import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [BsModalService]
})

export class LoginComponent extends BaseComponent implements OnInit {

  isShow = false; isLogd = false; isRmbrChk: boolean = false; capData: any; lsIP: string = ''; lgnUsrId: any; capImg: any;
  lgnFrm = this.formBuilder.group({ user_id: ['', Validators.required], password: ['', Validators.required], // cptch: ['', Validators.required],
    auth_token: environment.lgnDefJWT, sourceType: 2 }); isMigURL = environment.isMigURL; lgnAPIData = { lgnMstr: {}, allMstr: {}, prnths: [],
  strs: [] }; mstrData = { txnTypeLst: [], ordTypeLst: [], cntriesLst: [], rsnsLst: [], stsLst: [], statesLst: [], blockLst: [], carrierLst: [],
    comuChnlLst: [], LngLst: [], tmZoneLst: [], astTypLst: [], rlsLst: [], dstLst: [], IdentifiersLst: [], evntNtfcnLst: [], allMats: [] };
  cnfgData = { cnfgInvData: {}, cnfgOdrData: {} };
  username: string = '';
  password: string = '';
  dltDsble: boolean = false;
  mdlRef?: BsModalRef;

  constructor(private mdlSvc: BsModalService,
    public glblSvc: GlobalService,
    private router: Router,
    private formBuilder: FormBuilder,
    private domSntzr: DomSanitizer) {
    super(glblSvc);
    this.glblSvc.sbjctCptcha.subscribe(s => {
      if (s)
        this.capData = s, this.capImg = this.domSntzr.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.capData.captchaImg),
          this.lgnFrm.get('cptch')?.setValue(''), this.isLogd = false;
    });
  }

  get fc() { return this.lgnFrm.controls; }

  ngOnInit(): void {
    this.isRmbrChk = false, this.setElmntFocus('lgnEmail');
    if (localStorage.getItem('lgnSsn') != null) {
      let lgnSsnData = JSON.parse(<string>localStorage.getItem('lgnSsn')),
        pswd = lgnSsnData.usrPswd.substring(0, lgnSsnData.usrPswd.length - environment.rndmTxtLen);
      this.lgnFrm.get('user_id')?.setValue(lgnSsnData.usrName), this.lgnFrm.get('password')?.setValue(atob(pswd)), this.isRmbrChk = true;
      setTimeout(() => { localStorage.setItem('lgnSsn', JSON.stringify({ usrName: lgnSsnData.usrName, usrPswd: lgnSsnData.usrPswd })) }, 500);
    }
    localStorage.clear(), sessionStorage.clear(), this.glblSvc.GetPCsIP(0).subscribe((s: any) => {
      if (s.hasOwnProperty("IPv4")  && s.IPv4 != null)
      this.lsIP = s.IPv4;
      if (this.lsIP == '') {
        this.glblSvc.GetPCsIP(1).subscribe((s: any) => {
          if (s.hasOwnProperty("ip") && s.ip != null)
            this.lsIP = s.ip;
          if (this.lsIP == '') {
            this.glblSvc.GetPCsIP(2).subscribe((s: any) => {
              if (s.hasOwnProperty("ip") && s.ip != null)
                this.lsIP = s.ip;
            })
          }
        })
      }
    })

    // localStorage.clear(), sessionStorage.clear(), this.glblSvc.GetPCsIP(0).subscribe((s: any) => {
    //   if (s.hasOwnProperty("ip") && s.ip != null)
    //     this.lsIP = s.ip;
    //   if (this.lsIP == '') {
    //     this.glblSvc.GetPCsIP(1).subscribe((s: any) => {
    //       if (s.hasOwnProperty("IPv4") && s.IPv4 != null)
    //         this.lsIP = s.IPv4;
    //       if (this.lsIP == '') {
    //         this.glblSvc.GetPCsIP(2).subscribe((s: any) => {
    //           if (s.hasOwnProperty("ip") && s.ip != null)
    //             this.lsIP = s.ip;
    //         })
    //       }
    //     })
    //   }
    // })
    // err => {};
  }

  onGenCaptcha(isRld = false) {
    this.lgnFrm.get('cptch')?.setValue(''), this.FetchData(environment.apiCalls.get, environment.apiPaths.captcha, isRld ?
        environment.apiMthds.reGenCptch + this.capData?.captchaId : environment.apiMthds.genCptch, null, 3).then(() => {
      let resCap = this.result;
      if (this.result) {
        if (this.result.status == 'OK')
          this.glblSvc.sbjctCptcha.next(this.result.data), setTimeout(() => { if (localStorage.getItem('lgnSsn') != null) this.setElmntFocus('lgnCptch'); }, 500);
        else
          this.glblSvc.onTstr('i', this.result.message)
      }
      else
        this.glblSvc.onTstr('e', resCap.message)
    });
  }

  onValdCaptcha() {
    this.isLogd = true, this.checkFormValidations(this.lgnFrm);
    if (this.lgnFrm.invalid)
      this.glblSvc.onTstr('w', 'Please fill all details');
    else {
      if (this.capData) {
        this.lgnFrm.get('cptch')?.setValue(`${this.lgnFrm.get('cptch')?.value}`.trim()), this.FetchData(environment.apiCalls.get,
            environment.apiPaths.captcha, environment.apiMthds.valdCptch.replace('capID', this.capData.captchaId) +
            `${this.lgnFrm.get('cptch')?.value}`.trim(), null, 3).then(() => { let resCap = this.result;
          if (this.result) {
            if (this.result.status == 'OK')
              this.onSignIn();
            else
              this.glblSvc.onTstr('i', this.result.message)
          }
          else
            this.glblSvc.onTstr('e', resCap.message)
        });
      }
    }
  }

  onShwHde() {
    if (this.lgnFrm.get('password')?.value != '')
      this.isShow = !this.isShow, this.ShowHidePwd('lgnPswd', this.isShow);
  }

  onSignIn() {
    this.isLogd = true, this.checkFormValidations(this.lgnFrm);
    if (this.lgnFrm.invalid)
      this.glblSvc.onTstr('w', 'Please fill all details');
    else {
      let lgnPL = this.lgnFrm.getRawValue(), realPswd = this.lgnFrm.get('password')?.value;
      lgnPL.password = btoa(lgnPL.password) + this.GenUnqText(); //, delete lgnPL.cptch;
      Object.assign(lgnPL, { user_model: { browser_details: this.GetBrwsrInfo(), ip_address: this.lsIP != '' ? this.lsIP : '10.10.10.239', logged_from_mobile: false,
        mobile_app_version: null, login_time: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd, this.tmF.hms), last_reconnected_time: null }});
      this.glblSvc.sbjctSpnr.next(true), this.glblSvc.loginAPI(environment.apiPaths.loginout, environment.apiMthds.login, lgnPL).subscribe((s: any) => {
        this.glblSvc.sbjctSpnr.next(false);
        if (s.data == null) {
          this.glblSvc.onTstr('e', s?.message), this.isLogd; // , this.onGenCaptcha(true)
          return;
        }
        this.lgnUsrId = s.data.userId;
        if (this.isRmbrChk)
          localStorage.setItem('lgnSsn', JSON.stringify({ usrName: lgnPL.user_id, usrPswd: lgnPL.password }));
        else
          localStorage.removeItem('lgnSsn');
        if (s) {
          if (s.message === 'User LoggedIn Successfully' && s.status === 'OK') {
            if (s.data.roleId == 1) {
              this.glblSvc.onTstr('i', 'You are not supposed to use Web portal');
              return;
            }
            this.glblSvc.sbjctSpnr.next(true), localStorage.setItem('lgnUsrDtls', JSON.stringify(s.data)), this.glblSvc.sbjctJWT.next(s.data.token),
            this.glblSvc.sbjctChsnDmnId.next(s.data.pranthId), this.glblSvc.sbjctChsnDmnName.next(s.data.pranthName),
              localStorage.setItem('slctdDmnId', s.data.pranthId), this.glblSvc.sbjctLgnUsrDtls.next(s.data), localStorage.setItem('isMstrsCld', '1'),
              localStorage.setItem('isLgd', '1'), localStorage.setItem('isClkdSA', '0'), this.router.navigate(['/home']); // this.glblSvc.setIntrvlDT(true),
           /* const lgnMstrs = this.glblSvc.postAPI(environment.apiPaths.lgnMstrs, environment.apiMthds.getAll, {}, 2, 1);
            const allMstrs = this.glblSvc.getAPI(environment.apiPaths.webMstrs, environment.apiMthds.getAll, 2, 1);
            const userPrns = this.glblSvc.getAPI(environment.apiPaths.pranth, environment.apiMthds.mappedPranthsGetAll, 0);
            const userStrs = this.glblSvc.getAPI(environment.apiPaths.store, environment.apiMthds.getAllStrs + s.data.pranthId, 0);
            const cnfgInvData = this.glblSvc.getAPI(environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType + environment.cnfgTypes.inventory, 0);
            const cnfgOdrData = this.glblSvc.getAPI(environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType + environment.cnfgTypes.orders, 0);
            forkJoin([lgnMstrs, allMstrs, userPrns, userStrs, cnfgInvData, cnfgOdrData]).subscribe({
              next: (res: any) => {
                res.forEach((list: any, indx: number) => {
                  if (list.status === 'OK') {
                    const mstrLst: any = {
                      '0': () => this.lgnAPIData.lgnMstr = list?.data ?? {},
                      '1': () => this.lgnAPIData.allMstr = list?.data ?? {},
                      '2': () => this.lgnAPIData.prnths = list?.data ?? [],
                      '3': () => this.lgnAPIData.strs = list?.data ?? [],
                      '4': () => this.cnfgData.cnfgInvData = list?.data?.configJson ?? {},
                      '5': () => this.cnfgData.cnfgOdrData = list?.data?.configJson ?? {},
                    }
                    mstrLst[indx]();
                  }
                });
              },
              error: err => { console.error('something went wrong ', err), console.log('lgnAPIData', this.lgnAPIData, 'cnfgData', this.cnfgData); },
              complete: () => {
                let mstrsAll: any = this.lgnAPIData.allMstr, mstrsLgn: any = this.lgnAPIData.lgnMstr;
                this.mstrData = { txnTypeLst: mstrsLgn.masterTxnTypes, ordTypeLst: mstrsLgn.masterOrderTypes, cntriesLst: mstrsLgn.masterCountry,
                  rsnsLst: mstrsLgn.masterReasonTypes, stsLst: mstrsLgn.masterStatuses, statesLst: mstrsLgn.masterStates, blockLst: mstrsLgn.masterBlocks,
                  carrierLst: mstrsAll.carriers, comuChnlLst: mstrsLgn.masterCommunicationChannels, LngLst: mstrsLgn.masterLanguages,
                  tmZoneLst: mstrsLgn.masterTimeZones, astTypLst: mstrsLgn.masterAssetTypes, rlsLst: mstrsLgn.masterRoles, dstLst: mstrsLgn.masterDistrict,
                  IdentifiersLst: mstrsLgn.masterTmTypes, evntNtfcnLst: mstrsLgn.masterNotificationEventTypes, allMats: mstrsAll.products },
                localStorage.setItem('lgnMstrsData', JSON.stringify(this.mstrData)), this.glblSvc.sbjctMstrData.next(this.mstrData),
                localStorage.setItem('lgnCnfgData', JSON.stringify(this.cnfgData)), this.glblSvc.sbjctCnfgData.next(this.cnfgData),
                localStorage.setItem('lgnUsrPrths', JSON.stringify(this.lgnAPIData.prnths)), this.glblSvc.sbjctPrnthList.next(this.lgnAPIData.prnths),
                sessionStorage.setItem('lgnUsrStrs', JSON.stringify(this.lgnAPIData.strs)), this.glblSvc.sbjctStrList.next(this.lgnAPIData.strs), // localStorage.setItem('lgnUsrStrs', JSON.stringify(this.lgnAPIData.strs)),
                localStorage.setItem('appBdgs', JSON.stringify(mstrsAll.badges)), this.glblSvc.sbjctBdgsList.next(mstrsAll.badges),
                localStorage.setItem('isLgd', '1'), setTimeout(() => { this.glblSvc.sbjctSpnr.next(false); }, 500),
                this.router.navigate([this.isMigURL ? environment.lndgPathClnt : environment.lndgPathGnrl]);
              },
            }); */
          }
          else
            this.glblSvc.onTstr('e', s.message);
        }
      });
    }
  }

  GetBrwsrInfo() {
    if (navigator.userAgent.includes('Edg/'))
      return `Edge v${navigator.userAgent.split('Edg/')[1]}`; // this.glblSvc.onTstr('d', 'Edge');
    else if (navigator.userAgent.includes('OPR/'))
      return `Opera v${navigator.userAgent.split('OPR/')[1]}`; // this.glblSvc.onTstr('d', 'Opera');
    else if (navigator.userAgent.includes('Chrome/'))
        return `Chrome v${navigator.userAgent.split('Chrome/')[1].split(' ')[0]}`; // this.glblSvc.onTstr('d', 'Chrome');
    else if (navigator.userAgent.includes('Firefox/'))
      return `Firefox v${navigator.userAgent.split('Firefox/')[1]}`; // this.glblSvc.onTstr('d', 'Firefox');
    else if (navigator.userAgent.includes('Safari/'))
      return `Safari ${navigator.userAgent.split('Version/')[1].replace('Safari/','(')})`; // this.glblSvc.onTstr('d', 'Safari');
    else
      return ''; // this.glblSvc.onTstr('d', 'Unknown');
  }

  onClkFP() {
    this.router.navigate(['frgt-pswd']);
  }
  onDA(tmplt: TemplateRef<void>) {
    this.mdlRef = this.mdlSvc.show(tmplt);
  }
  btndsble() {
    this.dltDsble = this.username.trim() !== '' && this.password.trim() !== '';
  }
  clr() {
    this.username= '';
    this.password= '';
    this.dltDsble = false
  }
  deleteAct() { // https://evin-development.dhanushinfotech.com/evin-gateway/api/users/v1/delete-user
    let pL = { passWord: btoa(this.password) + this.GenUnqText(), userId: null, userName: this.username };
    this.FetchData(environment.apiCalls.post, environment.apiPaths.loginout, environment.apiMthds.dltUsr, pL, 3).
      then(() => {
      if (this.result) {
        if(this.result.status == 'OK'){
          this.glblSvc.onTstr('s', this.result.message), this.clr();
          setTimeout(() =>{
            this.mdlRef?.hide();
          }, 2000);
        }else{
          this.glblSvc.onTstr('e', this.result.message) 
          // setTimeout(()=>{
          //   this.clr();
          // }, 3000);
        }
      }
    })
  }
}