import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-event-summary',
  templateUrl: './cnfg-event-summary.component.html',
  styleUrls: ['./cnfg-event-summary.component.css']
})

export class CnfgEventSummaryComponent extends BaseComponent implements OnInit {
  parentPassData: any[] = [];
  tabId=37;

  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
  }

  ngOnInit(): void {
    this.onTabChage(this.tabId)
   
   }
   onTabChage(tabid:any){
   this.tabId =tabid;
   this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType +
    this.tabId, null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
           const resp= this.result?.data.configJson;
           this.parentPassData =resp;
          
        }
      }
    }
    });
    

   }


}