import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { environment } from 'src/environments/environment.prod';
import { DateFrmts, TimeFrmts } from 'src/app/Enums/enums';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class GlobalService {

  dtFrmt = DateFrmts; tmFrmt = TimeFrmts; crntDT: any; rprtPath = location.href.split('/#/')[1]?.replace('home/', '');

  public obsrvblSbjcts: BehaviorSubject<any> = new BehaviorSubject<any>({ sbjctSpnr: false, sbjctcrntMenu: 'navMenuDB',
    sbjctcrntLang: 'eng', sbjctcrntLangLst: [], }); public isObsrvl: Observable<any> = this.obsrvblSbjcts.asObservable();

  public sbjctChsnDmnId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntChsnDmnId: Observable<number> = this.sbjctChsnDmnId.asObservable();

  public sbjctChsnDmnName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public crntChsnDmnName: Observable<string> = this.sbjctChsnDmnName.asObservable();

  public sbjctLgnUsrDtls: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public crntLgnUsrDtls: Observable<any> = this.sbjctLgnUsrDtls.asObservable();

  public sbjctSpnr: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSpnr: Observable<boolean> = this.sbjctSpnr.asObservable();

  public sbjctTagShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isTags: Observable<boolean> = this.sbjctTagShow.asObservable();

  public sbjctcrntMenu: BehaviorSubject<string> = new BehaviorSubject<string>('navMenuDB');
  public crntMenu: Observable<string> = this.sbjctcrntMenu.asObservable();

  public sbjctcrntLang: BehaviorSubject<string> = new BehaviorSubject<string>('eng');
  public crntLang: Observable<string> = this.sbjctcrntLang.asObservable();

  public sbjctManfsLst: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public crntManfsLst: Observable<any[]> = this.sbjctManfsLst.asObservable();

  public sbjctMatStsLst: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public crntMatStsLst: Observable<any[]> = this.sbjctMatStsLst.asObservable();

  public sbjctcrntLangLst: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public crntLangLst: Observable<any[]> = this.sbjctcrntLangLst.asObservable();

  public sbjctJWT: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public actJWT: Observable<string> = this.sbjctJWT.asObservable();

  public sbjctDtTm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public crntDtTm: Observable<string> = this.sbjctDtTm.asObservable();

  public sbjctKskName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public crntKskName: Observable<string> = this.sbjctKskName.asObservable();

  public sbjctKskId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntKskId: Observable<number> = this.sbjctKskId.asObservable();

  public sbjctTblDataCnt: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntTblDataCnt: Observable<number> = this.sbjctTblDataCnt.asObservable();

  public sbjctOrdId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntOrdId: Observable<number> = this.sbjctOrdId.asObservable();

  public sbjctOrdSmry: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public crntOrdSmry: Observable<any> = this.sbjctOrdSmry.asObservable();

  public sbjctOrdCnsgnee: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public crntOrdCnsgnee: Observable<any> = this.sbjctOrdCnsgnee.asObservable();

  public sbjctOrdShip: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public crntOrdShip: Observable<any> = this.sbjctOrdShip.asObservable();

  public sbjctOrdDtls: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public crntOrdDtls: Observable<any> = this.sbjctOrdDtls.asObservable();

  public sbjctRlsId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntRlsId: Observable<number> = this.sbjctRlsId.asObservable();

  public sbjctShipmentId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public crntShipId: Observable<string> = this.sbjctShipmentId.asObservable();

  public sbjctStrList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public crntStrList: Observable<any[]> = this.sbjctStrList.asObservable();

  public sbjctGMSDStrList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public crntGMSDStrList: Observable<any[]> = this.sbjctGMSDStrList.asObservable();

  public editAssetInfo: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public assetInfoEdit: Observable<any> = this.editAssetInfo.asObservable();
  
  public sbjctPrnthList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public crntPrnthList: Observable<any[]> = this.sbjctPrnthList.asObservable();

  public sbjctslctdDmntPrnth: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public crntSlctdPrnth: Observable<number> = this.sbjctslctdDmntPrnth.asObservable();

  public sbjctodrStst: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public crntodrStst: Observable<any[]> = this.sbjctodrStst.asObservable();
  
  public sbjctBdgsList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public crntBdgsLst: Observable<any[]> = this.sbjctBdgsList.asObservable();

  public sbjctMstrData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public crntMstrData: Observable<any> = this.sbjctMstrData.asObservable();

  public sbjctSlctDmDtls: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public crntDmnDtls: Observable<any> = this.sbjctSlctDmDtls.asObservable();

  public sbjctCnfgData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public crntCnfgData: Observable<any> = this.sbjctCnfgData.asObservable();  
  
  public sbjctAssetId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntAsseId: Observable<number> = this.sbjctAssetId.asObservable();

  public sbjctStrId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crnttStrId: Observable<number> = this.sbjctStrId.asObservable();
  
  public sbjctManfacId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntManfacId: Observable<number> = this.sbjctManfacId.asObservable();

  public sbjctManEdit: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntManfacEdit: Observable<number> = this.sbjctManEdit.asObservable();

  public sbjctUsrId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntUsrId: Observable<number> = this.sbjctUsrId.asObservable();

  public sbjctTrnsId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntTrnsId: Observable<number> = this.sbjctTrnsId.asObservable();

  public sbjctFacStrId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public crntFacStrId: Observable<string> = this.sbjctFacStrId.asObservable();

  public sbjctRprtFltr: BehaviorSubject<string> = new BehaviorSubject<string>(this.rprtPath);
  public crntRprtFltr: Observable<string> = this.sbjctRprtFltr.asObservable();

  public sbjctRprtTabOptns: BehaviorSubject<string> = new BehaviorSubject<string>('o');
  public crntRprtTabOptns: Observable<string> = this.sbjctRprtTabOptns.asObservable();

  public sbjctTrnsprtrId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public crntTrnsprtrId: Observable<number> = this.sbjctTrnsprtrId.asObservable();

  public sbjctCptcha: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isCptcha: Observable<boolean> = this.sbjctCptcha.asObservable();

  public sbjctTabsPage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public crntTabsPage: Observable<string> = this.sbjctTabsPage.asObservable();

  public tableSource = new BehaviorSubject<ITable[]>([]); // this.dummyTable()
  public invTable = this.tableSource.asObservable();

  public sbjctExpt: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public crntExpt: Observable<string> = this.sbjctExpt.asObservable();

  public sbjctCceFacDB: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public crntCceFacDB: Observable<string> = this.sbjctCceFacDB.asObservable();

  public sbjctAsstStrId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public strId: Observable<string> = this.sbjctAsstStrId.asObservable();

  public sbjctCCEMdl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public cceMdl: Observable<string> = this.sbjctCCEMdl.asObservable();

  public sbjctURL: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public crntURL: Observable<string> = this.sbjctCCEMdl.asObservable();

  public sbjctObjs: BehaviorSubject<objObsrvls> = new BehaviorSubject<objObsrvls>({ sbjctSpnr: false, sbjctcrntMenu: 'navMenuDB',
    sbjctcrntLang: 'eng', sbjctcrntLangLst: [], sbjctJWT: '', sbjctKskName: '', sbjctTblDataCnt: 0, sbjctOrdId: 0, sbjctShipmentId: '', 
    sbjctAssetId : 0,  sbjctStrId: 0, sbjctManfacId: 0, sbjctUsrId: 0, sbjctRprtFltr: '', sbjctTrnsprtrId: 0, sbjctTrnsId: 0});
  public obsrvlObjs: Observable<objObsrvls> = this.sbjctObjs.asObservable(); crntJWT = ''; pgTtl = 'eToastr';

  constructor(private http: HttpClient,
    private dtPipe: DatePipe,
    private spnr: NgxSpinnerService,
    public rtr: Router,
    private tstr: ToastrService) {
    this.sbjctJWT.subscribe(s => this.crntJWT = s), this.sbjctSpnr.subscribe(s => { if (s) spnr.show(); else spnr.hide(); });
    // if (localStorage.getItem('lgnUsrStrs'))
    //   this.sbjctStrList.next(JSON.parse(<string>localStorage.getItem('lgnUsrStrs')));
    if (sessionStorage.getItem('lgnUsrStrs'))
      this.sbjctStrList.next(JSON.parse(<string>sessionStorage.getItem('lgnUsrStrs')));
    if (localStorage.getItem('lgnUsrPrths'))
      this.sbjctPrnthList.next(JSON.parse(<string>localStorage.getItem('lgnUsrPrths')));
    if (localStorage.getItem('appBdgs'))
      this.sbjctBdgsList.next(JSON.parse(<string>localStorage.getItem('appBdgs')));
    if (localStorage.getItem('sbjctOrdSmryDtls'))
      this.sbjctOrdSmry.next(JSON.parse(<string>localStorage.getItem('sbjctOrdSmryDtls')));
    if (localStorage.getItem('sbjctOrdCnsgnee'))
      this.sbjctOrdCnsgnee.next(JSON.parse(<string>localStorage.getItem('sbjctOrdCnsgnee')));
    if (localStorage.getItem('sbjctOrdShipDtls'))
      this.sbjctOrdShip.next(JSON.parse(<string>localStorage.getItem('sbjctOrdShipDtls')));
    if (localStorage.getItem('sbjctOrdDtls'))
      this.sbjctOrdDtls.next(JSON.parse(<string>localStorage.getItem('sbjctOrdDtls')));
    if (localStorage.getItem('lgnMstrsData'))
      this.sbjctMstrData.next(JSON.parse(<string>localStorage.getItem('lgnMstrsData')));
    if (localStorage.getItem('slctdDmnDtls'))
      this.sbjctSlctDmDtls.next(JSON.parse(<string>localStorage.getItem('slctdDmnDtls')));
    if (localStorage.getItem('lgnCnfgData'))
      this.sbjctCnfgData.next(JSON.parse(<string>localStorage.getItem('lgnCnfgData')));
    if (localStorage.getItem('lstManfs'))
      this.sbjctManfsLst.next(JSON.parse(<string>localStorage.getItem('lstManfs')));
    if (localStorage.getItem('lstMatSts'))
      this.sbjctMatStsLst.next(JSON.parse(<string>localStorage.getItem('lstMatSts')));
    if (localStorage.getItem('lstStrsGMSD'))
      this.sbjctGMSDStrList.next(JSON.parse(<string>localStorage.getItem('lstStrsGMSD')));
    if (localStorage.getItem('editAssetInfo'))
      this.editAssetInfo.next(JSON.parse(<string>localStorage.getItem('editAssetInfo')));
    if (localStorage.getItem('slctdDmnId'))
      this.sbjctChsnDmnId.next(<any>localStorage.getItem('slctdDmnId'));
    if (localStorage.getItem('slctdDmnName'))
      this.sbjctChsnDmnName.next(<string>localStorage.getItem('slctdDmnName'));
    if (localStorage.getItem('lgnUsrDtls'))
      this.sbjctLgnUsrDtls.next(JSON.parse(<string>localStorage.getItem('lgnUsrDtls')));
    if (localStorage.getItem('slctdMngPrnth'))
      this.sbjctslctdDmntPrnth.next(<any>localStorage.getItem('sbjctslctdDmntPrnth'));
    if (localStorage.getItem('dbstrDtl'))
      this.sbjctCceFacDB.next(<any>localStorage.getItem('dbstrDtl'));
  }

  nvgtPage(url: string) {
    if (url != '')
      this.rtr.navigate([url]);
  }

  GetPCsIP(i: any) {
    return this.http.get(environment.fetchIP[i]);
  }

  GetGeoCds(i: any) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${i}&key=AIzaSyBAN2pi24QgaojCRnYQvVX52dEh-FqLr00`);
  }

  setDtFrmt(dtVal: any, dtF: DateFrmts, tmF: TimeFrmts = TimeFrmts.noTM, noSpace: boolean = true): string { // Date format setting
    let resDate: any = '';
    if (dtVal?.toString().includes('-') && !dtVal?.toString().includes('GMT') && !dtVal?.toString().includes('UTC')) {
      let dt = dtVal.toString().split('-');
      resDate = `${dt[2]}-${dt[1]}-${dt[0]}`;
    }
    else {
      if (dtVal) {
        resDate = this.dtPipe.transform(dtVal, dtF);
        if (tmF)
          resDate += `${noSpace?' ':''}${this.dtPipe.transform(dtVal, tmF)}`;
      }
    }
    return resDate;
  }

  setIntrvlDT(isStart: boolean) {
    if (isStart)
      this.crntDT = setInterval(() => { this.sbjctDtTm.next(this.setDT()), 1000 });
    else
      clearInterval(this.crntDT);
  }

  setDT(): string {
    return this.setDtFrmt(new Date(), this.dtFrmt.dmy2, this.tmFrmt.hmsa);
  }

  onTstr(type: string, msg: string, title: string = '') { // Toastr calls
    if (type != '' && msg != '') {
      switch (type) {
        case 's':
          this.tstr.success(msg == '' ? 'Success Message' : msg); //, title == '' ? 'Success' : title);
          break;
        case 'i':
          this.tstr.info(msg == '' ? 'Info Message' : msg); //, title == '' ? 'Info' : title);
          break;
        case 'w':
          this.tstr.warning(msg == '' ? 'Warning Message' : msg); //, title == '' ? 'Warning' : title);
          break;
        case 'e':
          this.tstr.error(msg == '' ? 'Error Message' : msg); //, title == '' ? 'Error' : title);
          break;
        case 'd':
          this.tstr.show(msg == '' ? 'Default Message' : msg); //, title);
          break;
        default:
          break;
      }
    }
  }

  // API calls
  loginAPI(apiPath: string, apiMthd: string, payLoad: any, isLcl: boolean = false) {
    const httpOptns = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; //, 'auth_token': environment.lgnDefJWT
    return this.http.post(environment.webURL + environment.prntAPIPath[0] + apiPath + apiMthd, payLoad, httpOptns);
  }

  logoutAPI(apiPath: string, apiMthd: string, payLoad: any, isLcl: boolean = false) {
    const httpOptns = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; //, 'Authorization': 'Bearer ' + this.crntJWT
    return this.http.post(environment.webURL + environment.prntAPIPath[0] + apiPath + apiMthd, payLoad, httpOptns);
  }

  getAPI(apiPath: string, apiMthd: string, svcTagIndx: number, prntPathIndx: number = 0, isEvnGtWay: boolean = true, isLcl: boolean = false): Observable<any> {
    let fnlMthd = `${apiMthd}${this.SendQueryParams(apiMthd)}`;
    if (isEvnGtWay) {
      if (this.crntJWT) {
        const httpOptns = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.crntJWT }) };
        return this.http.get(environment.webURL + environment.prntAPIPath[prntPathIndx] + environment.apiSrcePath[svcTagIndx] + apiPath + fnlMthd, httpOptns);
      }
      else {
        const httpOptns = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.get(environment.webURL + environment.prntAPIPath[prntPathIndx] + environment.apiSrcePath[svcTagIndx] + apiPath + fnlMthd, httpOptns);
      }
    }
    else {
      const httpOptns = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.get(environment.webURL + environment.prntAPIPath[prntPathIndx] + environment.apiSrcePath[svcTagIndx] + apiPath + fnlMthd, httpOptns);
    }
  }

  postAPI(apiPath: string, apiMthd: string, payLoad: any, svcTagIndx: number, prntPathIndx: number = 0, isLcl: boolean = false): Observable<any> {
    let fnlMthd = `${apiMthd}${this.SendQueryParams(apiMthd)}`;
    if (this.crntJWT) {
      const httpOptns = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.crntJWT }) };      
      return this.http.post((isLcl ? environment.lclAPIUrl : environment.webURL) + environment.prntAPIPath[prntPathIndx] + environment.apiSrcePath[svcTagIndx] + apiPath + fnlMthd, payLoad, httpOptns);
    }
    else {
      const httpOptns = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.post((isLcl ? environment.lclAPIUrl : environment.webURL) + environment.prntAPIPath[prntPathIndx] + environment.apiSrcePath[svcTagIndx] + apiPath + fnlMthd, payLoad, httpOptns);
    }
  }

  FormDataSave(apiPath: string, apiMthd: string, frmData: any, svcTagIndx: number, prntPathIndx: number = 0, isLcl: boolean = false): Observable<any> {
    let fnlMthd = `${apiMthd}${this.SendQueryParams(apiMthd)}`; 
    const httpOptns = { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.crntJWT }) }; // 'Content-Type': 'application/json'    // multipart/form-data
    return this.http.post((isLcl ? environment.lclAPIUrl : environment.webURL) + environment.prntAPIPath[prntPathIndx] + environment.apiSrcePath[svcTagIndx] + apiPath + fnlMthd, frmData, httpOptns);
  }

  putAPI(payLoad: Object): Observable<any> {
    return this.http.put(environment.webURL, payLoad);
  }

  patchAPI(payLoad: Object): Observable<any> {
    return this.http.patch(environment.webURL, payLoad);
  }

  deleteAPI(apiPath: string, apiMthd: string, svcTagIndx: number, prntPathIndx: number = 0, isLcl: boolean = false): Observable<any> {
    let fnlMthd = `${apiMthd}${this.SendQueryParams(apiMthd)}`;
    if (this.crntJWT) {
      const httpOptns = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.crntJWT }) };
      return this.http.delete(environment.webURL + environment.prntAPIPath[prntPathIndx] + environment.apiSrcePath[svcTagIndx] + apiPath + fnlMthd, httpOptns);
    }
    else {
      const httpOptns = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.delete(environment.webURL + environment.prntAPIPath[prntPathIndx] + environment.apiSrcePath[svcTagIndx] + apiPath + fnlMthd, httpOptns);
    }
  }

  dummyTable(): ITable[] { // Dummy table to bind Inventory Stock DAta
    let dmyTable: ITable[];
    return dmyTable = [{ kId: 1231, kName: 'ABC', kAdrs: 'HYD', ccp: '', hdrs: ['Hdr 1', 'Hdr 2'], matData: [{ currentStock: 143 }, { currentStock: null }], total: [1, 2] }];
  }

  SendQueryParams(apiMthd: string) {
    let isQsn = apiMthd.includes('?'), fnlAPI = '', lgnUsrData = JSON.parse(<string>localStorage.getItem('lgnUsrDtls')),
      chsnPrnth = localStorage.getItem('slctdDmnId') != 'undefined' ? localStorage.getItem('slctdDmnId') : lgnUsrData.pranthId;
    if (lgnUsrData != null && chsnPrnth != null) {
      this.sbjctJWT.next(lgnUsrData.token);
      if (!apiMthd.includes('pranthId'))
        fnlAPI = `${isQsn?'&':'?'}pranthId=${chsnPrnth}`;
      if (!apiMthd.includes('userId'))
        fnlAPI = `${fnlAPI}${isQsn||fnlAPI.includes('?')?'&':'?'}userId=${lgnUsrData.userId}`;
      if (!apiMthd.includes('userName'))
        fnlAPI = `${fnlAPI}${isQsn||fnlAPI.includes('?')?'&':'?'}userName=${lgnUsrData.userName}`;
      if (!apiMthd.includes('homePranthId'))
        fnlAPI = `${fnlAPI}${isQsn||fnlAPI.includes('?')?'&':'?'}homePranthId=${lgnUsrData.pranthId}`;
    }
    return fnlAPI;
  }

}

export class objObsrvls {
  sbjctSpnr: boolean = false;
  sbjctcrntMenu: string = '';
  sbjctcrntLang: string = '';
  sbjctcrntLangLst: any[] = [];
  sbjctJWT: string = '';
  sbjctKskName: string = '';
  sbjctTblDataCnt: number = 0;
  sbjctOrdId: number = 0;
  sbjctShipmentId: string = '';  
  sbjctAssetId: number = 0;  
  sbjctStrId: number = 0;
  sbjctManfacId: number = 0;
  sbjctUsrId: number = 0;
  sbjctRprtFltr: string = '';  
  sbjctTrnsprtrId: number = 0;
  sbjctTrnsId: number = 0;
}

export class apiRspns {
  data: any = null;
  message: string = '';
  returnCode: number = 0;
  status: string = '';
}

export interface ITable {
  kAdrs: String;
  kId: number;
  kName: string;
  ccp: string;
  hdrs: string[];
  matData: any[];
  total: number[];
}