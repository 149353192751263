<form  [formGroup]="eventIndentForm">
    <div class="row mt-2">
        <div class="col-sm-12">
          <!-- Good Indent -->
            <div class="card mb-3 p-0 pb-0 mt-2 pt-2">
                <div class="card-header p-2">
                    <h6>Good Indent Performance</h6>
                </div>
                <div class="card-body p-2"> 
                    <div>
                        <div class="row mt-3">
                            
                            <div class="form-group col-xl-12 mt-2">
                                <label for="textBox" class="f-w-500 mb-2 me-2">Enable push
                                    notifications</label><br />
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox"  formControlName="enabledPushNotification">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Enable push notifications for good indent performance
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">Indents Fulfilled In <span class="text-danger"><sup>*</sup></span></label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" maxlength="2" min="0"  placeholder=""  onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'   formControlName="indentFulfilled" [ngClass]="{ 'is-invalid': isSbmtd && fc.indentFulfilled.errors }" class="form-control">
                                        <div *ngIf="isSbmtd && fc.indentFulfilled.errors" class="invalid-feedback">
                                            <div *ngIf="fc.indentFulfilled.errors?.required">Indents Fulfilled In is required</div>
                                        </div>
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> Hrs</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">Over a period of <span class="text-danger"><sup>*</sup></span></label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" maxlength="2" min="0"  placeholder=""  onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' placeholder=""  formControlName="overPeriodOfTime" [ngClass]="{ 'is-invalid': isSbmtd && fc.overPeriodOfTime.errors }" class="form-control" class="form-control">
                                        <div *ngIf="isSbmtd && fc.overPeriodOfTime.errors" class="invalid-feedback">
                                            <div *ngIf="fc.overPeriodOfTime.errors?.required"> Over Period Of Time is required</div>
                                        </div>
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> Days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div>
                                        <div class= "row">	
                                            <div class="form-group col-xl-6 mt-2">
                                                <label for="tagName"><b>Facility Category <span class="text-danger"><sup>*</sup></span></b></label>
                                                <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="selectedFacilityTags"
                                                (onSelect)="onSelect($event,'f')" (onDeSelect)="onDeSelect($event,'t')"
                                                (onSelectAll)="onSelectAll($event,'f')" (onDeSelectAll)="onDeSelectAll($event,'f')"  [data]="lstFacTags" placeholder="" formControlName="stckFacCatId" [ngClass]="{ 'is-invalid': isSbmtd && fc.stckFacCatId.errors }">
                                                </ng-multiselect-dropdown>
                                                <div *ngIf="isSbmtd && fc.stckFacCatId.errors" class="invalid-feedback">
                                                    <div *ngIf="fc.stckFacCatId.errors?.required"> Facility Category is required</div>
                                                </div>
                                              
                                            </div>				
                                            <div class="form-group col-xl-6 mt-2">
                                                <label for="tagName"><b>Material By Tag <span class="text-danger"><sup>*</sup></span></b></label>
                                                <div >
                                                    <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="selectedMaterialTags" placeholder=""
                                                      [data]="lstMatTags"(onSelect)="onSelect($event,'t')" (onDeSelect)="onDeSelect($event,'t')"
                                                      (onSelectAll)="onSelectAll($event,'t')" (onDeSelectAll)="onDeSelectAll($event,'t')" formControlName="stkMtrlBdge" [ngClass]="{ 'is-invalid': isSbmtd && fc.stkMtrlBdge.errors }">
                                                    </ng-multiselect-dropdown>
                                                    <div *ngIf="isSbmtd && fc.stkMtrlBdge.errors" class="invalid-feedback">
                                                        <div *ngIf="fc.stkMtrlBdge.errors?.required"> Material badge is required</div>
                                                    </div>
                                                </div>
                                          
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>

                          
                        </div>
                      
                    </div>

                </div>

            </div>
              <!-- END Good Indent -->

            <!-- Not FulFilled Indent -->

            
            <div class="card mb-3 p-0 pb-0 mt-2 pt-2">
                <div class="card-header p-2">
                    <h6>Indent Not FulFilled</h6>
                </div>
                <div class="card-body p-2">
                    <div>
                        <div class="row mt-3">
                            
                            <div class="form-group col-xl-12 mt-2">
                                <label for="textBox" class="f-w-500 mb-2 me-2">Enable push
                                    notifications</label><br />
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox"  formControlName="enabledPushNotificationIndentNotFulfilled">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Enable  push notifications to indent not fulfilled
                                        
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">Not Fulfilled In <span class="text-danger"><sup>*</sup></span></label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" maxlength="2" min="0"  placeholder=""  onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' placeholder=""  formControlName="notIndentFulfilled" [ngClass]="{ 'is-invalid': isSbmtd && fc.notIndentFulfilled.errors }" class="form-control">
                                        <div *ngIf="isSbmtd && fc.indentFulfilled.errors" class="invalid-feedback">
                                            <div *ngIf="fc.notIndentFulfilled.errors?.required">Not Fulfilled In is required</div>
                                        </div>
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> Days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-6 mt-2">
                         
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div>
                                        <div class= "row">	
                                            <div class="form-group col-xl-6 mt-2">
                                                <label for="tagName"><b>Facility Category <span class="text-danger"><sup>*</sup></span></b></label>
                                                <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="selectedNotFacilityTags"
                                                (onSelect)="onSelect($event,'nf')" (onDeSelect)="onDeSelect($event,'nf')"
                                                (onSelectAll)="onSelectAll($event,'nf')" (onDeSelectAll)="onDeSelectAll($event,'nf')"  [data]="lstFacTags" placeholder="" formControlName="NotstckFacCatId" [ngClass]="{ 'is-invalid': isSbmtd && fc.NotstckFacCatId.errors }">
                                                </ng-multiselect-dropdown>
                                                <div *ngIf="isSbmtd && fc.NotstckFacCatId.errors" class="invalid-feedback">
                                                    <div *ngIf="fc.NotstckFacCatId.errors?.required"> Facility Category is required</div>
                                                </div>
                                              
                                            </div>				
                                            <div class="form-group col-xl-6 mt-2">
                                                <label for="tagName"><b>Material By Tag <span class="text-danger"><sup>*</sup></span></b></label>
                                                <div >
                                                    <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="selectedNotMaterialTags" placeholder=""
                                                      [data]="lstMatTags"(onSelect)="onSelect($event,'nt')" (onDeSelect)="onDeSelect($event,'nt')"
                                                      (onSelectAll)="onSelectAll($event,'nt')" (onDeSelectAll)="onDeSelectAll($event,'t')" formControlName="NotstkMtrlBdge" [ngClass]="{ 'is-invalid': isSbmtd && fc.NotstkMtrlBdge.errors }">
                                                    </ng-multiselect-dropdown>
                                                    <div *ngIf="isSbmtd && fc.NotstkMtrlBdge.errors" class="invalid-feedback">
                                                        <div *ngIf="fc.NotstkMtrlBdge.errors?.required"> Material badge is required</div>
                                                    </div>
                                                </div>
                                          
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                         </div>
                    </div>

                </div>

            </div>
                <!-- END Not FulFilled Indent -->
      
            <div class="text-end p-3">
                <button type="button" class="btn btn-sm btn-primary" (click)="saveIndent()">Save</button>
            </div>
        </div>
    </div>
</form>