<form [formGroup]="astCapctyForm" autocomplete="off">
    <!--  *ngIf="lgnUsrData?.screenPermissions?.Reports?.viewList==true" -->
    <div class="card p-0 border-0">
        <div class="row mt-2 mb-0 p-3">
            <div class="col-xl-12">
                <div style="float: right;" *ngIf="isRprtsHlpShw">
                    <a href="btn btn-sm btn-link" [routerLink]="['/power-availability/help']" target="_blank"><u>Help</u></a> 
                </div>
            </div>
            <div class="col-xl-12">
                <div class="form-row row mb-2">
                    <div class="form-group col-xl-3">
                        <strong class="col-form-label">Facility By<span class="text-danger"><sup>*</sup></span></strong>
                        <div class="form-check form-check-inline mt-2 p-l-25">
                            <input class="form-check-input" type="radio" id="rbFac1" name="isFacBdge" value="badge"
                                [(ngModel)]="isFacBdge" [checked]="isFacBdge=='badge'" formControlName="isFacBdge"
                                (click)="onClkRdb('storeTags')">
                            <label class="form-check-label" for="fac">Tag</label>
                        </div>
                        <div class="form-check form-check-inline" *ngIf="!ChkNtnlUsrByPrnthId()">
                            <input class="form-check-input" type="radio" id="rbFac2" name="isFacBdge" value="name"
                                [(ngModel)]="isFacBdge" [checked]="isFacBdge=='name'" formControlName="isFacBdge"
                                (click)="onClkRdb('storeIds')">
                            <label class="form-check-label" for="fac">Name</label>
                        </div>
                        <div *ngIf="isFacBdge=='badge'">
                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                [data]="lstFacTags" formControlName="storeTags"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.storeTags.errors }"                                                                        
                                (onSelect)="onMultiSlct('s', 'f', $event)"
                                (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                [(ngModel)]="slctdFac">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.storeTags.errors" class="invalid-feedback">
                                <div *ngIf="fc.storeTags.errors?.required">Facility tag is required</div>
                            </div>
                        </div>
                        <div *ngIf="isFacBdge=='name'">
                            <ng-multiselect-dropdown [settings]="ddlStngs" [data]="lstFacIds" [(ngModel)]="slctdFacId"
                            formControlName="storeIds" (onSelect)="onMultiSlct('s', 'c', $event)"
                            (onSelectAll)="onMultiSlct('a', 'c', $event)" (onDeSelect)="onMultiSlct('r', 'c', $event)"
                            (onDeSelectAll)="onMultiSlct('d', 'c', $event)"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.storeIds.errors }" >
                        </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.storeIds.errors" class="invalid-feedback">
                                <div *ngIf="fc.storeIds.errors?.required">Facility name is required</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group col-xl-3">
                        <strong class="col-form-label">Asset by</strong>&nbsp;
                        <div class="form-check form-check-inline mt-2 ">
                            <input class="form-check-input" type="radio" id="rbMat1" name="isTypeBdge" value="badge" [(ngModel)]="isTypeBdge"
                                [checked]="isTypeBdge=='badge'" formControlName="isTypeBdge" (click)="onClkRdb('monitoringType')">
                            <label class="form-check-label" for="mat">Type</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="rbMat2" name="isTypeBdge" value="name" [(ngModel)]="isTypeBdge"
                                [checked]="isTypeBdge=='name'" formControlName="isTypeBdge" (click)="onClkRdb('assetType')">
                            <label class="form-check-label" for="mat">Name</label>
                        </div>
                        <div *ngIf="isTypeBdge=='badge'">
                            <select class="form-select form-select-sm" [(ngModel)]="montrngType" formControlName="monitoringType">
                                <option value="0">All</option>
                                <option value="1">Monitoring</option>
                                <option value="2">Monitored</option>
                            </select>
                        </div>
                        <div *ngIf="isTypeBdge=='name'">
                            <select class="form-select form-select-sm" [(ngModel)]="astType" formControlName="assetType">
                                <option [value]="item.id" *ngFor="let item of astTypLst">{{item.name}}</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="form-group col-xl-3">
                        <strong class="col-form-label">Assets name</strong>
                        <select class="form-select form-select-sm" [(ngModel)]="astType" readonly
                            formControlName="assetType">
                            <option [value]="item.id" *ngFor="let item of astLst">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-xl-6">
                        <div class="row">
                            <div class="col-xl-4 small form-group">
                                <strong class="col-form-label">From<span class="text-danger"><sup>*</sup></span></strong>
                                <div class="input-group" *ngIf="pstT=='Monthly'">
                                    <input type="text" autocomplete="off" onpaste="return false"
                                        formControlName="dpSDVal" #dpSD="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode: 'month',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="Start Date"
                                        (bsValueChange)="onChngDT($event, 'sd')" [maxDate]="crntDate" 
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.dpSDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                            [attr.aria-expanded]="dpSD.isOpen">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpSDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpSDVal.errors?.required">From date is required</div>
                                    </div>
                                </div>
                                <div class="input-group" *ngIf="pstT=='Daily' || pstT=='Weekly'">
                                    <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpSDVal"
                                        #dpSD="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                            displayOneMonthRange: true, containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="Start Date"
                                         (bsValueChange)="onChngDT($event, 'sd')" [maxDate]="crntDate"
                                         [ngClass]="{ 'is-invalid': isSbmtd && fc.dpSDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                            [attr.aria-expanded]="dpSD.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpSDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpSDVal.errors?.required">From date is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-1 text-center mt-1 p-0" style="margin-top: 2.3rem !important;"><label>-</label></div> 
                            <div class="col-xl-4 small">
                                 <strong class="col-form-label">To<span class="text-danger"><sup>*</sup></span></strong>
                                <div class="input-group" *ngIf="pstT=='Monthly'">
                                    <input type="text" autocomplete="off" onpaste="return false"
                                        formControlName="dpEDVal" #dpED="bsDatepicker" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" readonly
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY',
                                            minMode: 'month', containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="End Date"
                                        [maxDate]="yBDate" (bsValueChange)="onChngDT($event, 'ed')" [minDate]="yrMin"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.dpEDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpEDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpEDVal.errors?.required">To date is required</div>
                                    </div>
                                </div>
                                <div class="input-group" *ngIf="pstT=='Daily' || pstT=='Weekly'">
                                    <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpEDVal"
                                         #dpED="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                            displayOneMonthRange: true, containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="End Date"
                                        [maxDate]="maxToDate" [minDate]="dpSDVal" (bsValueChange)="onChngDT($event, 'ed')"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.dpEDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpEDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpEDVal.errors?.required">To date is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 small select2-drpdwn" style="margin-top: 2.3rem !important;">
                                <select class="form-select form-select-sm" [(ngModel)]="pstT" formControlName="pstT"
                                (change)="isSbmtd = false;lstGrid = [];lstGridDate = [];onChngPstT()">
                                <option *ngFor="let pc of lstPrdCtys" [value]="pc.name">{{pc.name}}</option>
                            </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 float-xl-end text-left"><strong>{{lblTextOfDate}}</strong></div>
                        </div>
                    </div>
                        <div class="form-group col-xl-3 mt-3">
                            <strong for="textBox">State</strong>
                            <select formControlName="state" class="form-select form-select-sm"
                                (change)="dstLst=[];blkLst=[];lstGrid=[];lstGridDate=[];onLocChng($event, 'state')" #locState>
                                <option value="null">All</option>
                                <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <strong for="textBox">District</strong>
                            <select formControlName="district" class="form-select form-select-sm"
                                (change)="blkLst=[];lstGrid=[];lstGridDate=[];onLocChng($event, 'district')" #locDist>
                                <option value="null">All</option>
                                <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <div>
                                <strong for="textBox">Taluk/Block</strong>
                                <select formControlName="block" class="form-select form-select-sm" (change)="LoadCCPs()" #locBlck>
                                    <option value="null">All</option>
                                    <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="form-group col-xl-3 mt-1">
                            <strong class="col-form-label">By manufacturer year<span class="text-danger"><sup>*</sup></span></strong>
                            <input type="text" placeholder="Filter by manufacturer year" class="form-control form-control-sm"
                                formControlName="manufacturerYear" id="manufacturerYear"
                                maxlength="4" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{'is-invalid': isSbmtd && fc.manufacturerYear.errors}">
                            <div *ngIf="isSbmtd && fc.manufacturerYear.errors" class="invalid-feedback">
                                <div *ngIf="fc.manufacturerYear.errors.required">Year of manufacture is required</div>
                                <div *ngIf="fc.manufacturerYear.errors.min || fc.manufacturerYear.errors.max">
                                    Year of manufacture should accepts between 2000 to {{mandatryDate}}
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="col-xl-9"></div> -->
                        <div class="col-xl-3 mt-3" *ngIf="tab == 'f' && (locDist.value != 'null' || locBlck.value != 'null')">
                            <strong>Facility</strong>
                            <ng-multiselect-dropdown [settings]="ddlStngs" [data]="lstCcpAll"
                                formControlName="facilityIds" (onSelect)="onMultiSlct('s', 'fc', $event)" (onSelectAll)="onMultiSlct('a', 'fc', $event)"
                                (onDeSelect)="onMultiSlct('r', 'fc', $event)" (onDeSelectAll)="onMultiSlct('d', 'fc', $event)" [(ngModel)]="isFclty">
                            </ng-multiselect-dropdown>
    
                        </div>
                    <!-- <div [ngClass]="(tab == 'f' && (locDist.value != 'null' || locBlck.value != 'null')) ? 'col-xl-9' : '9'"></div> -->
                    <div class="form-group col-xl-12 mt-4 pt-3">
                        <div class="float-xl-end mb-2">
                            <button class="btn btn-sm btn-light me-2" type="button" (click)="onCncl();crntPage=1;crntDPage=1;isSbmtd=false;lstGrid=[];lstGridDate=[];lstTxn=[]">Reset</button>
                            <button class="btn btn-sm btn-primary" type="button" (click)="crntPage=1;crntDPage=1;isSbmtd=true;GenRprt()">Get report</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-header border-0 p-3">
            <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                <li class="nav-item"><a class="nav-link active" id="Asset-capacity-tab" data-bs-toggle="tab"
                        href="#Asset-capacity" role="tab" aria-controls="Asset-capacity" aria-selected="true"
                        (click)="onTabClk('o')">Overview</a></li>
                <li class="nav-item"><a class="nav-link" id="Asset-capacity-1-tab" data-bs-toggle="tab"
                        href="#Asset-capacity-1" role="tab" aria-controls="Asset-capacity-1" aria-selected="false"
                        (click)="onTabClk('f')">
                        By facilities</a></li>
                <!-- <li class="nav-item"><a class="nav-link" id="Asset-capacity-2-tab" data-bs-toggle="tab"
                        href="#Asset-capacity-2" role="tab" aria-controls="#Asset-capacity-2" aria-selected="false"
                        (click)="onTabClk('l')">
                        By locations</a></li> -->
            </ul>
        </div>
        <div class="card-body p-3 pt-0">
            <div class="tab-content" id="top-tabContent">
                <div class="row">
                    <div class="form-group col-xl-3">
                        <label class="col-form-label">Metric</label>
                        <select class="form-select form-select-sm" [(ngModel)]="metrcVal"
                            formControlName="metricId" (change)="isSbmtd=true;GenRprt()">
                            <option value="2">Number of powercuts</option>
                            <option value="3">Average Power cut duration per asset (in days)</option>
                            <option value="4">Average Power cut frequency</option>
                            <option value="1">% of time power is available</option>
                        </select>
                    </div>
                </div>
                <div class="tab-pane fade show active" id="Asset-capacity" role="tabpanel"
                    aria-labelledby="Asset-capacity-tab">
                    <div *ngIf="!isShwDayTable">
                    <div class="row mt-4">
                        <div class="col-xl-12" *ngIf="lstGrid?.length!=0">
                            <div class="text-center">
                                <strong>{{metrcVal == 1 ? ('% of time power is available'):(metrcVal == 2 ? ('Number of powercuts'):
                                    (metrcVal == 3 ? ('Average Power cut duration per asset (in days)') : ('Average Power cut frequency')))}}</strong>
                                <strong>{{asstType}}</strong> 
                                <div *ngIf ="frmDate != '' && tooDate != ''">
                                    <small><strong>From:</strong> {{frmDate}} <strong>To:</strong> {{tooDate}} &nbsp;</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 mb-2">
                            <div class="row" *ngIf="lstGrid?.length!=0">
                                <div class="col-xl-2"></div>
                                <div class="col-xl-10">
                                    <button class="btn btn-sm btn-success" (click)="isSbmtd=true;GenRprt('e')">
                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-xl-2"></div>
                        <div class="col-xl-8">
                            <div class="card h-100 mb-0">
                                <div class="card-body p-3">
                                    <div class="table-responsive table_spacing">
                                        <table class="table table-striped table-sm table-bordered border-primary f-14">
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Item.No</th>
                                                    <th class="col-lg-8">{{isDtCng == 'Monthly' ? 'Month' : (isDtCng == 'Daily' ? 'Date' : 'Week')}}</th>
                                                    <!-- <th *ngIf="isDtCng == 'Monthly'">Month</th>
                                                    <th *ngIf="isDtCng == 'Weekly'">Week</th>
                                                    <th *ngIf="isDtCng == 'Daily'">Date</th> -->
                                                    <!-- <th class="col-lg-8">{{isDtCng == 'Monthly' ? 'Month' : (isDtCng == 'Daily' ? 'Date' : 'Week)}}</th> -->
                                                    <th class="col-lg-4 text-center">Count</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="lstGrid?.length!=0">
                                                <tr class="text-center" [ngClass]="(pstT == 'Monthly' || pstT == 'Weekly') ? 'pntr' : ''" *ngFor="let item of lstGrid; let i=index" (click)="GetDailyData(item.date, 'g')">
                                                    <td>{{i+1}}</td>
                                                    <!-- <td *ngIf="(isDtCng == 'Monthly' || isDtCng == 'Weekly')">{{item.date}}</td>
                                                    <td *ngIf="isDtCng == 'Daily'">{{item.date | date:'dd-MM-yyyy'}}</td> -->
                                                    <td>{{(isDtCng == 'Monthly' || isDtCng == 'Weekly') ? (item.date) : (item.date == 'Total Count'? item.date: (item.date | date:'dd-MM-yyyy'))}}</td>
                                                    <td>{{((item.count != '' && item.count != null && item.count != undefined) ? (item.count | number : '1.2-2') : '')}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="lstGrid?.length==0">
                                                <tr>
                                                    <td colspan="3" class="text-center">{{noRcrdTxt}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>

                    <div *ngIf="lstGridDate?.length!=0 && (isDtCng == 'Monthly'|| isDtCng == 'Weekly') && isShwDayTable;">
                        <div class="row mt-4">
                            <div class="col-xl-12">
                                <div class="col-xl-12">
                                    <div class="text-center" >
                                        <strong>{{metrcVal == 1 ? ('% of time power is available'):(metrcVal == 2 ? ('Number of powercuts'):
                                            (metrcVal == 3 ? ('Average Power cut duration per asset (in days)') : ('Average Power cut frequency')))}}</strong>
                                        <strong *ngIf="isTypeBdge=='badge'">- {{mntrngType}}</strong><strong *ngIf="isTypeBdge=='name'">- {{asstType}}</strong> 
                                        <div *ngIf ="frmDate != '' && tooDate != ''">
                                            <small ><strong>From:</strong> {{frmDates}} <strong>To:</strong> {{tooDates}} &nbsp;</small>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>                         
                        <div class="row mt-3">
                            <div class="col-xl-3"></div>
                            <div class="col-xl-6 col-sm-12 col-xs-12" *ngIf="lstGridDate?.length!=0">
                                <div class="row">
                                    <div class="col-xl-12 mb-2">
                                        <div class="row" *ngIf="lstGridDate?.length!=0">
                                            <div class="col-xl-6">
                                                <button class="btn btn-sm btn-success" (click)="isSbmtd=true;GetDailyData('', 'e')">
                                                    <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                                </button>
                                            </div>
                                            <div class="col-xl-6 float-end">
                                                <button class="btn btn-sm btn-primary float-end" (click)="isShwDayTable = false">
                                                    Back
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12">
                                        <div class="table_spacing table-responsive table-row-spacing">
                                            <table class="table table-striped table-sm table-bordered border-primary">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th class="text-center">Item.No.</th>
                                                        <th>Date</th>
                                                        <th>Response time to repair (days)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="text-center" *ngFor="let lst of lstGridDate; let i=index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{lst.date == 'Total Count'? lst.date: (lst.date | date:'dd-MM-yyyy')}}</td>
                                                        <td>{{lst.count}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="lstGridDate?.length ==0 && (isDtCng == 'Monthly'|| isDtCng == 'Monthly') && isShwDayTable">
                        <div class="row">
                            <div class="col-xl-12">
                                <button class="btn btn-sm btn-primary float-end" (click)="isShwDayTable=false;">Back</button>
                            </div>
                            <div class="text-center col-xl-12">{{noRcrdTxt}} </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="Asset-capacity-1" role="tabpanel" aria-labelledby="Asset-capacity-1-tab">
                    <div *ngIf="!isShwDayTable">
                    <div class="row mt-4">
                        <div class="col-xl-12" *ngIf="lstGrid?.length!=0">
                            <div class="text-center">
                                <strong>{{metrcVal == 1 ? ('% of time power is available'):(metrcVal == 2 ? ('Number of powercuts'):
                                    (metrcVal == 3 ? ('Average Power cut duration per asset (in days)') : ('Average Power cut frequency')))}}</strong>
                                <strong *ngIf="isTypeBdge=='badge'">- {{mntrngType}}</strong> <strong *ngIf="isTypeBdge=='name'">- {{asstType}}</strong>
                                <!-- <strong>{{metrcVal == 1 ? ('% of time power is availble'):(metrcVal == 2 ? ('Number of powercuts'): 'Average
                                    duration in this state per asset (in days)')}}</strong> -->
                                <div *ngIf="frmDate != '' && tooDate != ''">
                                    <small><strong>From:</strong> {{frmDate}} <strong>To:</strong> {{tooDate}} &nbsp;</small>
                                </div>
                                <div>
                                    <span *ngIf="isFacBdge=='badge'"><small><strong>Facility Tag:</strong> {{lstFacsTag}} </small></span>
                                    <span *ngIf="isFacBdge=='name'"><small><strong>Facility:</strong>{{lstFacsName}} </small></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 mb-2">
                            <div class="row" *ngIf="lstGrid?.length!=0">
                                <div class="col-xl-2">
                                    <button class="btn btn-sm btn-success" (click)="isSbmtd=true;GenRprt('e')">
                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                    </button>
                                </div>
                                <div class="col-xl-3" *ngIf="showExportOption">
                                    <select class="form-select form-select-sm" [(ngModel)]="expDrpn" [ngModelOptions]="{standalone: true}">
                                        <option value="1">Same as table</option>
                                        <option value="2">Facility wise</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div *ngIf="lstGrid?.length!=0" class="mt-2">{{pageEntrs}}</div>
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end" *ngIf="lstGrid?.length!=0">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'dm')" id="pcFclitys"></pagination-controls>
                            </div>
                        </div>
                    </div>
                
                    <div class="table-wrap" *ngIf="lstGrid.length!=0">
                        <table class="text-center table-small-font main-table table-bordered mt-1 center">
                            <thead>
                                <tr>
                                    <th colspan="5" class="freeze tbl-hdr-clr">
                                        <table class="w-600p break-word-td">
                                            <tr>
                                                <th  class="">Sno</th>
                                                <th  class="">State</th>
                                                <th class="" *ngIf="astCapctyForm?.get('state')?.value!=null && astCapctyForm?.get('state')?.value!='null'">District</th>
                                                <th class="" *ngIf="astCapctyForm?.get('district')?.value!=null && astCapctyForm?.get('district')?.value!='null'">Facility</th>
                                                <th  class="" *ngIf="astCapctyForm?.get('district')?.value!=null && astCapctyForm?.get('district')?.value!='null' && isFclty.length>0">Sno </th>
                                                <th  class="" *ngIf="astCapctyForm?.get('district')?.value!=null && astCapctyForm?.get('district')?.value!='null' && isFclty.length>0">Model </th>
                                                <th class="" *ngIf="astCapctyForm?.get('district')?.value!=null && astCapctyForm?.get('district')?.value!='null' && isFclty.length>0">Manufactor </th>
                                                <th class="" *ngIf="astCapctyForm?.get('district')?.value!=null && astCapctyForm?.get('district')?.value!='null' && isFclty.length>0">Type </th>
                                            </tr>
                                        </table>
                                    </th>                                            
                                    <th *ngFor="let hdr of lstGrid[0]?.hdrs" class="tbl-hdr-clr p-2 pntr" (click)="crntDPage=1;GetDailyData(hdr,'g')">
                                        {{(isDtCng == 'Monthly' || isDtCng == 'Weekly') ? (hdr) : (hdr == 'Total Count' ? hdr: (hdr | date:'dd-MM-yyyy'))}}</th>
                                </tr>
                                <tr>
                                    <th colspan="5" class="freeze tbl-hdr-clr">
                                        <table class="w-300p break-word-td">
                                            <tr>
                                                <th style="width: 25%"></th>
                                                <th style="width: 75%">Total</th>
                                            </tr>
                                        </table>
                                    </th>                                         
                                    <th *ngFor="let ttl of ttlCnt" class="tbl-hdr-clr p-2 pntr">{{((ttl != '' && ttl != null && ttl != undefined) ? (ttl | number : '1.2-2') : '')}}</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="lstGrid.length!=0">
                                <tr *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: ttlItms, id: 'pcFclitys' }; let i=index">
                                    <td colspan="5" class="freeze">
                                        <table  class="w-600p break-word-td">

                                            <tr>
                                                <td  class="wht-space">{{(pageSize*(crntPage-1))+i+1}}</td>
                                                <td  class="wht-space"  
                                                    *ngIf="astCapctyForm?.get('state')?.value!=null && astCapctyForm?.get('state')?.value!='null'">{{steRes}}</td>
                                                <td  class="wht-space" 
                                                    *ngIf="astCapctyForm?.get('district')?.value!=null && astCapctyForm?.get('district')?.value!='null'">{{dstRes}}</td>
                                                <td  class="wht-space"  *ngIf="isFclty.length==0">{{item.kName}}</td>
                                                <td  class="wht-space"  *ngIf="isFclty.length>0">{{item.matData[0]?.facilityName}}</td>
                                                <td  class="wht-space"  *ngIf="isFclty.length>0">{{item.matData[0]?.serialNumber}}</td>
                                                <td  class="wht-space"  *ngIf="isFclty.length>0">{{item.matData[0]?.model}}</td>
                                                <td  class="wht-space"  *ngIf="isFclty.length>0">{{item.matData[0]?.vendor}}</td>
                                                <td  class="wht-space"  *ngIf="isFclty.length>0">{{item.matData[0]?.assetType}}</td>
                                                
                                            </tr>
                                            <!-- <tr>
                                                <td >{{(pageSize*(crntPage-1))+i+1}}</td>
                                                <td
                                                    *ngIf="astCapctyForm?.get('state')?.value!=null && astCapctyForm?.get('state')?.value!='null'">{{steRes}}</td>
                                                <td 
                                                    *ngIf="astCapctyForm?.get('district')?.value!=null && astCapctyForm?.get('district')?.value!='null'">{{dstRes}}</td>
                                                <td>{{item.kName}}</td>
                                                <td  *ngIf="isFclty.length>0">{{item.matData[0]?.facilityName}}</td>
                                                <td    class="wht-space" *ngIf="isFclty.length>0">{{item.matData[0]?.serialNumber}}</td>
                                                <td   *ngIf="isFclty.length>0">{{item.matData[0]?.model}}</td>
                                                <td   *ngIf="isFclty.length>0">{{item.matData[0]?.vendor}}</td>
                                                <td   *ngIf="isFclty.length>0">{{item.matData[0]?.assetType}}</td>
                                                
                                            </tr> -->
                                        </table>
                                    </td>
                                    <td *ngFor="let col of item.matData">
                                        {{((col.count != '' && col.count != null && col.count != undefined) ? (col.count | number : '1.2-2') : '')}}
                                        <!-- <span *ngIf="col.productCount !=null"></span> <span *ngIf="col.productCount == null">NA</span> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row mt-3" *ngIf="lstGrid?.length==0">
                        <div class="text-center col-xl-12" *ngIf="lstGrid?.length==0">{{noRcrdTxt}} </div>
                    </div>
                    <div class="pb-3 pt-3">
                        <div class="row" *ngIf="lstGrid?.length!=0">
                            <div class="col-xl-6 text-start">
                                {{pageEntrs}}
                            </div>
                            <div class="col-xl-6">
                                <div class="float-end">
                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'dm')" id="pcFclitys"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div *ngIf="lstGridDate?.length!=0 && (isDtCng == 'Monthly' || isDtCng == 'Weekly') && isShwDayTable;">
                        <div class="row mt-4">
                            <div class="col-xl-12">
                                <div class="col-xl-12">
                                    <div class="text-center">
                                        <strong>{{metrcVal == 1 ? ('% of time power is available'):(metrcVal == 2 ? ('Number of powercuts'):
                                            (metrcVal == 3 ? ('Average Power cut duration per asset (in days)') : ('Average Power cut frequency')))}}</strong>
                                        <strong *ngIf="isTypeBdge=='badge'">- {{mntrngType}}</strong> <strong *ngIf="isTypeBdge=='name'">- {{asstType}}</strong>
                                        <!-- <strong>{{metrcVal == 1 ? ('% of time power is availble'):(metrcVal == 2 ? ('Number of powercuts'): 'Average
                                            duration in this state per asset (in days)')}}</strong> -->
                                        <div *ngIf="frmDate != '' && tooDate != ''">
                                            <small ><strong>From:</strong> {{frmDates}} <strong>To:</strong> {{tooDates}} &nbsp;</small>

                                        </div>
                                        <div>
                                            <span *ngIf="isFacBdge=='badge'"><small><strong>Facility Tag:</strong> {{lstFacsTag}} </small></span>
                                            <span *ngIf="isFacBdge=='name'"><small><strong>Facility:</strong>{{lstFacsName}} </small></span>
                                        </div>
                                    </div>
                                    <div class="text-center" >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-xl-12 mb-2">
                                <div class="row" *ngIf="lstGridDate?.length!=0">
                                    <div class="col-xl-2">
                                        <button class="btn btn-sm btn-success" (click)="isSbmtd=true;GetDailyData('', 'e')">
                                            <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                        </button>
                                    </div>
                                    <div class="col-xl-3">
                                        <select class="form-select form-select-sm" [(ngModel)]="expDrpnDly" [ngModelOptions]="{standalone: true}">
                                            <option value="1">Same as table</option>
                                            <option value="2">Facility wise</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-7 float-end">
                                        <button class="btn btn-sm btn-primary float-end" (click)="isShwDayTable = false">
                                          Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div *ngIf="lstGridDate?.length!=0" class="mt-2">{{pageEntrsD}}</div>
                            </div>
                            <div class="col-xl-6">
                                <div class="float-end" *ngIf="lstGridDate?.length!=0">
                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'dd')" id="pcFacltyDay"></pagination-controls>
                                </div>
                            </div>
                        </div>
                        <div class="table-wrap" *ngIf="lstGridDate.length!=0">
                            <table class="text-center table-small-font main-table table-bordered">
                                <thead>
                                    <tr>
                                        <th colspan="5" class="freeze tbl-hdr-clr">
                                            <table class="w-450p break-word-td">
                                                <tr>
                                                    <th style="width: 25%" class="text-center">Item No.</th>
                                                    <th [ngStyle]="{ 'width': (fgrv.district == null || fgrv.district == 'null') ? '50%' : '30%' }" class="text-center">State</th>
                                                    <th [ngStyle]="{ 'width': (fgrv.district == null || fgrv.district == 'null') ? '50%' : '25%' }" class="text-center"
                                                        *ngIf="astCapctyForm?.get('state')?.value!=null && astCapctyForm?.get('state')?.value!='null'">District</th>
                                                    <th style="width: 25%" class="text-center" *ngIf="astCapctyForm?.get('district')?.value!=null && astCapctyForm?.get('district')?.value!='null'">Facility</th>
                                                </tr>
                                            </table>
                                        </th>                                            
                                        <th *ngFor="let hdr of lstGridDate[0]?.hdrs" class="tbl-hdr-clr p-2">{{hdr == 'Total Count' ? hdr: (hdr | date:'dd-MM-yyyy')}}</th>
                                    </tr>
                                    <tr>
                                        <th colspan="5" class="freeze tbl-hdr-clr">
                                            <table class="w-300p break-word-td">
                                                <tr>
                                                    <th style="width: 25%"></th>
                                                    <th style="width: 75%">Total</th>
                                                </tr>
                                            </table>
                                        </th>                                         
                                        <th *ngFor="let ttl of ttlCntDly" class="tbl-hdr-clr p-2 pntr">{{ttl}}</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="lstGridDate.length!=0">
                                    <tr *ngFor="let item of lstGridDate | paginate: { itemsPerPage: pageSize, currentPage: crntDPage, totalItems: ttlDayItms, id: 'pcFacltyDay' }; let i=index">
                                        <td colspan="5" class="freeze">
                                            <table class="w-450p break-word-td">
                                                <tr>
                                                    <td style="width: 25%" class="text-center">{{(pageSize*(crntDPage-1))+i+1}}</td>
                                                    <td [ngStyle]="{ 'width': (fgrv.district == null || fgrv.district == 'null') ? '50%' : '30%' }"
                                                        *ngIf="astCapctyForm?.get('state')?.value!=null && astCapctyForm?.get('state')?.value!='null'">{{steResDly}}</td>
                                                    <td [ngStyle]="{ 'width': (fgrv.district == null || fgrv.district == 'null') ? '50%' : '25%' }"
                                                        *ngIf="astCapctyForm?.get('district')?.value!=null && astCapctyForm?.get('district')?.value!='null'">{{dstResDly}}</td>
                                                    <td style="width: 25%" class="text-center f-14">{{item.kName}}</td>
                                                    <!-- <td class="text-center" style="width: 5%"></td> -->
                                                </tr>
                                            </table>
                                        </td>
                                        <td *ngFor="let col of item.matData" >{{col.count}}
                                             <!-- <span *ngIf="col.productCount !=null"></span> <span *ngIf="col.productCount == null">NA</span> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pb-3 pt-3">
                            <div class="row" *ngIf="lstGridDate?.length!=0">
                                <div class="col-xl-6 text-start">
                                    {{pageEntrsD}}
                                </div>
                                <div class="col-xl-6">
                                    <div class="float-end">
                                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'dd')"
                                            id="pcFacltyDay"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="lstGridDate?.length ==0 && (isDtCng == 'Monthly' || isDtCng == 'Weekly') && isShwDayTable">
                        <div class="row">
                            <div class="col-xl-12">
                                <button class="btn btn-sm btn-primary float-end" (click)="isShwDayTable=false;">Back</button>
                            </div>
                            <div class="text-center col-xl-12">{{noRcrdTxt}} </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="Asset-capacity-2" role="tabpanel"
                    aria-labelledby="Asset-capacity-2-tab">
                    
                </div>
            </div>
        </div>
    </div>
</form>